import { makeStyles } from "@material-ui/core/styles";

const HeaderStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex"
  },
  menuContainer: {
    display: "flex"
  },
  selectDate: {
    flexGrow: 1
  },
  datesRail: {
    flexGrow: 2
  },
  MuiPickersDay: {
    daySelected: {
      backgroundColor: "green"
    }
  },
  goBackButton: {
    color: "#727272",
    textTransform: "capitalize",
    padding: 0,
  }
}));

export default HeaderStyles;
