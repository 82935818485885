import React from "react";
import { Grid } from "@material-ui/core";
import NewClient from "../NewClient/NewClient";
import ClientDetails from "../ClientDetails/ClientDetails";

const GridView = ({ clients, completed }) => {
  return (
    <Grid container spacing={4}>
      {!completed && (
        <Grid item lg={4} md={6} xs={12}>
          <NewClient />
        </Grid>
      )}
      {clients &&
        clients.map((client, index) => {
          return (
            <Grid item lg={4} md={6} xs={12} key={index} className={`${'dash-box-outer day-view1'}`}>
              <ClientDetails data={client} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default GridView;
