import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LoginStyles from "./LoginStyles";
import { MuiThemeProvider } from "@material-ui/core";
import "./Login.css";
import Container from "@material-ui/core/Container";
import LoginTheme from "./LoginTheme";
import { Form, Field } from "react-final-form";
import SessionFetches from "../../../Requests/Session/Session";
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_SUCCESS } from "../../../Redux/Actions/Types/Session/Session";
//import Link from "@material-ui/core/Link";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Copyright() {
  return (
    <Typography variant="body2" style={{ color: "white" }} align="center">
      Version 1.0 - Designed and Developed by Here Agency
    </Typography>
  );
}

export default function SignIn() {
  const classes = LoginStyles();
  const renderTF = (fieldName, title, placeholder) => {
    return (
      <Grid item xs={12}>
        <Field name={fieldName}>
          {({ input }) => (
            <input
              required
              placeholder={placeholder}
              type={fieldName}
              {...input}
              className="login-textfield-input"
            />
          )}
        </Field>
      </Grid>
    );
  };

  const dispatch = useDispatch();

  const handleLoginRequest = formObj => {
    const loginRequest = SessionFetches.requestLogin(formObj);
    loginRequest.then(response => {
      if (response.status) {
        if (response.data.token) {
          if (formObj.remember_me) {
            localStorage.setItem("supersecretkey", response.data.token);
          } else {
            sessionStorage.setItem("supersecretkey", response.data.token);
          }
          sessionStorage.setItem("supersecretkey", response.data.token);
          localStorage.setItem("supersecretkey", response.data.token);
          sessionStorage.setItem("loginUserName", response.data.name);
          toast.success(response.message);
          dispatch({
            type: LOGIN_SUCCESS
          });
        }
      } else {
        toast.error(response.message);
      }
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("supersecretkey") ||
      sessionStorage.getItem("supersecretkey")
    ) {
      dispatch({
        type: LOGIN_SUCCESS
      });
    }
  }, [dispatch]);

  const logged = useSelector(state => state.session.logged);
  // alert(classes.container);
  return (
    <MuiThemeProvider theme={LoginTheme}>

      {/* <div id="fdfd" className={classes.container}> */}
      <div className={`${classes.container} ${'loginContainer'}`}>
        {logged && <Redirect to="/" />}
        <Container component="main" maxWidth={"sm"}>
          <CssBaseline />
          <div className={`${classes.paper} ${'loginContainerInner'}`} >
            <div>
              <img
                src="/images/get-directed-logo.png"
                alt=""
                className="login-img-wrapper"
              />
            </div>
            <div style={{ padding: " 0px" }}>
              <Form
                onSubmit={formObj => {
                  //   dispatch({type: SESSION_REQUEST,
                  //     payload: SessionFetches.requestLogin(formObj)
                  // })}
                  handleLoginRequest(formObj);
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                      {renderTF("email", "", "E-mail")}
                      {renderTF("password", "", "Password")}
                      <Grid item xs={12}>
                        <div className="login-rem-for-wrapper">
                          <div className="login-rem-item-wrapper check-custom">
                            <Field
                              name="remember_me"
                              component="input"
                              type="checkbox"
                              id="rem"
                              defaultValue={false}
                              className="login-remember-me"
                            />
                            <label className="login-rem-item-label-wrapper" for="rem">
                              Remember Me
                            </label>
                          </div>
                          <div className="login-for-item-wrapper">
                            <Link to={"/forgot"} style={{ color: "#7f7f7f" }}>
                              <p>Forgot Password?</p>
                            </Link>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="login-submit-button-wrapper">
                          <Button className={classes.submit} type="submit">
                            LOGIN
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>
              <Box mt={8}>
                <Copyright />
              </Box>
            </div>
          </div>
        </Container>
      </div>
      <ToastContainer />
    </MuiThemeProvider>
  );
}
