import { makeStyles } from "@material-ui/core/styles";

const ChipButtonStyles = makeStyles(theme => ({
  chip: {
    margin: "12px 10px 0 0",
    backgroundColor: "#2C2C2C",
    color: "white"
  },
  staffTypeLetter: {
    padding: " 4px 8px",
    borderRadius: 25,
    margin: "0px 6px 0 -6px",
    lineHeight: 1,
    backgroundColor: "#F93D3D",
    fontSize: 17
  },
  active: {
    backgroundColor: "#FFB21B"
  }
}));

export default ChipButtonStyles;
