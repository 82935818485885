import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SearchBar from "../../../UI/SearchBar/SearchBar";
import DashboardStyles from "./DashboardStyles";
import { IconButton } from "@material-ui/core";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ViewListIcon from "@material-ui/icons/ViewList";

import "./Dashboard.css";
import GridView from "./GridView/GridView";
import ListView from "./ListView/ListView";
import Header from "../../../UI/Header/Header";
import AddStaffModal from "../../Staff/AddStaffModal/AddStaffModal";
import { SELECT_JOB } from "../../../../Redux/Actions/Types/Dashboard/Jobs";
import JobsActions from "../../../../Redux/Actions/dispatches/Jobs/Jobs";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

import Pagination from "react-js-pagination";
require("bootstrap");
require("bootstrap/less/bootstrap.less");

const Dashboard = () => {
  const classes = DashboardStyles();
  const dispatch = useDispatch();
  const utes = useSelector((state) => state.dashboard.utes);
  const tcs = useSelector((state) => state.dashboard.tcs);
  const loading = useSelector((state) => state.dashboard.loading);
  const [gridView, setGridView] = useState(true);
  const [openStaffModal, setOpenStaffModal] = useState(false);
  const [deleteJob, setDeleteJob] = useState(false);
  const jobs = useSelector((state) => state.jobs.jobs);
  const loggedJobs = useSelector((state) => state.dashboard.loggedJobs);
  const completedJobs = useSelector((state) => state.dashboard.completedJobs);
  const searchResults = useSelector((state) => state.dashboard.completedJobs);
  const selectedJob = useSelector((state) => state.dashboard.selectedJob);

  const currentLocation = window.location.pathname;

  const [searchJob, setSearchJob] = useState(loggedJobs);
  const [searchJobCompleted, setSearchJobCompleted] = useState(completedJobs);
  const today = moment().format("YYYY-MM-DD");
  const [dateSearch, setDateSearch] = useState(today);
  const [loggedJob, setLoggedJob] = useState(loggedJobs);
  const [completeJob, setCompleteJob] = useState(completedJobs);

  const [pageReload, setPageReload] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [filterLocation, setFilterLocation] = useState("");
  const [filterDate, setFilterDate] = useState("");

  const [totalItemsCount, setTotalItemsCount] = useState();
  const [itemsCountPerPage, setItemsCountPerPage] = useState();
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3);
  const [pageNo, setPageNo] = useState("1");
  const [serachKeyword, setSerachKeyword] = useState("");

  function utesCount() {
    var totalUTCS = 0;
    if (loggedJobs != undefined) {
      loggedJobs.forEach((item) => {
        totalUTCS += item.number_utes == null ? 0 : item.number_utes;
      });
      return totalUTCS;
    }
  }

  function tcsCount() {
    var totalTCS = 0;
    if (loggedJobs != undefined) {
      loggedJobs.forEach((item) => {
        totalTCS += item.number_trafic == null ? 0 : item.number_trafic;
      });
      return totalTCS;
    }
  }

  useEffect(() => {
    if (!jobs) {
      const formObj = {};
      formObj["date"] = dateSearch;
      formObj["page"] = pageNo;
      formObj["keyword"] = serachKeyword;

      /* if (window.sessionStorage.getItem("click-event") == 'edit' && window.sessionStorage.getItem("type") == 'job' && window.sessionStorage.getItem("record-status") == 'saved') {
      if (window.sessionStorage.getItem("page")) {
        formObj['page'] = window.sessionStorage.getItem("page");
        var pageFilter = parseInt(formObj['page']);
        setActivePage(pageFilter);
      }

      if (window.sessionStorage.getItem("keyword")) {
        formObj['keyword'] = window.sessionStorage.getItem("keyword");
        setSerachKeyword(window.sessionStorage.getItem("keyword"));
      }
	  
	  if(window.sessionStorage.getItem("date")){
		 setDateSearch(window.sessionStorage.getItem("date"));
         formObj["date"] = window.sessionStorage.getItem("date");   
	  }
	  
	  if(window.sessionStorage.getItem("dateOrder")){
		 setFilterDate(window.sessionStorage.getItem("dateOrder"));
         formObj["dateOrder"] = window.sessionStorage.getItem("dateOrder");   
	  }
	  
	  

      if (window.sessionStorage.getItem('locationOrder')) {
        formObj['locationOrder'] = window.sessionStorage.getItem('locationOrder');
        setFilterLocation(window.sessionStorage.getItem('locationOrder'));
      }
    
    } else {
      window.sessionStorage.setItem("page", pageNo);
      window.sessionStorage.setItem("keyword", serachKeyword);
    }
	  */

      dispatch(JobsActions.fetchJobs(formObj)).then((res) => {
        if (res.action.type == "FETCH_JOBS_LIST_FULFILLED") {
          if (res.value && res.value.status == true) {
            const filterredActiveJobs = res.value.data.data.filter(
              (job) => job.status === 0
            );
            setSearchJob(filterredActiveJobs);
            setLoggedJob(filterredActiveJobs);
            const filterredCompleteJobs = res.value.data.data.filter(
              (job) => job.status === 1
            );
            setSearchJobCompleted(filterredCompleteJobs);
            setCompleteJob(filterredCompleteJobs);
            setTotalItemsCount(res.value.data.total);
            setItemsCountPerPage(res.value.data.per_page);
          } else if (res.value && res.value.status == false) {
            //  toast.error(res.value.message);
          } else {
            // toast.error('Something went wrong');
          }
        }
      });
    } else if (jobs && !loggedJobs && !completedJobs) {
      const activeJobs = jobs.data.filter((job) => job.status === 0);
      const completedJobs = jobs.data.filter((job) => job.status === 1);
      dispatch(JobsActions.setActiveJobs(activeJobs));
      dispatch(JobsActions.setCompletedJobs(completedJobs));
    } else {
      if (jobs !== undefined && jobs !== null) {
        setTotalItemsCount(jobs.total);
        setItemsCountPerPage(jobs.per_page);
      }
    }

    if (openStaffModal && selectedJob) {
      dispatch(JobsActions.jobDetail(selectedJob.id));
    }
    if (deleteJob) {
      setDeleteJob(false);
      let response = dispatch(JobsActions.deleteJob(selectedJob.id));
      response.then((res) => {
        if (res.action.type === "DELETE_JOB_FULFILLED") {
          const activeJobs = jobs.data.filter((job) => job.status === 0);
          var arrloged = activeJobs.filter(function (obj) {
            return obj.id !== selectedJob.id;
          });
          const completedJobs = jobs.data.filter((job) => job.status === 1);
          var completedJ = completedJobs.filter(function (obj) {
            return obj.id !== selectedJob.id;
          });
          dispatch(JobsActions.setActiveJobs(arrloged));
          dispatch(JobsActions.setCompletedJobs(completedJ));
          toast.success(res.value.message);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      });
    }
    selectedJob ||
      dispatch({
        type: SELECT_JOB,
        payload: undefined,
      });
  }, [
    dispatch,
    loggedJobs,
    completedJobs,
    selectedJob,
    jobs,
    openStaffModal,
    dateSearch,
  ]);

  const handleDate = (e) => {
    setDateSearch(moment(e).format("YYYY-MM-DD"));
    const formObj = {};
    formObj["date"] = moment(e).format("YYYY-MM-DD");
    formObj["page"] = pageNo;
    formObj["keyword"] = serachKeyword;
    formObj["locationOrder"] = filterLocation;

    // window.sessionStorage.setItem("page", pageNo);
    // window.sessionStorage.setItem("keyword", serachKeyword);
    // window.sessionStorage.setItem("locationOrder", filterLocation);
    // window.sessionStorage.setItem("date", moment(e).format("YYYY-MM-DD"));

    dispatch(JobsActions.fetchJobs(formObj)).then((res) => {
      if (res.action.type == "FETCH_JOBS_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          const filterredActiveJobs = res.value.data.data.filter(
            (job) => job.status === 0
          );
          setSearchJob(filterredActiveJobs);
          const filterredCompleteJobs = res.value.data.data.filter(
            (job) => job.status === 1
          );
          setSearchJobCompleted(filterredCompleteJobs);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
          setLoggedJob(filterredActiveJobs);
          setCompleteJob(filterredCompleteJobs);
          dispatch(JobsActions.setActiveJobs(filterredActiveJobs));
          dispatch(JobsActions.setCompletedJobs(filterredCompleteJobs));
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  };

  const handleSearch = (e) => {
    //  alert()
    // var searchJobList = loggedJob
    //   .filter(
    //     job => `${job.address}`.toLocaleLowerCase().startsWith(e.target.value.toLocaleLowerCase().trim())

    //   );
    // setSearchJob(searchJobList);

    // var searchJobCompletedList = completeJob
    //   .filter(
    //     job => `${job.address}`.toLocaleLowerCase().startsWith(e.target.value.toLocaleLowerCase().trim())
    //   );
    // setSearchJobCompleted(searchJobCompletedList);

    setSerachKeyword(e.target.value);
    const formObj = {};
    formObj["page"] = pageNo;
    formObj["keyword"] = e.target.value.toLocaleLowerCase().trim();
    formObj["date"] = dateSearch;
    formObj["locationOrder"] = filterLocation;

    //window.sessionStorage.setItem("page", pageNo);
    // window.sessionStorage.setItem("keyword", e.target.value.toLocaleLowerCase().trim());
    // window.sessionStorage.setItem("locationOrder", filterLocation);
    // window.sessionStorage.setItem("date", dateSearch);

    dispatch(JobsActions.fetchJobs(formObj)).then((res) => {
      if (res.action.type == "FETCH_JOBS_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          const filterredActiveJobs = res.value.data.data.filter(
            (job) => job.status === 0
          );
          setSearchJob(filterredActiveJobs);
          const filterredCompleteJobs = res.value.data.data.filter(
            (job) => job.status === 1
          );
          setSearchJobCompleted(filterredCompleteJobs);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  };

  function handleFilterDate(value) {
    setFilterDate(value.target.value);
    const formObj = {};
    formObj["date"] = dateSearch;
    formObj["page"] = pageNo;
    formObj["keyword"] = serachKeyword;
    formObj["locationOrder"] = filterLocation;
    formObj["dateOrder"] = value.target.value;

    //window.sessionStorage.setItem("page", pageNo);
    // window.sessionStorage.setItem("keyword", serachKeyword);
    // window.sessionStorage.setItem("locationOrder", filterLocation);
    // window.sessionStorage.setItem("date", dateSearch);
    //  window.sessionStorage.setItem("dateOrder", value.target.value);

    dispatch(JobsActions.fetchJobs(formObj)).then((res) => {
      if (res.action.type == "FETCH_JOBS_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          const filterredActiveJobs = res.value.data.data.filter(
            (job) => job.status === 0
          );
          setSearchJob(filterredActiveJobs);
          const filterredCompleteJobs = res.value.data.data.filter(
            (job) => job.status === 1
          );
          setSearchJobCompleted(filterredCompleteJobs);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
          setLoggedJob(filterredActiveJobs);
          setCompleteJob(filterredCompleteJobs);
          dispatch(JobsActions.setActiveJobs(filterredActiveJobs));
          dispatch(JobsActions.setCompletedJobs(filterredCompleteJobs));
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }

  function handleFilterChange(value) {
    setFilterLocation(value.target.value);
    const formObj = {};
    formObj["date"] = dateSearch;
    formObj["page"] = pageNo;
    formObj["keyword"] = serachKeyword;
    formObj["locationOrder"] = value.target.value;

    //window.sessionStorage.setItem("page", pageNo);
    // window.sessionStorage.setItem("keyword", serachKeyword);
    // window.sessionStorage.setItem("locationOrder", value.target.value);
    //window.sessionStorage.setItem("date", dateSearch);

    dispatch(JobsActions.fetchJobs(formObj)).then((res) => {
      if (res.action.type == "FETCH_JOBS_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          const filterredActiveJobs = res.value.data.data.filter(
            (job) => job.status === 0
          );
          setSearchJob(filterredActiveJobs);
          const filterredCompleteJobs = res.value.data.data.filter(
            (job) => job.status === 1
          );
          setSearchJobCompleted(filterredCompleteJobs);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
          setLoggedJob(filterredActiveJobs);
          setCompleteJob(filterredCompleteJobs);
          dispatch(JobsActions.setActiveJobs(filterredActiveJobs));
          dispatch(JobsActions.setCompletedJobs(filterredCompleteJobs));
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    const formObj = {};
    formObj["date"] = dateSearch;
    formObj["page"] = pageNumber;
    formObj["keyword"] = serachKeyword;
    formObj["locationOrder"] = filterLocation;

    //window.sessionStorage.setItem("page", pageNumber);
    // window.sessionStorage.setItem("keyword", serachKeyword);
    //  window.sessionStorage.setItem("locationOrder",filterLocation);
    //window.sessionStorage.setItem("date", dateSearch);

    dispatch(JobsActions.fetchJobs(formObj)).then((res) => {
      if (res.action.type == "FETCH_JOBS_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          const filterredActiveJobs = res.value.data.data.filter(
            (job) => job.status === 0
          );
          setSearchJob(filterredActiveJobs);
          const filterredCompleteJobs = res.value.data.data.filter(
            (job) => job.status === 1
          );
          setSearchJobCompleted(filterredCompleteJobs);
          setLoggedJob(filterredActiveJobs);
          setCompleteJob(filterredCompleteJobs);
          dispatch(JobsActions.setActiveJobs(filterredActiveJobs));
          dispatch(JobsActions.setCompletedJobs(filterredCompleteJobs));
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header handleDate={handleDate} />
          <SearchBar handleSearch={handleSearch} />
          <AddStaffModal
            opened={openStaffModal}
            setOpened={setOpenStaffModal}
          />
          <div className="grid-view-outer">
            <div
              className={`${
                classes.jobCountInfoContainer
              } ${"utes-circle-outer"}`}
            >
              <p style={{ lineHeight: 2 }}>{"Nº UTES"}</p>
              <div className={`${classes.circleNumber} ${"utes-circle-col"}`}>
                <p>{utesCount()}</p>
              </div>
              <div style={{ paddingRight: "8px" }}>|</div>
              <p style={{ lineHeight: 2 }}>{"N° TC"}</p>
              <div className={`${classes.circleNumber} ${"utes-circle-col"}`}>
                <p>{tcsCount()}</p>
              </div>
            </div>
            <div className="filter-type">
              <span>Filter By </span>
              <select
                className="add-new-job-input-select"
                value={`${filterLocation}`}
                onChange={(e) => handleFilterChange(e)}
                id="client"
              >
                <option value="">Select Filter Type</option>
                <option value="ASC">Alphabetical Ascending</option>
                <option value="DESC">Alphabetical Descending</option>
                <option value="DateAsc">Date Ascending</option>
                <option value="DateDesc">Date Descending</option>
              </select>
            </div>
            <div className="search-view-type">
              <IconButton
                aria-label="carousel"
                className={`${gridView ? "search-tile-active" : ""}`}
                // style={gridView ? { backgroundColor: "#8FBE3E" } : {}}
                onClick={() => setGridView(true)}
              >
                <ViewModuleIcon />
              </IconButton>
              <IconButton
                aria-label="viewlist"
                className={`${!gridView ? "search-tile-active" : ""}`}
                onClick={() => setGridView(false)}
                // style={!gridView ? { backgroundColor: "#8FBE3E" } : {}}
              >
                <ViewListIcon />
              </IconButton>
            </div>
          </div>
          <div className="grids-outer-dashbard">
            {gridView ? (
              <GridView
                jobs={searchJob}
                openStaffModal={openStaffModal}
                setOpenStaffModal={setOpenStaffModal}
                setDeleteJob={setDeleteJob}
              />
            ) : (
              <ListView
                jobs={searchJob}
                openStaffModal={openStaffModal}
                setOpenStaffModal={setOpenStaffModal}
                setDeleteJob={setDeleteJob}
              />
            )}
          </div>
          <div className="dashboard-ljc">
            <p>Latest Jobs Completed</p>
            <div className="divider" />
          </div>
          <div className="grids-outer-dashbard">
            <div>
              {gridView ? (
                <GridView
                  // jobs={
                  //   searchResults && searchResults.resultsCompletedJobs
                  //     ? searchResults.resultsCompletedJobs
                  //     : completedJobs
                  // }
                  jobs={searchJobCompleted}
                  completed
                  openStaffModal={openStaffModal}
                  setOpenStaffModal={setOpenStaffModal}
                  setDeleteJob={setDeleteJob}
                />
              ) : (
                <ListView
                  jobs={searchJobCompleted}
                  completed
                  openStaffModal={openStaffModal}
                  setOpenStaffModal={setOpenStaffModal}
                  setDeleteJob={setDeleteJob}
                />
              )}
            </div>
          </div>
          {/* <Pagination
            prevPageText='Prev'
            nextPageText='Next'
            firstPageText='First'
            lastPageText='Last'
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={(e) => handlePageChange(e)}
          /> */}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Dashboard;
