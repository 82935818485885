import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FaqStyles from "./FaqStyles";
import "./Faq.css";
import Header from "../../UI/Header/Header";
import Sidebar from "../../UI/SideBar/Sidebar";
import SearchBarFaq from "../../UI/SearchBar/SearchBarFaq";
import ListView from "./UI/ListView/ListView";
import { useDispatch } from "react-redux";
import FaqActions from "../../../Redux/Actions/dispatches/Faq";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Faqs = () => {
  const classes = FaqStyles();
  const faqs = useSelector(state => state.faq.faq);
  const dispatch = useDispatch();
  const [searchFaq, setSearchFaq] = useState(faqs);

  useEffect(() => {
    dispatch(FaqActions.fetchFaq()).then((res) => {
      if (res.action.type == "FETCH_FAQ_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSearchFaq(res.value.data);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    })
  }, [dispatch]);

  const handleSearch = (e) => {
    var searchFaqList = faqs
      .filter(
        faq => faq.question.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase().trim())
          || `${faq.answer}`.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase().trim())
      );
    setSearchFaq(searchFaqList);
  }

  return (
    <Fragment>
      <div className={`${classes.root} ${'main-container'}`}>
        <Sidebar />
        <div className={`${classes.content} ${'main-content-area'}`}>
          <Header />
          <SearchBarFaq handleSearch={handleSearch} />
          <div className="grids-outer">
          <ListView faqs={searchFaq} faq />
</div>
        </div> 
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Faqs;
