import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from "react-final-form";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import ChipButton from "../../../UI/Elements/Buttons/ChipButton/ChipButton";
import "./DriverLicenseModal.css";
import DriverLicenseModalTheme from "./DriverLicenseModalTheme";
import { MuiThemeProvider } from "@material-ui/core";
import StaffActions from "../../../../Redux/Actions/dispatches/Staff";
import DragAndDrop from "../../../UI/Elements/DragAndDrop/DragAndDrop";
import moment from "moment";
import { NONAME } from "dns";
import $ from "jquery";

const DriverLicenseModal = ({ opened, setOpened, driverLicenseNo, setDriverLicenseNo, driverLicenseExpiryDate, setDriverLicenseExpiryDate, driverLicenseImage, setDriverLicenseImage, selectedDriverLicense, setSelectedDriverLicense }) => {

  const selectedStaff = useSelector(state => state.staff.selectedStaff)
  const today = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = React.useState(driverLicenseImage != undefined && driverLicenseImage != null ? driverLicenseImage : "/images/default-upload-popup.png");

  useEffect(() => {
  }, [dispatch, selectedStaff]);

  const style = theme => ({
    subTitle: {
      top: theme.spacing(-5),
      alignContent: "space-between",
      color: theme.palette.background.paper
    },
    closeButton: {
      marginLeft: "auto",
      backgroundColor: "#A3C960",
      padding: "4px",
      color: theme.palette.background.paper
    }
  });

  const addLicense = () => {
    var yellowCardLicenseNo = document.getElementById("yellow_card_license_no").value;
    var yellowCardExpiryDate = document.getElementById("yellow_card_expiry_date").value;


    if (yellowCardLicenseNo == "") {
      document.getElementById('errorDiv-yellow_card_license_no').innerHTML = "Please enter license number";
    } else {
      document.getElementById('errorDiv-yellow_card_license_no').innerHTML = "";
      setDriverLicenseNo(yellowCardLicenseNo);
    }

    if (yellowCardExpiryDate == "") {
      document.getElementById('errorDiv-yellow_card_expiry_date').innerHTML = "Please expiry date";
    } else {
      document.getElementById('errorDiv-yellow_card_expiry_date').innerHTML = "";
      setDriverLicenseExpiryDate(yellowCardExpiryDate);
    }

    if (yellowCardLicenseNo !== "" && yellowCardExpiryDate !== "") {
      setOpened(false);
      setSelectedDriverLicense(true);
    }
  };

  function onDriverImageChange(event) {
    setDriverLicenseImage(event.target.files[0]);
    setImagePreview(URL.createObjectURL(event.target.files[0]));
  }

  function onDriverImageSelect(e) {
    $("#driver_license_image").css('opacity', '0');
    e.preventDefault();
    $("#driver_license_image").trigger('click');
    $(".dragged-thumb").addClass('dragged-thumb-file-preview');

  }

  return (
    <Fragment>
      <MuiThemeProvider theme={DriverLicenseModalTheme}>
        <Dialog
          fullWidth open={opened}
          onClose={() => setOpened(false)}
          style={{ color: "#E8E8E8" }}
          disableBackdropClick
        >
          <div className="add-staff-modal-over pop-title-custom-over">
            <p>Driver License</p>
            <IconButton aria-label="close" style={style(DriverLicenseModalTheme).closeButton} onClick={() => {
              setOpened(false);
            }
            }>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent className="padd0 popupoverflowAuto">
            <div className="add-qualifcation-pop-outer">
              <div className="add-qualifcation-pop-left">

                {selectedStaff != undefined && selectedStaff != '' ? (
                  <div className="dragged-thumb dragged-thumb-file-preview">
                    <img src={imagePreview} alt="" />
                    <a href="#" id="select_driver_license_image" onClick={onDriverImageSelect}>Click to Upload New Image</a><input type="file" style={{ opacity: '0' }} id="driver_license_image" name="driver_license_image" onChange={onDriverImageChange} accept="image/*" />
                  </div>
                ) : (
                    <div className="dragged-thumb dragged-thumb-file-preview">
                      <img src={imagePreview} alt="" />
                      <a href="#" id="select_driver_license_image" onClick={onDriverImageSelect}>Click to Upload New Image</a><input type="file" style={{ opacity: '0' }} id="driver_license_image" name="driver_license_image" onChange={onDriverImageChange} accept="image/*" />
                    </div>)}
              </div>
              <div className="add-qualifcation-pop-right">
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title">
                    <p>License Number</p>
                  </div>
                  <input required="" placeholder="Enter License Number" type="text" name="yellow_card_license_no" maxLength={`20`} className="add-new-job-input-tf false" id="yellow_card_license_no" onChange={(id) => {
                    setDriverLicenseNo(id.target.value);
                  }}
                    value={driverLicenseNo}
                  />
                  <div className="error-message text-danger" id="errorDiv-yellow_card_license_no"></div>
                </div>
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title">
                    <p>Expiry Date</p>
                  </div>
                  <input required="" placeholder="Enter Expiry Date" type="date" min={today} name="yellow_card_expiry_date" className="add-new-job-input-tf false" id="yellow_card_expiry_date" onChange={(id) => {
                    setDriverLicenseExpiryDate(id.target.value);
                  }}
                    value={driverLicenseExpiryDate}
                  />
                  <div className="error-message text-danger" id="errorDiv-yellow_card_expiry_date"></div>
                </div>
                <div className="add-quali-btn">
                  <Button onClick={addLicense} style={{
                    background: "#A3C960",
                    borderRadius: 25,
                    color: "white",
                    padding: "6px 24px",
                    margin: "24px 0"
                  }}>
                    {/* {selectedStaff != undefined && selectedStaff != '' ? 'EDIT LICENSE' : 'ADD LICENSE'} */}
                    {selectedDriverLicense == true ? 'EDIT LICENSE' : 'ADD LICENSE'}
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </Fragment>
  );
};
export default DriverLicenseModal;
