import React from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { IconButton, Menu, MenuItem, Link, Button } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PopOverMenuStyles from "./PopOverMenuStyles";

const PopOverMenu = ({ options, icon, iconStyle }) => {
  const classes = PopOverMenuStyles();

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {popupState => (
        <React.Fragment>
          <IconButton
            aria-label="delete"
            {...bindTrigger(popupState)}
            style={iconStyle}
          >
            {icon ? icon : <MoreHorizIcon />}
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            {options.map((option, index) => {
              return (
                <MenuItem
                  onClick={popupState.close}
                  className={classes.menuItem}
                  key={`${option.label}-${index}`}
                >
                  {option.link ? (
                    <Link to={option.link} className={classes.link}>
                      <p>{option.label}</p>
                    </Link>
                  ) : (
                    <Button
                      onClick={option.action}
                      className={classes.button}
                    >
                      <p>{option.label}</p>
                    </Button>
                  )}
                </MenuItem>
              );
            })}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default PopOverMenu;
