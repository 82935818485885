import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import StaffMemberListStyles from "./StaffMemberListStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import JobDetailsTheme from "./StaffMemberListTheme";
import "./StaffMemberList.css";
import { SELECT_STAFF } from "../../../../../Redux/Actions/Types/Staff/Staff";
import { Link } from "react-router-dom";

const StaffMemberList = ({ data }) => {
  const classes = StaffMemberListStyles();
  const dispatch = useDispatch();
  const goToEdit = () => {
    dispatch({ type: SELECT_STAFF, payload: data });
  };
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={JobDetailsTheme}>
        <div className={classes.container}>
          <div style={{ display: "flex" }} className={'staff-list-outer'}>
            <div className={`staff-member-id-wrapper`}>
              <p>{`Staff ID GC-${data.id}`}</p>
            </div>
            <div className={`staff-member-name`}>
              <p>{data.name}</p>
            </div>
            <div className={`staff-member-field-title`}>
              <p>{`CURRENT JOB`}</p>
            </div>
            <div className={`staff-member-current-job`}>
              <p>{`UNASSIGNED`}</p>
            </div>
            <div className="staff-mini-divider" />
            <div className={`staff-member-field-title`}>
              <p>{`CONTACT`}</p>
            </div>
            <div className={`staff-member-default-value`}>
              <p>{data.mobile}</p>
            </div>
            <div className="staff-mini-divider" />

            <div className={`staff-member-field-title`}>
              <p>{`ROSTERED`}</p>
            </div>
            <div className={`staff-member-default-value`}>
              <p>{data.relationship}</p>
            </div>
            <div className="staff-member-location-edit-wrapper">
              <Link to={"/Staff/edit"} className={classes.link}>
                <Button
                  className={classes.editButton}
                  onClick={() => {
                    goToEdit();
                  }}
                >
                  Edit Details
                </Button>
              </Link>
              <div className="staff-member-location-wrapper">
                <img
                  className="staff-member-location"
                  src="/images/rcs/locationIcon.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default StaffMemberList;
