import { FETCH_EMAIL_TEMPLATE_LIST, DELETE_EMAIL_TEMPLATE } from "../Types/EmailTemplate/EmailTemplate";
import EmailTemplateFetches from "../../../Requests/EmailTemplate/EmailTemplate";

let EmailTemplateActions = {
  fetchEmailTemplate() {
    return {
      type: FETCH_EMAIL_TEMPLATE_LIST,
      payload: EmailTemplateFetches.fetchEmailTemplate()
    };
  },
  createEmailTemplate(data) {
    return {
      type: FETCH_EMAIL_TEMPLATE_LIST,
      payload: EmailTemplateFetches.createEmailTemplate(data)
    };
  },
  updateEmailTemplate(data) {
    return {
      type: FETCH_EMAIL_TEMPLATE_LIST,
      payload: EmailTemplateFetches.updateEmailTemplate(data)
    };
  },
  deleteEmailTemplate(faqId) {
    return {
      type: DELETE_EMAIL_TEMPLATE,
      payload: EmailTemplateFetches.deleteEmailTemplate(faqId)
    };
  },
  detailEmailTemplate(id) {
    return {
      type: FETCH_EMAIL_TEMPLATE_LIST,
      payload: EmailTemplateFetches.detailEmailTemplate(id)
    };
  },
};

export default EmailTemplateActions;
