import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import NewFaqStyles from "./NewFaqStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import NewFaqTheme from "./NewFaqTheme";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import "./NewFaq.css";

const NewFaq = ({ list }) => {
  const classes = NewFaqStyles();

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={NewFaqTheme}>
        <div className={classes.container}>
          <Link to={`/Faqs/new`} className={classes.link}>
            <Button className={classes.button}>
              <div className={`new-job-main-wrapper ${list && "new-job-main-wrapper-list"}`}>
                <div className={`new-job-inner-wrapper ${list && "new-job-inner-wrapper-list"}`}>
                  <AddCircleIcon className={classes.addCircle} />
                </div>
                <div className={`add-new-job ${list && "add-new-job-list"}`}>
                  <h4>{`Add a new Faq`}</h4>
                </div>
              </div>
            </Button>
          </Link>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default NewFaq;
