import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import StaffMemberStyles from "./StaffMemberStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import StaffMemberTheme from "./StaffMemberTheme";
import "./StaffMember.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { SELECT_STAFF } from "../../../../../Redux/Actions/Types/Staff/Staff";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const StaffMember = ({ data }) => {
  const classes = StaffMemberStyles();
  const dispatch = useDispatch();
  const goToEdit = () => {
    dispatch({ type: SELECT_STAFF, payload: data });
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={StaffMemberTheme}>
        {data && (
          <div className={`${classes.container} ${'grid-view-outer-block'}`}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexGrow: 1 }}>
                <div
                  className={`job-details-id pending`}
                >
                  <p>{`STAFF ID: GDTC${data.id}`}</p>
                </div>
              </div>
              <div>
                ROSTERED {data.rostered}
              </div>
            </div>
            <div
              style={{ display: "flex", textAlign: "left", padding: "0 8px" }}
            >
              <div
                style={{
                  textAlign: "left",
                  padding: "0 8px 8px",
                  fontSize: 20,
                  lineHeight: "26px",
                  color: "#535353"
                }}
              >
                <p>{data.name}</p>
              </div>
              <Fragment>
                <div className="job-details-info-wrapper">
                  <div className="job-details-info-title">
                    <p>CURRENT JOB {`GC-${data.current_job_id}`}</p>
                  </div>
                </div>
              </Fragment>
            </div>
            <div className="job-details-info-divider"></div>
            <div style={{ display: "flex", padding: "0 8px 12px" }}>
              <div className="jobs-details-utcs-wrapper">
                <div className="jobs-details-utcs-title">
                  <svg className="MuiSvgIcon-root makeStyles-addCircle-243" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
                </div>
                <div className="jobs-details-utcs-data">
                  <a title={data.mobile} href={`tel:${data.mobile}`} style={{ textDecoration: "none", color: "grey" }}>Contact</a>
                </div>
              </div>
              <div className="jobs-details-utcs-wrapper">
                <div className="jobs-details-utcs-title">
                  <svg className="MuiSvgIcon-root makeStyles-addCircle-243" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
                </div>
                <div className="jobs-details-utcs-data">
                  <a title={data.email} href={`mailto:${data.email}`} style={{ textDecoration: "none", color: "grey" }}>Locate</a>
                </div>
              </div>
              <div className="jobs-details-utcs-wrapper">
                <div className="jobs-details-utcs-title">
                  <svg className="MuiSvgIcon-root makeStyles-addCircle-243" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>
                </div>
                <div className="jobs-details-utcs-data">
                  <Link to={"/Staff/edit"} className={classes.link}>
                    <Button
                      className={classes.editButton}
                      onClick={() => {
                        goToEdit();
                      }}
                    >
                      Edit Details
                </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </MuiThemeProvider>
    </Fragment >
  );
};

export default StaffMember;
