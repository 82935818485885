import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import HeaderStyles from "./HeaderStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import HeaderTheme from "./HeaderTheme";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import IconButton from "@material-ui/core/IconButton";
import AvatarIcon from "../Icons/AvatarIcon/AvatarIcon";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatesRail from "../DatesRail/DatesRail";
import "./Header.css";
import { LOGOUT } from "../../../Redux/Actions/Types/Session/Session";
import PopOverMenu from "../Elements/PopoverMenu/PopOverMenu";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Redirect, Link } from "react-router-dom";
import { isMobile } from 'react-device-detect';

// import MomentLocaleUtils, {
//   formatDate,
//   parseDate,
// } from 'react-day-picker/moment';

// import 'moment/locale/it';

const getWeek = () => {
  var startOfWeek = moment()
    .startOf("week")
    .add("1", "day");
  var endOfWeek = moment()
    .endOf("week")
    .add("2", "days");
  var days = [];
  var day = startOfWeek;
  while (day <= endOfWeek) {
    days.push({ label: day.format("ddd Do"), date: day.toDate(), state: false });
    day = day.clone().add(1, "d");
  }
  return days;
};
const HeaderNotLink = ({ handleDate }) => {
  const classes = HeaderStyles();
  const dispatch = useDispatch();
  const today = moment().format("Do MMMM YYYY");
  const handleLogout = () => {
    dispatch({ type: LOGOUT });
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.removeItem("supersecretkey"); 
    localStorage.removeItem('supersecretkey');
    //  window.location.reload();
    window.location.href = '/';
  };
  const [startDate, setStartDate] = useState();

  const profileOptions = [{ label: "Log out", action: handleLogout }];

  const handleGoBack = () => {
    return (
      <Redirect
        to={{
          pathname: "/"
        }}
      />
    );
  };

  const renderJobOptions = newJob => {
    return (
      <div className="header-options-wrapper header-links">
        <div className={`header-links-left`}>
          <Button
            className={classes.goBackButton}
            onClick={() => {
              handleGoBack();
            }}
          >
            <Link to="/" style={{ textDecoration: "none", display: "flex" }}>
              <ArrowBackIosIcon style={{ fontSize: 16 }} />
              <p>Go Back</p>
            </Link>
          </Button>
          <div className="header-options-divider"></div>
          {newJob ? <p>Add New Job</p> : <p>Edit Job Created</p>}
        </div>

      </div>
    );
  };
  const renderStaffOptions = newStaff => {
    return (
      <div className="header-options-wrapper header-links mobile-view-with-link">
        <div className={`header-links-left`}>
          <Button
            className={classes.goBackButton}
            onClick={() => {
              handleGoBack();
            }}
          >
            <Link to="/Staff" style={{ textDecoration: "none", display: "flex" }}>
              <ArrowBackIosIcon style={{ fontSize: 16 }} />
              <p>Go Back</p>
            </Link>
          </Button>
          <div className="header-options-divider"></div>
          {newStaff ? <p>Add New Staff</p> : <p>Edit Staff Created</p>}
        </div>

      </div>
    );
  };

  const renderDashboardOptions = () => {
    return (
      <Fragment>
        <div className="header-section-wrapper-1">
          <div className="header-calendar-input-wrapper">
            <IconButton style={{ paddingTop: 4 }}>
              <CalendarTodayIcon />
            </IconButton>
          </div>
          <DayPickerInput
            // formatDate={formatDate}
            showPopperArrow={false}
            //  format="DD-MM-YYYY"
            selected={startDate}
            //onChange={date => setStartDate(date)}
            onDayChange={handleDate}
            inputProps={{
              style: {
                padding: "8px 8px 8px 34px",
                border: "none",
                borderRadius: 6,
                background: startDate && "none",
                fontFamily: "futura-pt-book",
                fontSize: 14,
                color: "#7F7F7F",
                outline: "none"
              },
              placeholder: "Select Date"
            }}
          />
        </div>
        <div className="header-section-wrapper-min mobile-view">
          <div className={classes.menuContainer}>
            <div>
              <Link to={`${`/Notifications`}`} >
                <IconButton aria-label="notifications" style={{ padding: 4 }}>
                  <NotificationsNoneIcon />
                </IconButton>
              </Link>
            </div>
            <div style={{ padding: "4px 12px 4px 8px", color: "#535353" }}>
              <p>{sessionStorage.getItem("loginUserName")}</p>
            </div>
            <div>
              <PopOverMenu
                options={profileOptions}
                icon={<AvatarIcon />}
                iconStyle={{ backgroundColor: "#8FBE3E", padding: 4 }}
              />
            </div>
          </div>
        </div>
        <div className="header-section-wrapper-2 calendar-block">
          <DatesRail dates={getWeek()} handleDate={handleDate} />
        </div>
      </Fragment>
    );
  };

  const renderNotificationOptions = () => {
    return (

      <div className="header-options-wrapper header-links mobile-view-without-link">

        <div className="header-section-wrapper-1">
          <div className="header-calendar-input-wrapper">
            <IconButton style={{ paddingTop: 4 }}>
              <CalendarTodayIcon />
            </IconButton>
          </div>
        </div>
        <div className="header-section-wrapper-min mobile-view">
          <div className={classes.menuContainer}>
            <div>
              <Link to={`${`/Notifications`}`} >
                <IconButton aria-label="notifications" style={{ padding: 4 }}>
                  <NotificationsNoneIcon />
                </IconButton>
              </Link>
            </div>
            <div style={{ padding: "4px 12px 4px 8px", color: "#535353" }}>
              <p>{sessionStorage.getItem("loginUserName")}</p>
            </div>
            <div>
              <PopOverMenu
                options={profileOptions}
                icon={<AvatarIcon />}
                iconStyle={{ backgroundColor: "#8FBE3E", padding: 4 }}
              />
            </div>
          </div>
        </div>
        <div className={`header-links-right`}>
          <Fragment>
            <div className="header-section-wrapper-1">
              <div className="header-calendar-input-wrapper">
                <IconButton style={{ paddingTop: 4 }}>
                  <CalendarTodayIcon />
                </IconButton>
              </div>
              <DayPickerInput
                showPopperArrow={false}
                selected={startDate}
                onDayChange={handleDate}
                inputProps={{
                  style: {
                    padding: "8px 8px 8px 34px",
                    border: "none",
                    borderRadius: 6,
                    background: startDate && "none",
                    fontFamily: "futura-pt-book",
                    fontSize: 14,
                    color: "#7F7F7F",
                    outline: "none"
                  },
                  placeholder: "Select Date"
                }}
              />
            </div>
          </Fragment>
          <div className="header-date-block">{today}</div>
        </div>
      </div>

    )

  };

  const renderClientOptions = newClient => {
    return (
      <div className="header-options-wrapper header-links mobile-view-with-link">
        <div className={`header-links-left`}>
          <Button
            className={classes.goBackButton}
            onClick={() => {
              handleGoBack();
            }}
          >
            <Link to="/Clients" style={{ textDecoration: "none", display: "flex" }}>
              <ArrowBackIosIcon style={{ fontSize: 16 }} />
              <p>Go Back</p>
            </Link>
          </Button>
          <div className="header-options-divider"></div>
          {newClient ? <p>Add New Client</p> : <p>Edit Client Created</p>}
        </div>

      </div>
    );
  };

  const renderClientHistoryOptions = newClientHistory => {
    return (
      <div className="header-options-wrapper header-links mobile-view-with-link">
        <div className={`header-links-left`}>
          <Button
            className={classes.goBackButton}
            onClick={() => {
              handleGoBack();
            }}
          >
            <Link to="/Clients" style={{ textDecoration: "none", display: "flex" }}>
              <ArrowBackIosIcon style={{ fontSize: 16 }} />
              <p>Go Back</p>
            </Link>
          </Button>
          <div className="header-options-divider"></div>
          {`History`}
        </div>
        <div className="header-section-wrapper-1">
          <div className="header-calendar-input-wrapper">
            <IconButton style={{ paddingTop: 4 }}>
              <CalendarTodayIcon />
            </IconButton>
          </div>

        </div>

      </div>
    );
  };


  const renderFaqOptions = newFaq => {
    return (
      <div className="header-options-wrapper header-links mobile-view-with-link">
        <div className={`header-links-left`}>
          <Button
            className={classes.goBackButton}
            onClick={() => {
              handleGoBack();
            }}
          >
            <Link to="/Faqs" style={{ textDecoration: "none", display: "flex" }}>
              <ArrowBackIosIcon style={{ fontSize: 16 }} />
              <p>Go Back</p>
            </Link>
          </Button>
          <div className="header-options-divider"></div>
          {newFaq ? <p>Add New Faq</p> : <p>Edit Faq Created</p>}
        </div>

      </div>
    );
  };

  const renderJobReportOptions = newFaq => {
    return (
      <div className="header-options-wrapper header-links mobile-view-with-link">
        <div className={`header-links-left`}>
          <Button
            className={classes.goBackButton}
            onClick={() => {
              handleGoBack();
            }}
          >
            <Link to="/Jobs" style={{ textDecoration: "none", display: "flex" }}>
              <ArrowBackIosIcon style={{ fontSize: 16 }} />
              <p>Go Back</p>
            </Link>
          </Button>
          <div className="header-options-divider"></div>
          <p>Job Completion Report</p>
        </div>

      </div>
    );
  };


  const renderEmailTemplateOptions = newFaq => {
    return (
      <div className="header-options-wrapper header-links mobile-view-with-link">
        <div className={`header-links-left`}>
          <Button
            className={classes.goBackButton}
            onClick={() => {
              handleGoBack();
            }}
          >
            <Link to="/EmailTemplates" style={{ textDecoration: "none", display: "flex" }}>
              <ArrowBackIosIcon style={{ fontSize: 16 }} />
              <p>Go Back</p>
            </Link>
          </Button>
          <div className="header-options-divider"></div>
          {newFaq ? <p>Add New Email Template</p> : <p>Edit Email Template Created</p>}
        </div>
      </div>
    );
  }

  const renderOptions = () => {
    const currentLocation = window.location.pathname;
    var clientData = currentLocation.split('/');
    var clientId = clientData[clientData.length - 1];

    switch (window.location.pathname) {
      case "/":
      case "/Jobs":
        return renderDashboardOptions();
      case "/Jobs/new":
        return renderJobOptions(true);
      case "/Jobs/edit":
        return renderJobOptions();
      case `/Jobs/report/${clientId}`:
        return renderJobReportOptions(true);
      case "/Staff/new":
        return renderStaffOptions(true);
      case "/Staff/edit":
        return renderStaffOptions();
      case "/Clients/new":
        return renderClientOptions(true);
      case "/Clients/edit":
        return renderClientOptions();
      case `/Clients/history/${clientId}`:
        return renderClientHistoryOptions(true);
      case "/Notifications":
        return renderNotificationOptions();
      case "/Faqs/new":
        return renderFaqOptions(true);
      case "/Faqs/edit":
        return renderFaqOptions();
      case "/EmailTemplates/new":
        return renderEmailTemplateOptions(true);
      case "/EmailTemplates/edit":
        return renderEmailTemplateOptions();
      default:
        break;
    }
  };
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={HeaderTheme}>
        <div className={`${classes.container} ${'dashboard-content-area-header head-section-3'}`}>
          {/* {isMobile && ( */}
          <a href="#" className="sidebar-switch-menu">
            <img src="/images/menu-toggle.png" />
          </a>
          {/* )} */}
          <div className="header-section-wrapper-min mobile-view-without-link">
            <div className={classes.menuContainer}>
              <div>
                <Link to={`${`/Notifications`}`} >
                  <IconButton aria-label="notifications" style={{ padding: 4 }}>
                    <NotificationsNoneIcon />
                  </IconButton>
                </Link>
              </div>
              <div style={{ padding: "4px 12px 4px 8px", color: "#535353" }}>
                <p>{sessionStorage.getItem("loginUserName")}</p>
              </div>
              <div>
                <PopOverMenu
                  options={profileOptions}
                  icon={<AvatarIcon />}
                  iconStyle={{ backgroundColor: "#8FBE3E", padding: 4 }}
                />
              </div>
            </div>
          </div>
          {renderOptions()}
          <div className="header-section-wrapper-min desktop-view">
            <div className={classes.menuContainer}>
              <div>
                <Link to={`${`/Notifications`}`} >
                  <IconButton aria-label="notifications" style={{ padding: 4 }}>
                    <NotificationsNoneIcon />
                  </IconButton>
                </Link>
              </div>
              <div style={{ padding: "4px 12px 4px 8px", color: "#535353" }}>
                <p>{sessionStorage.getItem("loginUserName")}</p>
              </div>
              <div>
                <PopOverMenu
                  options={profileOptions}
                  icon={<AvatarIcon />}
                  iconStyle={{ backgroundColor: "#8FBE3E", padding: 4 }}
                />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default HeaderNotLink;
