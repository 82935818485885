import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import SectionDividerAssignStaff from "../../../UI/Elements/SectionDivider/SectionDividerAssignStaff";
import ChipButton from "../../../UI/Elements/Buttons/ChipButton/ChipButtonDashboard";
import "./AddStaffModal.css";
import AddStaffModalTheme from "./AddStaffModalTheme";
import { MuiThemeProvider } from "@material-ui/core";
import StaffActions from "../../../../Redux/Actions/dispatches/Staff";
import JobsAction from "../../../../Redux/Actions/dispatches/Jobs/Jobs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
var _ = require('lodash');

const AddStaffModal = ({ opened, setOpened, staffs, setStaffs, type }) => {
  const staffList = useSelector(state => state.staff.staff);
  const selectedJob = useSelector(state => state.jobs.detailedJob)
  //const [innerStaffs, setInerStaffs] = useState([]);
  const [innerStaffs, setInerStaffs] = useState([]);
  const [searchStaff, setSearchStaff] = useState(staffList);
  const [maxTrafficController, setMaxTrafficController] = useState(selectedJob !== undefined ? selectedJob.number_trafic : '');

  const dispatch = useDispatch();
  useEffect(() => {
    if (!staffList) {
      dispatch(StaffActions.fetchStaff());
    } else {
      setSearchStaff(staffList);
    }
    if (selectedJob) {
      selectedJob.staffs != undefined && selectedJob.staffs.map((staff) => {
        staffList.map((staffListData) => {
          if (staffListData.id === staff.id) {
            staffListData.memberType = staff.relationship;
            staffListData.is_available = true;
          }
        });
      });
      setInerStaffs(selectedJob.staffs);

      setTeamLeadId(selectedJob != undefined ? selectedJob.team_leader_id : '');
      setTeamLeadName(selectedJob != undefined && selectedJob.team_leader_name != 'N/A' ? selectedJob.team_leader_name : 'Select a leader from the staff list');
      setTeamLeadSelected(selectedJob != undefined && selectedJob.team_leader_id != '' ? 'selected' : '');
      setMaxTrafficController(selectedJob !== undefined ? selectedJob.number_trafic : '');
    }
  }, [dispatch, staffList, selectedJob]);

  const staffSwitch = () => {
    //if (selectedJob !== undefined && staffs.length > 0) {
    if (staffs) {
      return staffs;
    }
    else {
      return innerStaffs;
    }
  };

  const addMember = (member, relationship, jobId) => {
    if ((innerStaffs.length + 1) <= maxTrafficController) {
      var newStaffs = Array.from(staffSwitch());
      if (!newStaffs.find((e) => e.id === member.id)) {
        var newStaffMember = { ...member, relationship: relationship, job_id: jobId };
        if (member.memberType !== undefined) {
          newStaffs.push(newStaffMember);
        } else {
          alert('Please select role type');
        }

        setInerStaffs(newStaffs)
      }
    } else {
      alert(`You can not select more than ${maxTrafficController} traffic controllers.`);
    }
  };
  const deleteMember = (id) => {
    var newStaffs = staffSwitch().filter(e => e.id !== id);
    setInerStaffs(newStaffs);
    if (teamLeadId != undefined && teamLeadId == id) {
      setTeamLeadId('');
      setTeamLeadName('Select a leader from the staff list');
      setTeamLeadSelected('');
    }

    var arrStudent = _.cloneDeep(searchStaff)
    arrStudent.map((member, index) => {
      if (member.id == id) {
        // member.memberType = '';
        member.memberType = undefined;
      }
    });
    setSearchStaff(arrStudent);

  };


  const updateMembers = () => {
    var isTeamLead = teamLeadName;
    if (isTeamLead == "Select a leader from the staff list") {
      // document.getElementById('errorDiv-is_team_lead').innerHTML = "*Please add team leader to proceed";
      setTeamLeadError('*Please add team leader to proceed');
    }
    else {
      //document.getElementById('errorDiv-is_team_lead').innerHTML = "";
      setTeamLeadError('');
    }
    const finalData = {};
    finalData.job_id = selectedJob.id;
    var mappedData = [];
    const assignArray = innerStaffs.map(data => {
      var teamLead = data.id == teamLeadId ? true : false;
      var obj = { "staff_id": data.id, "job_id": selectedJob.id, "type": data.relationship, "is_team_lead": teamLead }
      mappedData.push(obj)
    });
    finalData.staffs = mappedData;

    if (isTeamLead !== "Select a leader from the staff list") {
      dispatch(JobsAction.updateJobStaff(finalData)).then((res) => {
        if (res.action.type == "UPDATE_JOB_STAFF_FULFILLED") {
          if (res.value && res.value.status == true) {
            toast.success(res.value.message);
            setOpened(false);
            setInterval(function () { window.location.reload(); }, 1000);
          } else if (res.value && res.value.status == false) {
            toast.error(res.value.message);
          } else {
            toast.error('Something went wrong');
          }

        }
      });
    }
  };
  const handleSearch = (e) => {
    if (e.target) {
      setSearchStaff(staffList.filter(s => s.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1))
    }
  }

  function setStaffRole(type, staffId) {
    if ((innerStaffs.length + 1) <= maxTrafficController) {
      var arrStudent = _.cloneDeep(searchStaff)
      let arrData = arrStudent.find((data) => data.memberType === 'D')
      // if (arrData !== undefined && type === 'D') {

      // } else {
      arrStudent.map((member, index) => {
        if (member.id == staffId) {
          member.memberType = type;
          member.is_available = true;
        }
      });
      // }
      setSearchStaff(arrStudent);
    } else {
      alert(`You can not select more than ${maxTrafficController} traffic controllers.`);

    }
  }

  const style = theme => ({
    subTitle: {
      // position: 'absolute',
      // right: theme.spacing(1),
      top: theme.spacing(-5),
      alignContent: "space-between",
      color: theme.palette.background.paper
    },
    closeButton: {
      marginLeft: "auto",
      backgroundColor: "#A3C960",
      padding: "4px",
      color: theme.palette.background.paper
    }
  });

  const [teamLeadId, setTeamLeadId] = React.useState(selectedJob != undefined ? selectedJob.team_leader_id : '');
  const [teamLeadName, setTeamLeadName] = React.useState(selectedJob != undefined && selectedJob.team_leader_name != 'N/A' ? selectedJob.team_leader_name : 'Select a leader from the staff list');
  const [teamLeadSelected, setTeamLeadSelected] = React.useState(selectedJob != undefined && selectedJob.team_leader_id != '' ? 'selected' : '');
  const [teamLeadError, setTeamLeadError] = React.useState('');
  function oncustomSelectToggle(e) {
    $(this).toggleClass('active');
    $(".custom-select ").toggleClass('open');
  }

  function onOptionSelect(optionId, optionName) {
    $(".custom-select ").toggleClass('open');
    setTeamLeadSelected('selected');
    setTeamLeadId(optionId);
    setTeamLeadName(optionName);
    setTeamLeadError('');
  }

  return (
    <Fragment>
      <MuiThemeProvider theme={AddStaffModalTheme}>
        <Dialog
          fullWidth
          open={opened}
          onClose={() => setOpened(false)}
          style={{ color: "#E8E8E8" }}
          disableBackdropClick
        >
          <div className="add-staff-modal-over pop-title-custom-over" >
            <p>Search, add or remove Traffic Controllers. Add staff or Close to exit</p>
            <IconButton aria-label="close" style={style(AddStaffModalTheme).closeButton} onClick={() => { setOpened(false); window.location.reload(); }}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogTitle>
            <SectionDividerAssignStaff title={"Assign Staff"} selectedJob={selectedJob} />
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item className="assign-staff-col">
                <div className="add-staff-block-title">
                  <p>Search Crew members</p>
                  <div>
                    <input
                      onChange={handleSearch}
                      placeholder="Search for a crew member"
                      className="add-staff-search-input"
                    />
                    <div className="add-staff-crew-wrapper custom-scroll">
                      {searchStaff &&
                        searchStaff.map((member, index) => {
                          return (
                            <div
                              className="add-staff-member-content-wrapper"
                              key={`${member.id}-${index}`}
                            >
                              <div className="add-staff-member-name-wrapper">
                                <p>{member.name}</p>
                              </div>
                              {member.is_available === true ? (
                                <div className="add-staff-member-letters-wrapper">
                                  <div
                                    className={`add-staff-member-letter-wrapper  ${member.memberType != undefined ? member.memberType === 'D' ? 'add-job-green-cirlce' : '' : ''}`}
                                    onClick={() => {
                                      setStaffRole('D', member.id)
                                    }}
                                  >
                                    D
                                </div>
                                  <div
                                    className={`add-staff-member-letter-wrapper ${member.memberType != undefined ? member.memberType === 'S' ? 'add-job-green-cirlce' : '' : ''}`}
                                    // onClick={() => addMember(member, 'S', type === true ? '0' : selectedJob.id)}
                                    onClick={() => setStaffRole('S', member.id)}
                                  >
                                    S
                                </div>
                                  <div className="add-staff-member-add-job">
                                    <p><a href="javascript:void(0)" onClick={() => addMember(member, member.memberType, selectedJob !== undefined ? selectedJob.id : '0')}>Add to job</a></p>
                                  </div>
                                </div>
                              ) : (
                                  <div className="unavailable-area">
                                    <p style={{ color: "#A9A9A9", alignSelf: 'right' }}>Unavailable</p>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item className="assign-staff-col">
                <div className="add-staff-block-title">
                  <p>Current Members Selected</p>
                  <span style={{ fontFamily: "futura-pt-demi" }}>You can select maximum {maxTrafficController} staff for this job.</span>
                  <div className="member-select-outer">
                    {staffSwitch() &&
                      staffSwitch().map((member, index) => {
                        return (
                          <ChipButton
                            title={member.name}
                            driver={member.relationship === 'D'}
                            active
                            id={member.id}
                            onDelete={deleteMember}
                            key={member.id}
                          />
                        );
                      })}
                  </div>
                </div>

                <div className="assign-team-leader-select">
                  <div className="add-new-job-field-title">
                    <p>{"Assign Team Leader"}</p>
                  </div>
                  <div className="custom-select">
                    <a href="javascript:void()" onClick={oncustomSelectToggle} className={`custom-select-toggle  ${teamLeadSelected}`}> <span>{teamLeadName}</span></a>
                    <div className="custom-select-value">
                      <ul className="custom-scroll">
                        {staffSwitch() &&
                          staffSwitch().map((option, index) => {
                            return (
                              <li>
                                <a href="javascript:void()"
                                  onClick={(id) => {
                                    var optionId = option.staff_id !== undefined && option.staff_id != null && option.staff_id != '' ? option.staff_id : option.id; //selectedJob !== undefined && staffs.length > 0 ? option.id : option.staff_id;
                                    onOptionSelect(optionId, option.name);
                                  }}
                                >
                                  {option.name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  {teamLeadError != undefined && teamLeadError != '' && (
                    <div className="custom-select-error">
                      <div className="error-message text-danger" id={`errorDiv-is_team_lead`}>{teamLeadError}</div>
                    </div>)}
                </div>

              </Grid>
              <Grid item xs={12}>
                <div style={{ width: "max-content", margin: "0 0 0 auto" }}>
                  <Button
                    onClick={updateMembers}
                    style={{
                      background: "#A3C960",
                      borderRadius: 25,
                      color: "white",
                      padding: "6px 24px",
                      margin: "24px 0"
                    }}
                  >
                    {selectedJob !== undefined && selectedJob.staffs.length > 0 ? 'UPDATE STAFF' : 'ADD STAFF'}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default AddStaffModal;
