import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import EmailTemplateDetailsListStyles from "./EmailTemplateDetailsListStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import EmailTemplateDetailsListTheme from "./EmailTemplateDetailsListTheme";
import "./EmailTemplateDetailsList.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import EmailTemplateActions from "../../../../../Redux/Actions/dispatches/EmailTemplate";

import { SELECT_EMAIL_TEMPLATE } from "../../../../../Redux/Actions/Types/EmailTemplate/EmailTemplate";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmailTemplateDetailsList = ({ data }) => {
  const classes = EmailTemplateDetailsListStyles();
  const dispatch = useDispatch();
  const goToEdit = () => {
    dispatch({ type: SELECT_EMAIL_TEMPLATE, payload: data });
  };

  function deleteThisEmailTemplate(emailTemplateId) {
    dispatch(EmailTemplateActions.deleteEmailTemplate(emailTemplateId)).then((res) => {
      
      if (res.action.type == "DELETE_EMAIL_TEMPLATE_FULFILLED") {
        if (res.value && res.value.status == true) {
          toast.success(res.value.message);
          window.location = '/EmailTemplates';
        } else if (res.value && res.value.status == false) {
          toast.error(res.value.message);
        } else {
          toast.error('Something went wrong');
        }
      }
    });
  }

  const handleDeleteEmailTemplate = (emailTemplateId) => {
    confirmAlert({
      title: 'Get Directed',
      message: 'Are you sure you want to delete this Email Template?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteThisEmailTemplate(emailTemplateId)
        },
        {
          label: 'No',
        }
      ]
    })
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={EmailTemplateDetailsListTheme}>
        <div className="emailtemplate-outer">
          <div className="emailtemplate-title">{data.name}</div>
          <div className="emailtemplate-content">{data.subject}</div>
          <div className="emailtemplate-actions">
            <Link to={"/EmailTemplates/edit"} className={classes.link} onClick={() => {
              goToEdit();
            }}>
              Edit
          </Link>
            <Link to={"#"} className={classes.link} onClick={() => {
              handleDeleteEmailTemplate(data.id)
            }}>
              Delete
          </Link>
          </div>
        </div>
      </MuiThemeProvider>
      <ToastContainer />
    </Fragment>
  );
};

export default EmailTemplateDetailsList;
