import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import ClientDetailsStyles from "./ClientDetailsStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import ClientDetailsTheme from "./ClientDetailsTheme";
import "./ClientDetails.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { SELECT_CLIENT } from "../../../../../Redux/Actions/Types/Client/Client";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const ClientDetails = ({ data }) => {
  const classes = ClientDetailsStyles();
  const dispatch = useDispatch();
  const goToEdit = () => {
    window.sessionStorage.setItem("click-event", "edit");
    window.sessionStorage.setItem("type", "client");
    window.sessionStorage.getItem("keyword");
    window.sessionStorage.getItem("locationOrder");

    dispatch({ type: SELECT_CLIENT, payload: data });
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={ClientDetailsTheme}>
        {data && (
          <div
            className={`${
              classes.container
            } ${"grid-view-outer-block block-space-between"}`}
          >
            <div className="client-title-section">
              <p>{data.company_name}</p>
              <Link
                to={`/Clients/history/${data.id}`}
                className={classes.link}
                style={{ color: "#8FBE3E", textAlign: "right" }}
              >
                <p>
                  <img src="/images/green-clock.svg" alt="" />{" "}
                  <span> Job History</span>
                </p>
              </Link>
            </div>
            <div className="client-grid-btm">
              <div
                className="job-det-info-inline"
                style={{ display: "flex", textAlign: "left", padding: "0 8px" }}
              >
                <div className="job-details-info-wrapper">
                  <div className="job-details-info-title">
                    <p>Contact </p>
                  </div>
                  <div className="job-details-info-data">
                    <p>{data.name}</p>
                  </div>
                </div>

                <Fragment>
                  <div className="job-details-info-wrapper">
                    <div className="job-details-info-title">
                      <p>Logged Jobs</p>
                    </div>
                    <div className="job-details-info-data">
                      <p>{data.total_job}</p>
                    </div>
                  </div>
                </Fragment>
              </div>

              <div className="job-details-info-divider"></div>
              <div style={{ display: "flex", padding: "0 8px 12px" }}>
                <div className="bottom-links-with-icon">
                  <div className="bottom-links-col">
                    <img src="/images/gray-call-circle-icon.svg" alt="" />
                    <a
                      title={data.mobile}
                      href={`tel:${data.mobile}`}
                      style={{ textDecoration: "none", color: "grey" }}
                    >
                      Contact
                    </a>
                    {/* <Link title={data.mobile} to={`mailto:${data.mobile}`} style={{ textDecoration: "none", color: "grey" }}>Contact</Link> */}
                  </div>
                  <div className="bottom-links-col">
                    <img src="/images/gray-msg-circle-icon.svg" alt="" />
                    <a
                      title={data.email}
                      href={`mailto:${data.email}`}
                      style={{ textDecoration: "none", color: "grey" }}
                    >
                      Email
                    </a>
                    {/* <Link title={data.email} to={`mailto:${data.email}`} style={{ textDecoration: "none", color: "grey" }}>Email</Link> */}
                  </div>
                  <div className="bottom-links-col">
                    <img src="/images/gray-edit-circle-icon.svg" alt="" />
                    <Link
                      to={"/Clients/edit"}
                      className={classes.link}
                      onClick={() => {
                        goToEdit();
                      }}
                    >
                      Edit Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MuiThemeProvider>
    </Fragment>
  );
};

export default ClientDetails;
