import { makeStyles } from "@material-ui/core/styles";

const JobDetailsStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: 8,
    padding: "0 8px"
  },
  menuItem: {
    color: "#535353",
    fontSize: 12
  },
  link: {
    textDecoration: "none",
    color: "#535353"
  }
}));

export default JobDetailsStyles;
