import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ForgotPassStyles from "./ForgotPassStyles";
import { MuiThemeProvider } from "@material-ui/core";
import "./ForgotPass.css";
import Container from "@material-ui/core/Container";
import ForgotPassTheme from "./ForgotPassTheme";
import { Form, Field, Fade, Input } from "react-final-form";
import ForgotFetches from "../../../Requests/Session/Forgot";
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FORGOT_SUCCESS } from "../../../Redux/Actions/Types/Session/Forgot";
//import Link from "@material-ui/core/Link";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Copyright() {
  return (
    <Typography variant="body2" style={{ color: "white" }} align="center">
      Version 1.0 - Designed and Developed by Here Agency
    </Typography>
  );
}

const ForgotPass = () => {

  const classes = ForgotPassStyles();
  const renderTF = (fieldName, title, placeholder) => {
    return (
      <Grid item xs={12}>
        <Field name={fieldName}>
          {({ input }) => (
            <input
              required
              placeholder={placeholder}
              type={fieldName}
              {...input}
              className="login-textfield-input"
              validations={['required', 'isEmail']}
            />
          )}
        </Field>
      </Grid>
    );
  };

  const dispatch = useDispatch();

  const handleForgotRequest = formObj => {
    const requestForgot = ForgotFetches.requestForgot(formObj);
    requestForgot.then(response => {
      if (response) {
        if (response.status) {
          toast.success(response.message);
          dispatch({
            type: FORGOT_SUCCESS
          });
          //window.location.href = '/login';
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error(response.message);
      }
    });
  };

  return (
    <MuiThemeProvider theme={ForgotPassTheme}>
      <div className={`${classes.container} ${'loginContainer forgot-pass-container'}`}>
        <Container component="main" maxWidth={"sm"}>
          <CssBaseline />
          <div className={`${classes.paper} ${'loginContainerInner'}`} >
            <div>
              <img
                src="/images/get-directed-logo.png"
                alt=""
                className="login-img-wrapper"
              />
            </div>
            <div style={{ padding: " 0px" }}>
              <Form
                onSubmit={formObj => { handleForgotRequest(formObj); }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                      {renderTF("email", "", "Email Address")}
                      <Grid item xs={12}>
                        <div className="login-rem-for-wrapper">
                          <div className="login-for-item-wrapper">
                            <Link to={"/login"} variant="body2" style={{ color: "#7f7f7f" }}>
                              <p>Back to login</p>
                            </Link>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="login-submit-button-wrapper">
                          <Button className={classes.submit} type="submit">
                            Forgot
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>
              <Box mt={8}>
                <Copyright />
              </Box>
            </div>
          </div>
              </Container>
      </div>

      <ToastContainer />

    </MuiThemeProvider>

  );
};
export default ForgotPass;
