export const FETCH_EMAIL_TEMPLATE_LIST = "FETCH_EMAIL_TEMPLATE_LIST";
export const FETCH_EMAIL_TEMPLATE_LIST_PENDING = "FETCH_EMAIL_TEMPLATE_LIST_PENDING";
export const FETCH_EMAIL_TEMPLATE_LIST_FULFILLED = "FETCH_EMAIL_TEMPLATE_LIST_FULFILLED";
export const FETCH_EMAIL_TEMPLATE_LIST_REJECTED = "FETCH_EMAIL_TEMPLATE_LIST_REJECTED";

export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const DELETE_EMAIL_TEMPLATE_PENDING = "DELETE_EMAIL_TEMPLATE_PENDING";
export const DELETE_EMAIL_TEMPLATE_FULFILLED = "DELETE_EMAIL_TEMPLATE_FULFILLED";
export const DELETE_EMAIL_TEMPLATE_REJECTED = "DELETE_EMAIL_TEMPLATE_REJECTED";

export const SELECT_EMAIL_TEMPLATE = "SELECT_EMAIL_TEMPLATE";

