import { makeStyles } from "@material-ui/core/styles";

const AddNewStaffStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#E8E8E8",
    paddingTop: 0,
    padding: theme.spacing(3),
    maxWidth: 1500,
    margin: "auto",
    minHeight: 80
  }
}));

export default AddNewStaffStyles;
