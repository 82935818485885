import { FETCH_NOTIFICATION_LIST, DELETE_NOTIFICATION } from "../Types/Notification/Notification";
import NotificationFetches from "../../../Requests/Notification/Notification";

let NotificationActions = {
  fetchNotification(data) {
    return {
      type: FETCH_NOTIFICATION_LIST,
      payload: NotificationFetches.fetchNotification(data)
    };
  },
  createNotification(data) {
    return {
      type: FETCH_NOTIFICATION_LIST,
      payload: NotificationFetches.createNotification(data)
    };
  },
  updateNotification(data) {
    return {
      type: FETCH_NOTIFICATION_LIST,
      payload: NotificationFetches.updateNotification(data)
    };
  },
  deleteNotification() {
    return {
      type: DELETE_NOTIFICATION,
      payload: NotificationFetches.deleteNotification()
    };
  }
};

export default NotificationActions;
