import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import StaffMemberStyles from "./StaffMemberStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import StaffMemberTheme from "./StaffMemberTheme";
import "./StaffMember.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { SELECT_STAFF } from "../../../../../Redux/Actions/Types/Staff/Staff";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const StaffMember = ({ data }) => {
  const classes = StaffMemberStyles();
  const dispatch = useDispatch();
  const goToEdit = () => {
	  window.sessionStorage.setItem("click-event",'edit');
	  window.sessionStorage.setItem("type",'staff');
    dispatch({ type: SELECT_STAFF, payload: data });
  };
  const mapURL = `https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`;
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={StaffMemberTheme}>
        {data && (
          <div className={`${classes.container} ${'grid-view-outer-block block-space-between'}`}>
            <div className="staff-grid-top-area">
              <div className={`job-details-id pending`}>
                <p><span className="id-text-light">STAFF ID: </span> {`GDTC${data.id}`}</p>
              </div>
              <div className="grid-date-right staff-mem-page">
                <p>ROSTERED <span className="value-green">{data.rostered}</span></p>
              </div>
              <div className="grid-title-outer">
                <div className="grid-title">{data.name}</div>
                <div className="job-det-info-inline">
                  <div className="job-details-info-wrapper">
                    <div className="job-details-info-title">
                      <p>CURRENT JOB <span className="value-green">{data.current_job_id}</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="staff-grid-btm-area">
              <div className="job-details-info-divider"></div>
              <div style={{ display: "flex", padding: "0 8px 12px" }}>
                <div className="bottom-links-with-icon">
                  <div className="bottom-links-col">
                    <img src="/images/gray-call-circle-icon.svg" alt="" />
                    <a title={data.mobile} href={`tel:${data.mobile}`} style={{ textDecoration: "none", color: "grey" }}>Contact</a>
                  </div>
                  <div className="bottom-links-col">
                    <img src="/images/gray-locate-circle-icon.svg" alt="" />
                    {/* <a target="_blank" title={data.address} href={`${mapURL}`} style={{ textDecoration: "none", color: "grey" }}>Locate</a> */}
                    <Link to={"/Location"} style={{ textDecoration: "none", color: "grey" }}>Locate</Link>
                  </div>
                  <div className="bottom-links-col">
                    <img src="/images/gray-edit-circle-icon.svg" alt="" />
                    <Link to={"/Staff/edit"} className={classes.link} onClick={() => {
                      goToEdit();
                    }}>
                      Edit Details
            </Link>
                  </div>
                </div>

              </div>
            </div>
          </div>

        )}
      </MuiThemeProvider>
    </Fragment>
  );
};

export default StaffMember;
