import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import FaqDetailsListStyles from "./FaqDetailsListStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import FaqDetailsListTheme from "./FaqDetailsListTheme";
import "./FaqDetailsList.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import FaqActions from "../../../../../Redux/Actions/dispatches/Faq";

import { SELECT_FAQ } from "../../../../../Redux/Actions/Types/Faq/Faq";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FaqDetailsList = ({ data, deleteJob, setDeleteJob }) => {
  const classes = FaqDetailsListStyles();
  const dispatch = useDispatch();

  const goToEdit = () => {
    dispatch({ type: SELECT_FAQ, payload: data });
  };

  function deleteThisFaq(faqId) {
    dispatch(FaqActions.deleteFaq(faqId)).then((res) => {
      if (res.action.type == "DELETE_FAQ_FULFILLED") {
        if (res.value && res.value.status == true) {
          toast.success(res.value.message);
          window.location = "/Faqs";
        } else if (res.value && res.value.status == false) {
          toast.error(res.value.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  }

  const handleDeleteFaq = (faqId) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to delete this Faq?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteThisFaq(faqId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={FaqDetailsListTheme}>
        <div className="faq-outer">
          <div className="faq-title">{data.question}</div>
          <div className="faq-content">{data.answer}</div>
          <div className="faq-actions">
            <Link
              to={"/Faqs/edit"}
              className={classes.link}
              onClick={() => {
                goToEdit();
              }}
            >
              Edit
            </Link>
            <Link
              to={"#"}
              className={classes.link}
              onClick={() => {
                handleDeleteFaq(data.id);
              }}
            >
              Delete
            </Link>
          </div>
        </div>
      </MuiThemeProvider>
      <ToastContainer />
    </Fragment>
  );
};

export default FaqDetailsList;
