import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ClientsIcon = ({ props, className }) => {
  return (
    <SvgIcon {...props} className={className}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="sign-out-alt"
        className="svg-inline--fa fa-sign-out-alt fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <path
          d="M13.564 0.636524C14.119 0.0409301 14.9645 0.0787426 15.4995 0.519649C16.0857 1.00368 16.1548 1.85646 15.7183 2.43802L13.4681 5.43846C12.8518 6.26027 11.8703 6.75087 10.8432 6.75087L5.30419 6.75087C5.32263 7.28143 5.03828 7.80268 4.52738 8.05796L3.49088 8.57637C3.25931 8.69196 2.97778 8.59824 2.86184 8.36668L0.0495002 2.74202C-0.0660935 2.51046 0.0276252 2.22893 0.259188 2.11299L1.29569 1.5949C1.70413 1.39084 2.17847 1.39615 2.58469 1.61115L3.48838 0.815586C4.72019 -0.268414 6.576 -0.271851 7.81156 0.805274C8.13562 1.05493 8.19809 1.12618 8.38466 1.12618L10.8433 1.12618C11.4735 1.12618 12.0082 1.54302 12.1863 2.11549L13.564 0.636524ZM12.7183 4.87607L14.9684 1.87563C15.1113 1.68563 15.0912 1.40782 14.9056 1.24535C14.7281 1.09004 14.4388 1.07316 14.25 1.27566L12.1864 3.49085C11.9215 3.7755 11.5465 3.93863 11.1574 3.93863L8.44945 3.93863C8.19073 3.93863 7.98073 3.72863 7.98073 3.46991C7.98073 3.21119 8.1907 3.00119 8.44945 3.00119L10.8434 3.00119C11.1018 3.00119 11.3121 2.79088 11.3121 2.53247C11.3121 2.27407 11.1018 2.06375 10.8434 2.06375L8.38477 2.06375C8.04789 2.06375 7.72448 1.97719 7.20452 1.51941C6.32177 0.742879 4.99058 0.742879 4.10783 1.51941L3.2207 2.30029L4.97717 5.8135L10.8434 5.8135C11.5771 5.8135 12.278 5.46322 12.7183 4.87607ZM4.10809 7.21961L3.49094 7.52833L1.09766 2.74208L1.71513 2.43336C1.94669 2.31773 2.22791 2.41117 2.34384 2.64305L4.31778 6.59092C4.43341 6.82245 4.33966 7.10367 4.10809 7.21961Z"
          transform="translate(0 7.373291)"
          id="Shape"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M4.71844 1.90613C4.71844 2.40706 4.52063 2.86203 4.19972 3.19856C5.07253 3.68978 5.65587 4.62659 5.65591 5.68716L5.65591 6.15587C5.65591 6.41459 5.44591 6.62459 5.18719 6.62459L0.468719 6.62459C0.21 6.62459 0 6.41459 0 6.15587L0 5.68716C0 4.60534 0.616219 3.68291 1.47334 3.20231C1.12775 2.85013 0.937438 2.39172 0.937438 1.90613C0.937438 0.87275 1.81019 0 2.84356 0C3.88475 0 4.71844 0.874313 4.71844 1.90613ZM2.84369 0.9375C3.35178 0.9375 3.78113 1.38122 3.78113 1.90619C3.78113 2.42303 3.36053 2.84362 2.84369 2.84362C2.31872 2.84362 1.875 2.41428 1.875 1.90619C1.875 1.39028 2.32778 0.9375 2.84369 0.9375ZM2.84362 3.78101C1.81025 3.78101 0.9375 4.65376 0.9375 5.68713L4.7185 5.68713C4.7185 4.63594 3.87731 3.78101 2.84362 3.78101Z"
          transform="translate(5.65625 0)"
          id="Shape"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
      </svg>
    </SvgIcon>
  );
};

export default ClientsIcon;
