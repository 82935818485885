let JobsRequests = {
  fetchJobs(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/listweb?page=${data['page']}`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`

      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  fetchJobs1() {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`

      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  createNewJob(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/create`, {
      method: "POST",
      // body: JSON.stringify(data), // data can be `string` or {object}!
      body: data, // data can be `string` or {object}!
      headers: {
        "Accept": "application/json",
        // "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  editJob(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/edit`, {
      method: "POST",
      body: data, // data can be `string` or {object}!
      headers: {
        "Accept": "application/json",
        //"Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Methods": "*",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  deleteJob(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  confirmJob(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/confirm/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  jobDetail(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/show/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //   "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  jobReport(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/report/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //   "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  updateJobStaff(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/assign_staffs`, {
      method: "POST",
      //body: JSON.stringify({ staffs: staff.map(e => e.id) }), // data can be `string` or {object}!
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  exportJob(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/export/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  updateJobStaff1(id, staff) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/${id}/staffs`, {
      method: "POST",
      body: JSON.stringify({ staffs: staff.map(e => e.id) }), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  }
};

export default JobsRequests;
