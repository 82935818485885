import { makeStyles } from "@material-ui/core/styles";

const StaffMemberListStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: 8,
    padding: "0 8px"
  },
  menuItem: {
    color: "#535353",
    fontSize: 12
  },
  link: {
    textDecoration: "none",
    color: "#535353"
  },
  editButton: {
    backgroundColor: "#8fbe3e",
    borderRadius: 25,
    color: "white",
    fontFamily: "futura-pt-demi",
    fontSize: 14,
    padding: 8,
    height: 40,
    margin: "4px 24px"
  }
}));

export default StaffMemberListStyles;
