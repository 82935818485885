import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import ClientHistoryDetailsStyles from "./ClientHistoryDetailsStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import ClientHistoryDetailsTheme from "./ClientHistoryDetailsTheme";
import "./ClientHistoryDetails.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";

import { SELECT_CLIENT } from "../../../../../Redux/Actions/Types/Client/Client";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const ClientHistoryDetails = ({ data }) => {
  const classes = ClientHistoryDetailsStyles();
  const dispatch = useDispatch();
  const goToEdit = () => {
    dispatch({ type: SELECT_CLIENT, payload: data });
  };

  const today = moment().format("YYYY-MM-DD");

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={ClientHistoryDetailsTheme}>
        {data && (
          <div className={`${classes.container} ${'grid-view-outer-block'}`}>
            <div className={`job-details-id ${0 == data.status && false == data.is_accepted ? "incomplete" : 0 == data.status && true === data.is_accepted ? "in-progress" : "completed"} `}>
              <p>{`job ID: GC-${data.id}`}</p>
            </div>
            <div className="grid-date-right">
              <p><img src="/images/calendar-icon.svg" alt="" /> <span>{today == data.date ? 'Today' : moment(data.date).format('DD-MM-YYYY')}</span></p>
            </div>
            <div className="grid-title">
              {data.address ? (`${data.address},`) : ('-')}{data.location ? (`${data.location}`) : ('')}
            </div>
            <div style={{ display: "flex", padding: "0 8px 12px" }}>
              {data.status != 1 ? (
                <div className="jobs-details-utcs-wrapper">
                  <div className="jobs-details-utcs-title">
                    <p>{"N° UTES "}</p>
                  </div>
                  <div className="jobs-details-utcs-data">
                    <p>{data.number_utes ? (`${data.number_utes}`) : ""}</p>
                  </div>
                </div>
              ) : (" ")}
              {data.status != 1 ? (
                <div className="jobs-details-utcs-wrapper">
                  <div className="jobs-details-utcs-title">
                    <p>{"N° TCS "}</p>
                  </div>
                  <div className="jobs-details-utcs-data">
                    <p style={{ color: "#8FBE3E" }}>
                      {data.accepted_count}
                    </p>/
            <p>
                      {data.number_trafic}
                    </p>
                  </div>
                </div>
              ) : (" ")}
              {data.status != 1 && data.is_accepted == true ? (
                <Fragment>
                  <div className="jobs-details-utcs-wrapper">
                    <div className="jobs-details-utcs-title">
                      <p>{"START TIME"}</p>
                    </div>
                    <div className="jobs-details-utcs-data">
                      <p>{`${data.time_req_site}`}</p>
                    </div>
                  </div>
                </Fragment>
              ) : (
                  " "
                )}
              {data.status == 1 ? (
                <Fragment>
                  <div className="jobs-details-utcs-wrapper">
                    <div className="jobs-details-utcs-title job-report-link">
                      <Link to={`/Jobs/report/${data.id}`} style={{ textDecoration: "none" }}>
                        <p
                          style={{ cursor: "pointer" }}
                        >
                          {"View Job Report"}
                        </p>
                      </Link>
                    </div>
                  </div>
                </Fragment>
              ) : (
                  " "
                )}
              {data.tbc && data.status == 0 ? (
                <div className="jobs-details-utcs-wrapper" style={{ display: "block" }}>
                  <div className="jobs-details-tbc">{"TBC"}</div>
                </div>
              ) : (
                  <div />
                )}
            </div>
          </div>
        )}
      </MuiThemeProvider>
    </Fragment>
  );
};

export default ClientHistoryDetails;
