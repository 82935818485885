import { makeStyles } from "@material-ui/core/styles";

const NewJobStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#8FBE3E",
    borderRadius: 8
  },
  button: {
    width: "100%",
    height: "100%"
  },
  addCircle: {
    fontSize: "2em"
  },
  link: {
    textDecoration: "none",
    color: "white"
  }
}));

export default NewJobStyles;
