import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddNewStaffStyles from "./AddNewStaffStyles";
import "./AddNewStaff.css";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import Header from "../../../UI/Header/HeaderLink";
import { Form, Field } from "react-final-form";
import { Grid, Button } from "@material-ui/core";
import DragAndDropTfns from "../../../UI/Elements/DragAndDrop/DragAndDropTfns";
import DragAndDropSwms from "../../../UI/Elements/DragAndDrop/DragAndDropSwms";
import StaffActions from "../../../../Redux/Actions/dispatches/Staff";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAdress,
  getLatLng,
} from "react-places-autocomplete";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import DriverLicenseModal from "../../Staff/DriverLicenseModal/DriverLicenseModal";
import IndustryAuthorityModel from "../../Staff/IndustryAuthorityModel/IndustryAuthorityModel";
import TrafficManagementModel from "../../Staff/TrafficManagementModel/TrafficManagementModel";
import ConstructionInductionModel from "../../Staff/ConstructionInductionModel/ConstructionInductionModel";

import $ from "jquery";
var _ = require("lodash");
const renderTF = (fieldName, title, placeholder, disabled, value, required) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFM = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  required
) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            type="tel"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
            minLength={`10`}
            maxLength={`16`}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderCHArray = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  setVehicle
) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <div className="add-new-job-field-cb-wrapper add-staff-radio-btn">
        <div className="radio-custom-circle">
          <Field name={fieldName} component="input" type="radio" value={"1"}>
            {(props) => (
              <input
                {...props.input}
                disabled={disabled}
                checked={value == "1" ? true : false}
                onChange={(e) => setVehicle(1)}
                className="add-new-job-field-cb"
                id="yes"
              ></input>
            )}
          </Field>
          <label htmlFor="yes" className="add-new-job-field-cb-label">
            Yes
          </label>
        </div>
        <div className="radio-custom-circle">
          <Field name={fieldName} component="input" type="radio" value={"0"}>
            {(props) => (
              <input
                {...props.input}
                disabled={disabled}
                checked={value == "0" ? true : false}
                onChange={(e) => setVehicle(0)}
                className="add-new-job-field-cb"
                id="no"
              ></input>
            )}
          </Field>
          <label htmlFor="no" className="add-new-job-field-cb-label">
            No
          </label>
        </div>
      </div>
    </Grid>
  );
};

const renderSelect = (
  fieldName,
  title,
  placeholder,
  options,
  disabled,
  value,
  setRelationship
) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {(props) => (
          <select
            {...props.input}
            className="add-new-job-input-select"
            disabled={disabled}
            value={value}
            onChange={(id) => {
              setRelationship(id.target.value);
            }}
          >
            {options.map((option, key) => {
              return <option key={`realationship-${key}`} value={`${option.id}`}>{option.label}</option>;
            })}
          </select>
        )}
      </Field>
    </Grid>
  );
};
//const relationshipsOption = ["Relative","Partner","Friend"];
const relationshipsOption = [
  { id: 1, label: "Relative" },
  { id: 2, label: "Partner" },
  { id: 3, label: "Friend" },
];

const handleSubmitButton = () => {
  var name = document.forms["staff-form"]["name"].value;
  var email = document.forms["staff-form"]["email"].value;
  var mobile = document.forms["staff-form"]["mobile"].value;
  var address = document.forms["staff-form"]["address"].value.trim();
  var start_date = document.forms["staff-form"]["start_date"].value;
  var contact_name = document.forms["staff-form"]["contact_name"].value;
  var contact_phone = document.forms["staff-form"]["contact_phone"].value;

  var status = false;
  var formIsValid = true;

  if (name == "") {
    document.getElementById("errorDiv-name").innerHTML =
      "Please enter staff name";
    var formIsValid = false;
  } else {
    document.getElementById("errorDiv-name").innerHTML = "";
    var status = true;
    var formIsValid = true;
  }

  //regular expression for email validation
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (email == "") {
    document.getElementById("errorDiv-email").innerHTML = "Please enter email";
    var formIsValid = false;
  } else if (email != "" && !pattern.test(email)) {
    var formIsValid = false;
    var validEmail = false;
    document.getElementById("errorDiv-email").innerHTML =
      "Please enter valid email";
  } else {
    document.getElementById("errorDiv-email").innerHTML = "";
    var status = true;
    var formIsValid = true;
    var validEmail = true;
  }

  if (mobile == "") {
    document.getElementById("errorDiv-mobile").innerHTML =
      "Please enter mobile number";
    var formIsValid = false;
  } else if (
    mobile != "" &&
    !mobile.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
  ) {
    var formIsValid = false;
    var validMobile = false;
    document.getElementById("errorDiv-mobile").innerHTML =
      "Please enter valid mobile number";
  } else {
    document.getElementById("errorDiv-mobile").innerHTML = "";
    var status = true;
    var formIsValid = true;
    var validMobile = true;
  }

  if (address == "") {
    document.getElementById("errorDiv-address").innerHTML =
      "Please enter address";
    var formIsValid = false;
  } else {
    document.getElementById("errorDiv-address").innerHTML = "";
    var status = true;
    var formIsValid = true;
  }

  if (start_date == "") {
    document.getElementById("errorDiv-start_date").innerHTML =
      "Please enter start date";
    var formIsValid = false;
  } else {
    document.getElementById("errorDiv-start_date").innerHTML = "";
    var status = true;
    var formIsValid = true;
  }

  if (contact_name == "") {
    document.getElementById("errorDiv-contact_name").innerHTML =
      "Please enter contact name";
    var formIsValid = false;
  } else {
    document.getElementById("errorDiv-contact_name").innerHTML = "";
    var status = true;
    var formIsValid = true;
  }

  if (contact_phone == "") {
    document.getElementById("errorDiv-contact_phone").innerHTML =
      "Please enter contact phone";
    var formIsValid = false;
  } else if (
    contact_phone != "" &&
    !contact_phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
  ) {
    var formIsValid = false;
    var validMobile = false;
    var validContactPhone = false;
    document.getElementById("errorDiv-mobile").innerHTML =
      "Please enter valid mobile number";
  } else {
    document.getElementById("errorDiv-contact_phone").innerHTML = "";
    var status = true;
    var formIsValid = true;
    var validContactPhone = true;
  }

  if (
    status == true &&
    formIsValid == true &&
    name !== "" &&
    email !== "" &&
    validEmail == true &&
    mobile !== "" &&
    validMobile == true &&
    address !== "" &&
    start_date !== "" &&
    contact_name !== "" &&
    contact_phone !== "" &&
    validContactPhone == true
  ) {
    document
      .getElementById("staff-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  }
};

const AddNewStaff = () => {
  const classes = AddNewStaffStyles();
  const currentLocation = window.location.pathname;
  const today = moment().format("YYYY-MM-DD");
  const todayAU = moment().format("DD-MM-YYYY");
  const dispatch = useDispatch();
  const selectedStaff = useSelector((state) => state.staff.selectedStaff);

  const [openDriverLicenseModal, setOpenDriverLicenseModal] = useState(false);
  const [openIndustryAuthorityModel, setOpenIndustryAuthorityModel] =
    useState(false);
  const [openTrafficManagementModel, setOpenTrafficManagementModel] =
    useState(false);
  const [openConstructionInductionModel, setOpenConstructionInductionModel] =
    useState(false);

  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);

  if (selectedStaff != undefined && currentLocation == "/Staff/new") {
    window.location.reload();
  }

  const handleStaffProcess = (formObj) => {
    setSubmitButtonDisable(true);
    const body = new FormData();

    // formObj["vehicle"] = vehicle;
    // formObj["address"] = address;
    // formObj["yellow_card_license_no"] = driverLicenseNo;
    // formObj["yellow_card_expiry_date"] = driverLicenseExpiryDate !== undefined && driverLicenseExpiryDate !== '' ? moment(driverLicenseExpiryDate).format("YYYY-MM-DD") : '';
    // formObj["sky_blue_card_authority_no"] = authorityNo;
    // formObj["sky_blue_card_expiry_date"] = authorityExpiryDate !== undefined && authorityExpiryDate !== '' ? moment(authorityExpiryDate).format("YYYY-MM-DD") : '';
    // formObj["blue_card_card_no"] = trafficCardNo;
    // formObj["blue_card_rto_no"] = trafficRtoNo;
    // formObj["blue_card_issue_date"] = trafficIssueDate !== undefined && trafficIssueDate !== '' ? moment(trafficIssueDate).format("YYYY-MM-DD") : '';
    // formObj["blue_card_expiry_date"] = trafficExpiryDate !== undefined && trafficExpiryDate !== '' ? moment(trafficExpiryDate).format("YYYY-MM-DD") : '';
    // formObj["red_card_card_no"] = constructionCardNo;
    // formObj["red_card_rto_no"] = constructionRtoNo;
    // formObj["red_card_issue_date"] = constructionIssueDate !== undefined && constructionIssueDate !== '' ? moment(constructionIssueDate).format("YYYY-MM-DD") : '';

    body.append("vehicle", vehicle);
    body.append(
      "address",
      address !== undefined && address !== null && address !== "" ? address : ""
    );
    body.append(
      "yellow_card_license_no",
      driverLicenseNo !== undefined &&
        driverLicenseNo !== null &&
        driverLicenseNo !== ""
        ? driverLicenseNo
        : ""
    );
    body.append(
      "yellow_card_expiry_date",
      driverLicenseExpiryDate !== undefined &&
        driverLicenseExpiryDate !== "" &&
        driverLicenseExpiryDate !== null
        ? moment(driverLicenseExpiryDate).format("YYYY-MM-DD")
        : ""
    );
    body.append(
      "sky_blue_card_authority_no",
      authorityNo !== undefined && authorityNo !== null && authorityNo !== ""
        ? authorityNo
        : ""
    );
    body.append(
      "sky_blue_card_expiry_date",
      authorityExpiryDate !== undefined &&
        authorityExpiryDate !== "" &&
        authorityExpiryDate !== null
        ? moment(authorityExpiryDate).format("YYYY-MM-DD")
        : ""
    );
    body.append(
      "blue_card_card_no",
      trafficCardNo !== undefined &&
        trafficCardNo !== null &&
        trafficCardNo !== ""
        ? trafficCardNo
        : ""
    );
    body.append(
      "blue_card_rto_no",
      trafficRtoNo !== undefined && trafficRtoNo !== null && trafficRtoNo !== ""
        ? trafficRtoNo
        : ""
    );
    body.append(
      "blue_card_issue_date",
      trafficIssueDate !== undefined &&
        trafficIssueDate !== "" &&
        trafficIssueDate !== null
        ? moment(trafficIssueDate).format("YYYY-MM-DD")
        : ""
    );
    body.append(
      "blue_card_expiry_date",
      trafficExpiryDate !== undefined &&
        trafficExpiryDate !== "" &&
        trafficExpiryDate !== null
        ? moment(trafficExpiryDate).format("YYYY-MM-DD")
        : ""
    );
    body.append(
      "red_card_card_no",
      constructionCardNo !== undefined &&
        constructionCardNo !== null &&
        constructionCardNo !== ""
        ? constructionCardNo
        : ""
    );
    body.append(
      "red_card_rto_no",
      constructionRtoNo !== undefined &&
        constructionRtoNo !== null &&
        constructionRtoNo !== ""
        ? constructionRtoNo
        : ""
    );
    body.append(
      "red_card_issue_date",
      constructionIssueDate !== undefined &&
        constructionIssueDate !== "" &&
        constructionIssueDate !== null
        ? moment(constructionIssueDate).format("YYYY-MM-DD")
        : ""
    );

    body.append("name", formObj.name);
    body.append("email", formObj.email);
    body.append("mobile", formObj.mobile);
    body.append("contact_name", formObj.contact_name);
    body.append("contact_phone", formObj.contact_phone);
    body.append("relationship", formObj.relationship);
    body.append(
      "vehicle_registration",
      formObj.vehicle_registration !== undefined
        ? formObj.vehicle_registration
        : ""
    );
    body.append("start_date", today);

    // if (typeof tfns === 'object' && tfns !== '' && tfns !== undefined && tfns !== null && tfns.length > 0) {
    //   body.append("tfns[]", tfns, tfns.name);
    // }

    if (tfns !== undefined && tfns !== null && tfns.length > 0) {
      var i;
      for (i = 0; i < tfns.length; i++) {
        body.append(`tfns[${i}]`, tfns[i], tfns[i].name);
      }
    }

    body.append(
      "tfns_ids",
      removedTfns != undefined && removedTfns !== null ? removedTfns : []
    );

    if (typeof driverLicenseImage === "object") {
      body.append(
        "driver_license_image",
        driverLicenseImage,
        driverLicenseImage.name
      );
    }

    if (typeof authorityImage === "object") {
      body.append(
        "industry_authority_image",
        authorityImage,
        authorityImage.name
      );
    }

    if (typeof trafficImage === "object") {
      body.append("traffic_managment_image", trafficImage, trafficImage.name);
    }

    if (typeof constructionImage === "object") {
      body.append(
        "construction_induction_image",
        constructionImage,
        constructionImage.name
      );
    }

    if (swms !== undefined && swms !== null && swms.length > 0) {
      var i;
      for (i = 0; i < swms.length; i++) {
        body.append(`swms[${i}]`, swms[i], swms[i].name);
      }
    }

    body.append(
      "swms_ids",
      removedSwms != undefined && removedSwms !== null ? removedSwms : []
    );

    if (!selectedStaff) {
      // formObj["time_start"] = today;
      body.append("time_start", today);
      dispatch(StaffActions.createStaff(body)).then((res) => {
        if (res.action.type == "ADD_STAFF_FULFILLED") {
          if (res.value && res.value.status == true) {
            toast.success(res.value.message);
            window.location = "/Staff";
          } else if (res.value && res.value.status == false) {
            setSubmitButtonDisable(false);
            toast.error(res.value.message);
          } else {
            setSubmitButtonDisable(false);
            // toast.error(res.value.message);
          }
        }
      });
    } else {
      //formObj["id"] = selectedStaff.id;
      body.append("id", selectedStaff.id);

      dispatch(StaffActions.updateStaff(body)).then((res) => {
        if (res.action.type == "ADD_STAFF_FULFILLED") {
          if (res.value && res.value.status == true) {
            toast.success(res.value.message);
            window.sessionStorage.setItem("record-status", "saved");
            window.location = "/Staff";
          } else if (res.value && res.value.status == false) {
            setSubmitButtonDisable(false);
            toast.error(res.value.message);
          } else {
            setSubmitButtonDisable(false);
            // toast.error(res.value.message);
          }
        }
      });
    }
  };

  const [address, setAddress] = React.useState(
    selectedStaff !== undefined &&
      selectedStaff.address !== undefined &&
      selectedStaff.address !== null &&
      selectedStaff.address !== ""
      ? selectedStaff.address
      : ""
  );
  const [relationship, setRelationship] = React.useState(
    selectedStaff != undefined ? selectedStaff.relationship : "1"
  );
  const [vehicle, setVehicle] = React.useState(
    selectedStaff != undefined ? selectedStaff.vehicle : "1"
  );

  const [driverLicenseNo, setDriverLicenseNo] = React.useState(
    selectedStaff != undefined ? selectedStaff.yellow_card_license_no : ""
  );
  const [driverLicenseExpiryDate, setDriverLicenseExpiryDate] = React.useState(
    selectedStaff != undefined &&
      selectedStaff.yellow_card_expiry_date !== undefined &&
      selectedStaff.yellow_card_expiry_date !== "" &&
      selectedStaff.yellow_card_expiry_date !== null
      ? moment(selectedStaff.yellow_card_expiry_date).format("YYYY-MM-DD")
      : ""
  );
  const [driverLicenseImage, setDriverLicenseImage] = React.useState(
    selectedStaff != undefined && selectedStaff.driver_license_image != null
      ? selectedStaff.driver_license_image_path
      : "/images/default-upload-popup.png"
  );

  const [authorityNo, setAuthorityNo] = React.useState(
    selectedStaff != undefined ? selectedStaff.sky_blue_card_authority_no : ""
  );
  const [authorityExpiryDate, setAuthorityExpiryDate] = React.useState(
    selectedStaff != undefined &&
      selectedStaff.sky_blue_card_expiry_date !== undefined &&
      selectedStaff.sky_blue_card_expiry_date !== "" &&
      selectedStaff.sky_blue_card_expiry_date !== null
      ? moment(selectedStaff.sky_blue_card_expiry_date).format("YYYY-MM-DD")
      : ""
  );
  const [authorityImage, setAuthorityImage] = React.useState(
    selectedStaff != undefined && selectedStaff.industry_authority_image != null
      ? selectedStaff.industry_authority_image_path
      : "/images/default-upload-popup.png"
  );

  const [trafficCardNo, setTrafficCardNo] = React.useState(
    selectedStaff != undefined ? selectedStaff.blue_card_card_no : ""
  );
  const [trafficRtoNo, setTrafficRtoNo] = React.useState(
    selectedStaff != undefined ? selectedStaff.blue_card_rto_no : ""
  );
  const [trafficIssueDate, setTrafficIssueDate] = React.useState(
    selectedStaff != undefined &&
      selectedStaff.blue_card_issue_date !== undefined &&
      selectedStaff.blue_card_issue_date !== "" &&
      selectedStaff.blue_card_issue_date !== null
      ? moment(selectedStaff.blue_card_issue_date).format("YYYY-MM-DD")
      : ""
  );
  const [trafficExpiryDate, setTrafficExpiryDate] = React.useState(
    selectedStaff != undefined &&
      selectedStaff.blue_card_expiry_date !== undefined &&
      selectedStaff.blue_card_expiry_date !== "" &&
      selectedStaff.blue_card_expiry_date !== null
      ? moment(selectedStaff.blue_card_expiry_date).format("YYYY-MM-DD")
      : ""
  );
  const [trafficImage, setTrafficImage] = React.useState(
    selectedStaff != undefined && selectedStaff.traffic_managment_image != null
      ? selectedStaff.traffic_managment_image_path
      : "/images/default-upload-popup.png"
  );

  const [constructionCardNo, setConstructionCardNo] = React.useState(
    selectedStaff != undefined ? selectedStaff.red_card_card_no : ""
  );
  const [constructionRtoNo, setConstructionRtoNo] = React.useState(
    selectedStaff != undefined ? selectedStaff.red_card_rto_no : ""
  );
  const [constructionIssueDate, setConstructionIssueDate] = React.useState(
    selectedStaff != undefined &&
      selectedStaff.red_card_issue_date !== undefined &&
      selectedStaff.red_card_issue_date !== "" &&
      selectedStaff.red_card_issue_date !== null
      ? moment(selectedStaff.red_card_issue_date).format("YYYY-MM-DD")
      : ""
  );
  const [constructionImage, setConstructionImage] = React.useState(
    selectedStaff != undefined &&
      selectedStaff.construction_induction_image != null
      ? selectedStaff.construction_induction_image_path
      : "/images/default-upload-popup.png"
  );

  const [tfns, setTfns] = React.useState([]);
  const [uploadedTfns, setUploadedTfns] = useState(
    selectedStaff !== undefined && selectedStaff.tfns ? selectedStaff.tfns : []
  );
  const [removedTfns, setRemovedTfns] = useState([]);
  const [removedTfnsIds, setRemovedTfnsIds] = useState([]);
  const [tfnsViewUpdate, setTfnsViewUpdate] = useState(false);

  const [swms, setSwms] = React.useState([]);
  const [uploadedSwms, setUploadedSwms] = useState(
    selectedStaff !== undefined && selectedStaff.swms ? selectedStaff.swms : []
  );
  const [removedSwms, setRemovedSwms] = useState([]);
  const [removedSwmsIds, setRemovedSwmsIds] = useState([]);
  const [swmsViewUpdate, setSwmsViewUpdate] = useState(false);

  const [selectedDriverLicense, setSelectedDriverLicense] = React.useState(
    selectedStaff !== undefined &&
      driverLicenseNo !== undefined &&
      driverLicenseNo !== null &&
      driverLicenseExpiryDate !== undefined &&
      driverLicenseExpiryDate !== null
      ? true
      : false
  );

  const [selectedAuthority, setSelectedAuthority] = React.useState(
    selectedStaff !== undefined &&
      authorityNo !== undefined &&
      authorityNo !== null &&
      authorityExpiryDate !== undefined &&
      authorityExpiryDate !== null
      ? true
      : false
  );

  const [selectedTraffic, setSelectedTraffic] = React.useState(
    selectedStaff !== undefined &&
      trafficCardNo !== undefined &&
      trafficCardNo !== null &&
      trafficRtoNo !== undefined &&
      trafficRtoNo !== null &&
      trafficIssueDate !== undefined &&
      trafficIssueDate !== null &&
      trafficExpiryDate !== undefined &&
      trafficExpiryDate !== null
      ? true
      : false
  );

  const [selectedConstruction, setSelectedConstruction] = React.useState(
    selectedStaff !== undefined &&
      constructionRtoNo !== undefined &&
      constructionRtoNo !== null &&
      constructionCardNo !== undefined &&
      constructionCardNo !== null &&
      constructionIssueDate !== undefined &&
      constructionIssueDate !== null
      ? true
      : false
  );

  const handleSelect = async (value) => {
    setAddress(value);
  };

  const searchOptions = {
    componentRestrictions: { country: "au" },
  };

  const removeTfns = (id) => {
    if (id !== -1) {
      tfns.splice(id, 1);
      setTfnsViewUpdate(true);
    }
  };

  const removeTfnss = (index, tfnsId) => {
    if (index !== -1) {
      uploadedTfns.splice(index, 1);
      setUploadedTfns(uploadedTfns);
      setTfnsViewUpdate(true);
      removedTfnsIds.push(tfnsId);
      setRemovedTfns(removedTfnsIds);
    }
  };

  function deleteCurrentStaff(jobId) {
    dispatch(StaffActions.deleteStaff(jobId)).then((res) => {
      // if (res.action.type == "DELETE_JOB_FULFILLED") {
      if (res.value && res.value.status == true) {
        toast.success(res.value.message);
        window.location = "/staff";
      } else if (res.value && res.value.status == false) {
        toast.error(res.value.message);
      } else {
        toast.error("Something went wrong");
      }
      // }
    });
  }

  const handleDeleteCurrentStaff = (jobId) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to delete this Staff?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCurrentStaff(jobId),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const removeSwm = (id) => {
    if (id !== -1) {
      swms.splice(id, 1);
      setSwmsViewUpdate(true);
    }
  };

  const removeSwms = (index, swmsId) => {
    if (index !== -1) {
      uploadedSwms.splice(index, 1);
      setUploadedSwms(uploadedSwms);
      setSwmsViewUpdate(true);
      removedSwmsIds.push(swmsId);
      setRemovedSwms(removedSwmsIds);
    }
  };

  useEffect(() => {
    if (!selectedStaff && currentLocation !== "/Staff/new") {
      window.location.href = "/Staff";
    }

    if (tfnsViewUpdate == true) {
      setTfnsViewUpdate(false);
    }

    if (swmsViewUpdate == true) {
      setSwmsViewUpdate(false);
    }
  }, [dispatch, tfnsViewUpdate, swmsViewUpdate]);

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header />
          <div className={`page-main-title`}>
            <SectionDivider
              title={`${selectedStaff ? "Edit Staff" : "Add New Staff"}`}
            />
          </div>
          <DriverLicenseModal
            opened={openDriverLicenseModal}
            setOpened={setOpenDriverLicenseModal}
            driverLicenseNo={driverLicenseNo}
            setDriverLicenseNo={setDriverLicenseNo}
            driverLicenseExpiryDate={driverLicenseExpiryDate}
            setDriverLicenseExpiryDate={setDriverLicenseExpiryDate}
            driverLicenseImage={driverLicenseImage}
            setDriverLicenseImage={setDriverLicenseImage}
            selectedDriverLicense={selectedDriverLicense}
            setSelectedDriverLicense={setSelectedDriverLicense}
          />
          <IndustryAuthorityModel
            opened={openIndustryAuthorityModel}
            setOpened={setOpenIndustryAuthorityModel}
            authorityNo={authorityNo}
            setAuthorityNo={setAuthorityNo}
            authorityExpiryDate={authorityExpiryDate}
            setAuthorityExpiryDate={setAuthorityExpiryDate}
            authorityImage={authorityImage}
            setAuthorityImage={setAuthorityImage}
            selectedAuthority={selectedAuthority}
            setSelectedAuthority={setSelectedAuthority}
          />
          <TrafficManagementModel
            opened={openTrafficManagementModel}
            setOpened={setOpenTrafficManagementModel}
            trafficCardNo={trafficCardNo}
            setTrafficCardNo={setTrafficCardNo}
            trafficRtoNo={trafficRtoNo}
            setTrafficRtoNo={setTrafficRtoNo}
            trafficIssueDate={trafficIssueDate}
            setTrafficIssueDate={setTrafficIssueDate}
            trafficExpiryDate={trafficExpiryDate}
            setTrafficExpiryDate={setTrafficExpiryDate}
            trafficImage={trafficImage}
            setTrafficImage={setTrafficImage}
            selectedTraffic={selectedTraffic}
            setSelectedTraffic={setSelectedTraffic}
          />
          <ConstructionInductionModel
            opened={openConstructionInductionModel}
            setOpened={setOpenConstructionInductionModel}
            constructionCardNo={constructionCardNo}
            setConstructionCardNo={setConstructionCardNo}
            constructionRtoNo={constructionRtoNo}
            setConstructionRtoNo={setConstructionRtoNo}
            constructionIssueDate={constructionIssueDate}
            setConstructionIssueDate={setConstructionIssueDate}
            constructionImage={constructionImage}
            setConstructionImage={setConstructionImage}
            selectedConstruction={selectedConstruction}
            setSelectedConstruction={setSelectedConstruction}
          />
          <div
            className={`grids-outer-common-css-full`}
            style={{ padding: " 8px 0" }}
          >
            <Form onSubmit={(formObj) => handleStaffProcess(formObj)}>
              {({ handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  id="staff-form"
                  encType="multipart/form-data"
                >
                  <Grid container spacing={4}>
                    {renderTF(
                      "name",
                      "Staff Name",
                      "Enter Staff Name",
                      false,
                      selectedStaff && selectedStaff.name,
                      "required"
                    )}

                    {renderTF(
                      "email",
                      "Email",
                      "Enter Email",
                      false,
                      selectedStaff && selectedStaff.email,
                      "required"
                    )}

                    <Grid item lg={4} md={6} xs={12}>
                      <div style={{ position: "relative" }}>
                        <div className="add-staff-signature-wrapper">
                          <div className="add-staff-registered-name">
                            Registered Signature
                          </div>
                          {/* <div className="add-staff-staff-name">{selectedStaff && selectedStaff.name}</div> */}
                          <div className="add-staff-signature-inner-wrapper">
                            <div className="add-staff-signature-img-wrapper">
                              <img
                                className="add-staff-signature-img"
                                src={`${
                                  selectedStaff &&
                                  selectedStaff.signature_path !== undefined
                                    ? selectedStaff.signature_path
                                    : ""
                                }`}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <div className="add-new-job-field-title">
                        <p>Address</p>
                      </div>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                        searchOptions={searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className={"custom-address-dropdown"}>
                            <input
                              id="address"
                              className="add-new-job-input-tf address"
                              {...getInputProps({ placeholder: "Address" })}
                            />
                            <div className="address-section">
                              {loading ? (
                                <div className="address-suggestion">
                                  ...loading
                                </div>
                              ) : (
                                ""
                              )}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#84d1f1"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    className="address-suggestion"
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                            <div
                              className="error-message text-danger"
                              id="errorDiv-address"
                            ></div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Grid>
                    {renderTFM(
                      "mobile",
                      "Mobile",
                      "Enter Mobile Number",
                      false,
                      selectedStaff && selectedStaff.mobile,
                      "required"
                    )}
                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={12}
                      className="form-blank-div"
                    />
                    {renderTF("start_date", "Start Date", "", true, todayAU)}
                    {renderCHArray(
                      "vehicle",
                      "Assigned GDTC UTE",
                      "",
                      false,
                      vehicle,
                      setVehicle
                    )}
                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={12}
                      className="form-blank-div"
                    />

                    <Grid item lg={4} md={12} xs={12}>
                      <p className="text-left">{`Upload TCA, Driver License, White/Blue Card and
                    other qualificatons`}</p>
                      <br />
                      <div className="qualificatons-upload-block">
                        {selectedDriverLicense == true ? (
                          <div className="qualificaton-uploaded-col">
                            <p>DRIVERS LICENSE</p>
                            <a
                              href="#"
                              onClick={() => setOpenDriverLicenseModal(true)}
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <rect
                                  width="24"
                                  height="24"
                                  rx="12"
                                  fill="#e8e8e8"
                                />
                                <path
                                  d="M.33,11.966h0a.332.332,0,0,1-.234-.1.328.328,0,0,1-.079-.336l1.377-4.14,0-.007A.113.113,0,0,1,1.4,7.372l0-.01c0-.007,0-.008.006-.01l.008-.014.008-.013,0-.006A.166.166,0,0,1,1.447,7.3l.01-.013.006-.006.013-.013,6.2-6.2L8.265.482a1.649,1.649,0,0,1,2.328,0l.893.889a1.651,1.651,0,0,1,0,2.328L4.7,10.487a.029.029,0,0,1-.01.01l0,0-.006.006-.014.011-.016.012-.017.01-.017.01-.006,0a.14.14,0,0,1-.03.013s0,0-.007,0L.436,11.949A.381.381,0,0,1,.33,11.966ZM1.852,8.113l-1,3,3.007-1Zm6.06-6.347h0L2.174,7.5,4.467,9.8,10.2,4.058,7.912,1.766ZM9.43.657a.983.983,0,0,0-.7.289L8.38,1.3l2.292,2.292.349-.353a1,1,0,0,0,0-1.4l-.9-.9A.983.983,0,0,0,9.43.657Z"
                                  transform="translate(6 6)"
                                  fill="#4c4c4c"
                                />
                              </svg>
                            </a>
                          </div>
                        ) : (
                          <div className="qualificaton-upload-col">
                            <p>DRIVERS LICENSE</p>
                            <a
                              href="#"
                              onClick={() => setOpenDriverLicenseModal(true)}
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,1,1,20.485,20.485,11.922,11.922,0,0,1,12,24ZM7,11a1,1,0,1,0,0,2h4v4a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2H13V7a1,1,0,0,0-2,0v4Z"
                                  fill="#a3c960"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        {selectedAuthority == true ? (
                          <div className="qualificaton-uploaded-col">
                            <p>INDUSTRY AUTHORITY</p>
                            <a
                              href="#"
                              onClick={() =>
                                setOpenIndustryAuthorityModel(true)
                              }
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <rect
                                  width="24"
                                  height="24"
                                  rx="12"
                                  fill="#e8e8e8"
                                />
                                <path
                                  d="M.33,11.966h0a.332.332,0,0,1-.234-.1.328.328,0,0,1-.079-.336l1.377-4.14,0-.007A.113.113,0,0,1,1.4,7.372l0-.01c0-.007,0-.008.006-.01l.008-.014.008-.013,0-.006A.166.166,0,0,1,1.447,7.3l.01-.013.006-.006.013-.013,6.2-6.2L8.265.482a1.649,1.649,0,0,1,2.328,0l.893.889a1.651,1.651,0,0,1,0,2.328L4.7,10.487a.029.029,0,0,1-.01.01l0,0-.006.006-.014.011-.016.012-.017.01-.017.01-.006,0a.14.14,0,0,1-.03.013s0,0-.007,0L.436,11.949A.381.381,0,0,1,.33,11.966ZM1.852,8.113l-1,3,3.007-1Zm6.06-6.347h0L2.174,7.5,4.467,9.8,10.2,4.058,7.912,1.766ZM9.43.657a.983.983,0,0,0-.7.289L8.38,1.3l2.292,2.292.349-.353a1,1,0,0,0,0-1.4l-.9-.9A.983.983,0,0,0,9.43.657Z"
                                  transform="translate(6 6)"
                                  fill="#4c4c4c"
                                />
                              </svg>
                            </a>
                          </div>
                        ) : (
                          <div className="qualificaton-upload-col">
                            <p>INDUSTRY AUTHORITY</p>
                            <a
                              href="#"
                              onClick={() =>
                                setOpenIndustryAuthorityModel(true)
                              }
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,1,1,20.485,20.485,11.922,11.922,0,0,1,12,24ZM7,11a1,1,0,1,0,0,2h4v4a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2H13V7a1,1,0,0,0-2,0v4Z"
                                  fill="#a3c960"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        {selectedTraffic == true ? (
                          <div className="qualificaton-uploaded-col">
                            <p>TRAFFIC MANAGEMENT</p>
                            <a
                              href="#"
                              onClick={() =>
                                setOpenTrafficManagementModel(true)
                              }
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <rect
                                  width="24"
                                  height="24"
                                  rx="12"
                                  fill="#e8e8e8"
                                />
                                <path
                                  d="M.33,11.966h0a.332.332,0,0,1-.234-.1.328.328,0,0,1-.079-.336l1.377-4.14,0-.007A.113.113,0,0,1,1.4,7.372l0-.01c0-.007,0-.008.006-.01l.008-.014.008-.013,0-.006A.166.166,0,0,1,1.447,7.3l.01-.013.006-.006.013-.013,6.2-6.2L8.265.482a1.649,1.649,0,0,1,2.328,0l.893.889a1.651,1.651,0,0,1,0,2.328L4.7,10.487a.029.029,0,0,1-.01.01l0,0-.006.006-.014.011-.016.012-.017.01-.017.01-.006,0a.14.14,0,0,1-.03.013s0,0-.007,0L.436,11.949A.381.381,0,0,1,.33,11.966ZM1.852,8.113l-1,3,3.007-1Zm6.06-6.347h0L2.174,7.5,4.467,9.8,10.2,4.058,7.912,1.766ZM9.43.657a.983.983,0,0,0-.7.289L8.38,1.3l2.292,2.292.349-.353a1,1,0,0,0,0-1.4l-.9-.9A.983.983,0,0,0,9.43.657Z"
                                  transform="translate(6 6)"
                                  fill="#4c4c4c"
                                />
                              </svg>
                            </a>
                          </div>
                        ) : (
                          <div className="qualificaton-upload-col">
                            <p>TRAFFIC MANAGEMENT</p>
                            <a
                              href="#"
                              onClick={() =>
                                setOpenTrafficManagementModel(true)
                              }
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,1,1,20.485,20.485,11.922,11.922,0,0,1,12,24ZM7,11a1,1,0,1,0,0,2h4v4a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2H13V7a1,1,0,0,0-2,0v4Z"
                                  fill="#a3c960"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        {selectedConstruction == true ? (
                          <div className="qualificaton-uploaded-col">
                            <p>CONSTRUCTION INDUCTION</p>
                            <a
                              href="#"
                              onClick={() =>
                                setOpenConstructionInductionModel(true)
                              }
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <rect
                                  width="24"
                                  height="24"
                                  rx="12"
                                  fill="#e8e8e8"
                                />
                                <path
                                  d="M.33,11.966h0a.332.332,0,0,1-.234-.1.328.328,0,0,1-.079-.336l1.377-4.14,0-.007A.113.113,0,0,1,1.4,7.372l0-.01c0-.007,0-.008.006-.01l.008-.014.008-.013,0-.006A.166.166,0,0,1,1.447,7.3l.01-.013.006-.006.013-.013,6.2-6.2L8.265.482a1.649,1.649,0,0,1,2.328,0l.893.889a1.651,1.651,0,0,1,0,2.328L4.7,10.487a.029.029,0,0,1-.01.01l0,0-.006.006-.014.011-.016.012-.017.01-.017.01-.006,0a.14.14,0,0,1-.03.013s0,0-.007,0L.436,11.949A.381.381,0,0,1,.33,11.966ZM1.852,8.113l-1,3,3.007-1Zm6.06-6.347h0L2.174,7.5,4.467,9.8,10.2,4.058,7.912,1.766ZM9.43.657a.983.983,0,0,0-.7.289L8.38,1.3l2.292,2.292.349-.353a1,1,0,0,0,0-1.4l-.9-.9A.983.983,0,0,0,9.43.657Z"
                                  transform="translate(6 6)"
                                  fill="#4c4c4c"
                                />
                              </svg>
                            </a>
                          </div>
                        ) : (
                          <div className="qualificaton-upload-col">
                            <p>CONSTRUCTION INDUCTION</p>
                            <a
                              href="#"
                              onClick={() =>
                                setOpenConstructionInductionModel(true)
                              }
                              className="upload-right-link"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,1,1,20.485,20.485,11.922,11.922,0,0,1,12,24ZM7,11a1,1,0,1,0,0,2h4v4a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2H13V7a1,1,0,0,0-2,0v4Z"
                                  fill="#a3c960"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                      </div>
                    </Grid>

                    {renderTF(
                      "vehicle_registration",
                      "Vehicle Registration",
                      "Enter Vehicle Registration Number",
                      false,
                      selectedStaff && selectedStaff.vehicle_registration
                    )}
                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={12}
                      className="form-blank-div"
                    />
                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={12}
                      className="form-blank-div"
                    />
                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={12}
                      className={"drag-drop-outer add-staff-page-drag"}
                    >
                      <div className="drag-drop-title-wrapper add-new-job-field-title">
                        <p>TFN, Superannuation</p>
                      </div>

                      {uploadedTfns.map((tfn, index) => {
                        var tfnFileName = tfn.path.split("/tfns/");
                        return (
                          <div
                            className="uploaded-block"
                            style={{ marginTop: "10px" }}
                          >
                            <a
                              href={tfn.full_path}
                              target="_blank"
                              style={{ color: "#535353" }}
                            >
                              {tfn.original_file_name != null ? (
                                <p>{tfn.original_file_name}</p>
                              ) : (
                                <p>{tfnFileName}</p>
                              )}
                            </a>
                            <a href="javascript:void(0)">
                              <img
                                src="/images/gray-close-icon.png"
                                alt=""
                                onClick={() => removeTfnss(index, tfn.id)}
                              />
                            </a>
                          </div>
                        );
                      })}

                      {tfns.map((tfn, index) => {
                        return (
                          <div
                            className="uploaded-block"
                            style={{ marginTop: "10px" }}
                          >
                            <a
                              href="#"
                              target="_blank"
                              style={{ color: "#535353" }}
                            >
                              <p>
                                {/* {tfn.name.slice(
                                  tfn.name.length > 25
                                    ? tfn.name.length - 15
                                    : tfn.name.length - tfn.name.length
                                )} */}
                                {tfn.name}
                              </p>
                            </a>
                            <a href="javascript:void(0)">
                              <img
                                src="/images/gray-close-icon.png"
                                alt=""
                                onClick={() => removeTfns(index)}
                              />
                            </a>
                          </div>
                        );
                      })}

                      <DragAndDropTfns
                        setTfns={setTfns}
                        tfns={tfns}
                        setTfnsViewUpdate={setTfnsViewUpdate}
                        uploadedTfns={uploadedTfns}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={12}
                      className={"drag-drop-outer add-staff-page-drag"}
                    >
                      <div className="drag-drop-title-wrapper add-new-job-field-title">
                        <p>SWMS and Documentation</p>
                      </div>

                      {uploadedSwms.map((swms, index) => {
                        var swmsFileName = swms.path.split("/swms/");
                        return (
                          <div
                            className="uploaded-block"
                            style={{ marginTop: "10px" }}
                          >
                            <a
                              href={swms.full_path}
                              target="_blank"
                              style={{ color: "#535353" }}
                            >
                              {swms.original_file_name != null ? (
                                <p>{swms.original_file_name}</p>
                              ) : (
                                <p>{swmsFileName}</p>
                              )}
                            </a>
                            <a href="javascript:void(0)">
                              <img
                                src="/images/gray-close-icon.png"
                                alt=""
                                onClick={() => removeSwms(index, swms.id)}
                              />
                            </a>
                          </div>
                        );
                      })}

                      {swms.map((swms, index) => {
                        return (
                          <div
                            className="uploaded-block"
                            style={{ marginTop: "10px" }}
                          >
                            <a
                              href="#"
                              target="_blank"
                              style={{ color: "#535353" }}
                            >
                              <p>
                                {/* {swms.name.slice(
                                  swms.name.length > 25
                                    ? swms.name.length - 15
                                    : swms.name.length - swms.name.length
                                )} */}
                                {swms.name}
                              </p>
                            </a>
                            <a href="javascript:void(0)">
                              <img
                                src="/images/gray-close-icon.png"
                                alt=""
                                onClick={() => removeSwm(index)}
                              />
                            </a>
                          </div>
                        );
                      })}

                      <DragAndDropSwms
                        setSwms={setSwms}
                        swms={swms}
                        setSwmsViewUpdate={setSwmsViewUpdate}
                        uploadedSwms={uploadedSwms}
                        setUploadedSwms={setUploadedSwms}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SectionDivider title="Emergency Contact" />
                    </Grid>

                    {renderTF(
                      "contact_name",
                      "Emergency Contact",
                      "Enter Emergency Contact",
                      false,
                      selectedStaff && selectedStaff.contact_name,
                      "required"
                    )}

                    {renderSelect(
                      "relationship",
                      "Relationship",
                      "",
                      relationshipsOption,
                      false,
                      relationship,
                      setRelationship
                    )}
                    <Grid
                      item
                      lg={4}
                      md={6}
                      xs={12}
                      className={"add-staff-blank"}
                    />
                    {renderTF(
                      "contact_phone",
                      "Phone Number",
                      "Enter Phone Number",
                      false,
                      selectedStaff && selectedStaff.contact_phone,
                      "required"
                    )}

                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      className={"staff-form-btn"}
                    />
                    <div className="anj-create-job-wrapper staff-action-button ">
                      <div className="anj-create-job-inner-wrapper">
                        {selectedStaff && (
                          <div style={{ padding: "12px 0" }}>
                            <Button
                              className={`light-gray-btn ${classes.deleteJob}`}
                              onClick={() =>
                                handleDeleteCurrentStaff(selectedStaff.id)
                              }
                            >
                              Delete Staff
                            </Button>
                          </div>
                        )}

                        {/* <Grid item lg={4} md={6} xs={12} className={'staff-form-btn'}>*/}

                        <Button
                          style={{
                            color: "white",
                            fontFamily: "futura-pt-demi",
                            fontSize: 18,
                            backgroundColor: "#A3C960",
                            borderRadius: 22,
                          }}
                          disabled={submitButtonDisable}
                          onClick={() => handleSubmitButton()}
                        >
                          {`${selectedStaff ? "Save" : "Register"} Staff`}
                        </Button>
                      </div>
                    </div>
                    {/*</Grid>*/}
                  </Grid>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>

      <ToastContainer />
    </Fragment>
  );
};

export default AddNewStaff;
