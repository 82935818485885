export const FETCH_STAFF_LIST = "FETCH_STAFF_LIST_CLIENTS";
export const FETCH_STAFF_LIST_PENDING = "FETCH_STAFF_LIST_CLIENTS_PENDING";
export const FETCH_STAFF_LIST_FULFILLED = "FETCH_STAFF_LIST_CLIENTS_FULFILLED";
export const FETCH_STAFF_LIST_REJECTED = "FETCH_STAFF_LIST_CLIENTS_REJECTED";

export const FETCH_STAFF_SEARCH_LIST = "FETCH_STAFF_SEARCH_LIST";
export const FETCH_STAFF_SEARCH_LIST_PENDING = "FETCH_STAFF_SEARCH_LIST_PENDING";
export const FETCH_STAFF_SEARCH_LIST_FULFILLED = "FETCH_STAFF_SEARCH_LIST_FULFILLED";
export const FETCH_STAFF_SEARCH_LIST_REJECTED = "FETCH_STAFF_SEARCH_LIST_REJECTED";

export const ADD_STAFF= "ADD_STAFF";
export const ADD_STAFF_PENDING = "ADD_STAFF_PENDING";
export const ADD_STAFF_FULFILLED = "ADD_STAFF_FULFILLED";
export const ADD_STAFF_REJECTED = "ADD_STAFF_REJECTED";

export const DELETE_STAFF = "DELETE_STAFF_CLIENTS";
export const DELETE_STAFF_PENDING = "DELETE_STAFF_CLIENTS_PENDING";
export const DELETE_STAFF_FULFILLED = "DELETE_STAFF_CLIENTS_FULFILLED";
export const DELETE_STAFF_REJECTED = "DELETE_STAFF_CLIENTS_REJECTED";

export const SELECT_STAFF = "SELECT_STAFF";
