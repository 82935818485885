import React, { useRef } from "react";
import "./DragAndDrop.css";
import { Button } from "@material-ui/core";
import DragAndDropStyles from "./DragAndDropStyles";
import { useDropzone } from "react-dropzone";

function MyDropzone({ classes, acc, setFilesState, isNotDisplayUpload }) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  setFilesState && setFilesState(acceptedFiles)
  return (
    <div {...getRootProps()} style={{ outline: "none" }} className="dragOuterPopup">
      <input {...getInputProps()} />
      <div className={"drag-n-drop-box"}>
        {acceptedFiles.length > 0 ? (
          <div className={"drag-n-drop-text-wrapper uploaded-file-list"}>
            <ol>
              {acceptedFiles.map(af => {
                return (
                  <li>
                    <p>{af.name}</p>
                  </li>
                );
              })}
            </ol>
          </div>
        ) : (
            <div className={"drag-n-drop-text-wrapper"}><p>DRAG AND DROP FILE</p></div>
          )}
      </div>
      {!isNotDisplayUpload && (
        <div>
          <div className="drag-n-drop-or-wrapper">
            <p>or</p>
            <div className="drag-n-drop-or-upload-button-wrapper">
              <Button className={classes.uploadButton}>upload</Button>
            </div>
          </div>
        </div>)}
    </div>
  );
}
const DragAndDrop = ({ title, setFilesState, isNotDisplayUpload }) => {
  const classes = DragAndDropStyles();
  var acc = useRef([]);
  return (
    <div className="drag-drop-wrapper" style={{ marginTop: '15px' }}>
      <div className="drag-drop-title-wrapper add-new-job-field-title">
        <p>{title}</p>
      </div>
      {/* <div
        className={"drag-n-drop-box"}
        onDrop={event => drop(event)}
        onDragOver={event => allowDrop(event)}
      >
        <div className={"drag-n-drop-text-wrapper"}>
          <div />
          <p>DRAG AND DROP FILE</p>
        </div>
      </div> */}
      <MyDropzone classes={classes} acc={acc} setFilesState={setFilesState} isNotDisplayUpload={isNotDisplayUpload} />
    </div>
  );
};

export default DragAndDrop;
