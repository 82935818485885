import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const StaffIcon = ({ props, className }) => {
  return (
    <SvgIcon {...props} className={className}>
    <svg id="Shape_2" data-name="Shape 2" xmlns="http://www.w3.org/2000/svg" width="18.789" height="15.93" viewBox="0 0 18.789 15.93">
      <path id="Shape" d="M13.959,15.93H4.829a3.059,3.059,0,0,1-2.906-2.1l-.546-1.64a1.838,1.838,0,0,1-.135-3.517A7.96,7.96,0,0,1,6.755,1.6,1.842,1.842,0,0,1,8.578,0h1.633a1.84,1.84,0,0,1,1.823,1.6,7.96,7.96,0,0,1,5.513,7.073,1.838,1.838,0,0,1-.135,3.517l-.547,1.64A3.06,3.06,0,0,1,13.959,15.93ZM2.688,12.253l.4,1.194A1.835,1.835,0,0,0,4.829,14.7h9.13A1.837,1.837,0,0,0,15.7,13.448l.4-1.194ZM1.838,9.8a.613.613,0,0,0,0,1.226H16.951a.613.613,0,1,0,0-1.226ZM12.049,2.91V8.578h4.262A6.78,6.78,0,0,0,12.049,2.91ZM8.578,1.226a.613.613,0,0,0-.613.612v6.74h2.859V1.838a.613.613,0,0,0-.613-.612ZM6.739,2.91A6.78,6.78,0,0,0,2.479,8.578H6.739Z" transform="translate(0 0)" fill="#f2f2f2"/>
    </svg>

    </SvgIcon>
  );
};

export default StaffIcon;
