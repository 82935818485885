import React from "react";
import { Grid } from "@material-ui/core";
import NotificationDetailsList from "../NotificationDetailsList/NotificationDetailsList";

const ListView = ({ notifications }) => {
  return (
    <Grid container spacing={2} style={{ width: "calc(100% + 8px)" }}>
      {notifications &&
        notifications.map((notification, index) => {
          return (
            <Grid item xs={12} key={index} className={`${'dash-box-outer list-view day-view1'}`}>
              <NotificationDetailsList data={notification} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ListView;
