import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const LocationIcon = ({ props, className }) => {
  return (
    <SvgIcon {...props} className={className}>
      <svg id="Group_10" data-name="Group 10" xmlns="http://www.w3.org/2000/svg" width="14.563" height="18" viewBox="0 0 14.563 18">
        <path id="Shape" d="M7.282,18a.629.629,0,0,1-.48-.221l-5.287-6.21A5.724,5.724,0,0,1,.461,9.827a7.269,7.269,0,0,1,.846-6.7A7.168,7.168,0,0,1,7.282,0a7.167,7.167,0,0,1,5.974,3.129,7.274,7.274,0,0,1,.845,6.7,5.743,5.743,0,0,1-1.055,1.74L7.761,17.779A.629.629,0,0,1,7.282,18Zm0-16.741A5.921,5.921,0,0,0,2.343,3.846a6.009,6.009,0,0,0-.7,5.54,4.482,4.482,0,0,0,.832,1.364l4.81,5.65,4.807-5.647,0,0h0a4.5,4.5,0,0,0,.83-1.36,6.014,6.014,0,0,0-.7-5.544A5.925,5.925,0,0,0,7.282,1.259Z" transform="translate(0)" fill="#f2f2f2" />
        <path id="Shape-2" data-name="Shape" d="M3.524,7.049A3.524,3.524,0,1,1,7.049,3.524,3.528,3.528,0,0,1,3.524,7.049Zm0-5.79A2.266,2.266,0,1,0,5.791,3.524,2.268,2.268,0,0,0,3.524,1.259Z" transform="translate(3.757 3.734)" fill="#f2f2f2" />
      </svg>

    </SvgIcon>
  );
};

export default LocationIcon;
