import { makeStyles } from "@material-ui/core/styles";

const HeaderStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    padding: "8px 0",
    fontSize: 22,
    color: "#535353"
  },
  searchTextField: {
    backgroundColor: "white",
    borderRadius: 8
  },
  input: {
    backgroundColor: "green"
  },
  search: {
    width: 114,
    backgroundColor: "#88BB41",
    color: "white",
    fontSize: 13.5,
    fontFamily: "futura-pt-demi",
    marginLeft: "15px"
  }
}));

export default HeaderStyles;
