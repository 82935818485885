import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import JobDetailsStyles from "./JobDetailsStyles";
import { MuiThemeProvider } from "@material-ui/core";
import JobDetailsTheme from "./JobDetailsTheme";
import "./JobDetails.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import { SELECT_JOB } from "../../../../../Redux/Actions/Types/Dashboard/Jobs";
import JobsActions from "../../../../../Redux/Actions/dispatches/Jobs/Jobs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import $ from "jquery";
import { is } from "@babel/types";

const JobDetails = ({
  data,
  openStaffModal,
  setOpenStaffModal,
  deleteJob,
  setDeleteJob,
}) => {
  const classes = JobDetailsStyles();
  const dispatch = useDispatch();
  const loggedJobs = useSelector((state) => state.dashboard.loggedJobs);
  const completedJobs = useSelector((state) => state.dashboard.completedJobs);
  const today = moment().format("YYYY-MM-DD");

  const getClientName = (id) => {
    switch (id) {
      default:
        return "GET DIRECTED";
    }
  };

  const findJobDetails = (jobID, array) => {
    var job = array.filter((obj) => {
      return obj.id === jobID;
    });
    return job[0];
  };

  const handleOpenEdit = (jobID) => {
    window.sessionStorage.setItem("click-event", "edit");
    window.sessionStorage.setItem("type", "job");
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs),
    });
  };

  const handleOpenReport = (jobID) => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, completedJobs),
    });
  };

  const handleOpenAddStaff = (jobID, popupState) => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs),
    });
    setOpenStaffModal(true);
    popupState.close();
  };

  function deleteThisJob(jobID) {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs),
    });
    setDeleteJob(true);
  }

  const handleDeleteJob = (jobID, popupState) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to delete this Job?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteThisJob(jobID),
        },
        {
          label: "No",
        },
      ],
    });

    popupState.close();
  };

  function confirmJob(jobId) {
    dispatch(JobsActions.confirmJob(jobId)).then((res) => {
      if (res.action.type == "CONFIRM_JOB_FULFILLED") {
        if (res.value && res.value.status == true) {
          toast.success(res.value.message);
          setInterval(function () {
            window.location = "/";
          }, 1000);
        } else if (res.value && res.value.status == false) {
          toast.error(res.value.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  }

  const handleConfirmJob = (jobID, popupState) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to confirm this Job?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmJob(jobID),
        },
        {
          label: "No",
        },
      ],
    });
    popupState.close();
  };

  //$('.grid-view-outer-block').css("padding-bottom", $('.job-grid-btm-area').height());

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={JobDetailsTheme}>
        {data && (
          <div className="job-grid-outer">
            <div
              className={`job-grid-id ${
                0 == data.status && false == data.is_accepted
                  ? "incomplete"
                  : 0 == data.status && true === data.is_accepted
                  ? "in-progress"
                  : "completed"
              }
                  `}
            >{`Job ID: GC-${data.id}`}</div>
            {data.status != 1 ? (
              <div className="job-grid-time">
                <span>Start Time: </span>{" "}
                {`${moment(today + " " + data.time_req_site).format(
                  "hh:mm A"
                )}`}
              </div>
            ) : (
              ""
            )}
            {data.status == 1 ? (
              <div className="job-grid-time">
                <span>COMPLETED AT: </span>{" "}
                {`${moment(data.updated_at).format("hh:mm A")}`}
              </div>
            ) : (
              ""
            )}
            {data.status === 0 ? (
              <div className={`job-grid-toggle`}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton
                        aria-label="delete"
                        {...bindTrigger(popupState)}
                      >
                        <MoreHorizIcon />
                      </IconButton>

                      <Menu {...bindMenu(popupState)}>
                        {data.date >= today && (
                          <MenuItem
                            onClick={() => handleOpenEdit(data.id)}
                            className={classes.menuItem}
                          >
                            <Link to={"/Jobs/edit"} className={classes.link}>
                              <p>Edit Job</p>
                            </Link>
                          </MenuItem>
                        )}
                        {data.date >= today && (
                          <MenuItem
                            onClick={() =>
                              handleOpenAddStaff(data.id, popupState)
                            }
                            className={classes.menuItem}
                          >
                            <p>Assign staff</p>
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => handleDeleteJob(data.id, popupState)}
                          className={classes.menuItem}
                        >
                          <p>Delete </p>
                        </MenuItem>
                        {data.date >= today &&
                          (data.tbc &&
                          data.tbc == 1 &&
                          data.is_accepted == false ? (
                            <MenuItem
                              onClick={() =>
                                handleConfirmJob(data.id, popupState)
                              }
                              className={classes.menuItem}
                            >
                              <p>Confirm Job</p>
                            </MenuItem>
                          ) : (
                            ""
                          ))}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </div>
            ) : (
              ""
            )}

            <div className="job-grid-content">
              <div className="job-grid-title">
                {data.address ? `${data.address}` : ""}
              </div>

              <div className="job-grid-btm">
                <ul className="grid-btm-first">
                  <li>
                    {data.client_company_name} <span>Client</span>
                  </li>
                  <li>
                    {data.booking_name} <span>Name</span>
                  </li>
                  <li>
                    {data.number_utes ? `${data.number_utes}` : ""}{" "}
                    <span>N° UTES</span>
                  </li>
                  <li>
                    <span className="text-green">{data.accepted_count}</span>/
                    {data.number_trafic} <span>N° TCS </span>
                  </li>
                </ul>
                <ul className="grid-btm-second">
                  <li>
                    <img src="images/traffic-control-icon.svg" alt="" />{" "}
                    <span>Job Type: </span> {data.job_type_name}
                  </li>
                  <li>
                    <img src="images/team-leader-icon.svg" alt="" />{" "}
                    <span>Team Leader: </span> {data.team_leader_name}
                  </li>
                </ul>
                <div className="grid-btm-third">
                  {data.status === 0 ? (
                    <div className="grid-btm-contact-left">
                      <p>
                        <img src="images/call-circle-light-gray.svg" alt="" />{" "}
                        <a
                          title={data.contact_number}
                          href={`tel:${data.contact_number}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          Call
                        </a>
                      </p>
                      <p>
                        <img src="images/mail-circle-light-gray.svg" alt="" />{" "}
                        <a
                          title={data.client_email}
                          href={`mailto:${data.client_email}`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          Mail
                        </a>
                      </p>
                    </div>
                  ) : (
                    <div className="grid-btm-job-report">
                      <Link
                        to={`/Jobs/report/${data.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          onClick={() => handleOpenReport(data.id)}
                          style={{ color: "#A3C960", cursor: "pointer" }}
                        >
                          {"View Job Report >"}
                        </p>
                      </Link>
                    </div>
                  )}

                  <div className="grid-btm-status-right">
                    {data.tbc === 1 &&
                      data.status === 0 &&
                      data.is_accepted == false && (
                        <span className="tbc-circle">TBC</span>
                      )}
                    <span className="day-status">
                      {data.shift_type && data.shift_type === 1 ? (
                        <img src="images/sun-img.svg" alt="" />
                      ) : (
                        <img src="images/moon-circle-light-gray.svg" alt="" />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MuiThemeProvider>
      <ToastContainer />
    </Fragment>
  );
};

export default JobDetails;
