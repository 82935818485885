export const FETCH_NOTIFICATION_LIST = "FETCH_NOTIFICATION_LIST";
export const FETCH_NOTIFICATION_LIST_PENDING = "FETCH_NOTIFICATION_LIST_PENDING";
export const FETCH_NOTIFICATION_LIST_FULFILLED = "FETCH_NOTIFICATION_LIST_FULFILLED";
export const FETCH_NOTIFICATION_LIST_REJECTED = "FETCH_NOTIFICATION_LIST_REJECTED";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_PENDING = "DELETE_NOTIFICATION_PENDING";
export const DELETE_NOTIFICATION_FULFILLED = "DELETE_NOTIFICATION_FULFILLED";
export const DELETE_NOTIFICATION_REJECTED = "DELETE_NOTIFICATION_REJECTED";


