import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import JobReportStyles from "./JobReportStyles";
import "./JobReport.css";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import Header from "../../../UI/Header/HeaderLink";
import { Form, Field } from "react-final-form";
import { Grid, Button } from "@material-ui/core";
import JobActions from "../../../../Redux/Actions/dispatches/Jobs/Jobs";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

const renderTF = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={true}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTA = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {(props) => (
          <textarea
            {...props.input}
            className="add-new-job-input-textarea job-form-disabled"
            disabled={true}
          ></textarea>
        )}
      </Field>
    </Grid>
  );
};

const JobReport = () => {
  const classes = JobReportStyles();
  const jobReport = useSelector((state) => state.jobs.report);
  const currentLocation = window.location.pathname;
  const today = moment().format("YYYY-MM-DD");
  const [selectedJob, setSelectedJob] = useState();
  const [clientEmail, setClientEmail] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    var jobData = currentLocation.split("/");
    var jobId = jobData[jobData.length - 1];
    if (jobReport !== undefined && currentLocation == `/Jobs/report/${jobId}`) {
      window.location.reload();
    }

    dispatch(JobActions.jobReport(jobId)).then((res) => {
      if (res.action.type == "REPORT_JOB_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSelectedJob(res.value.data);
          setClientEmail(
            res.value.data != undefined &&
              res.value.data.client_other_email != undefined &&
              res.value.data.client_other_email != null
              ? `${res.value.data.client_email}, ${res.value.data.client_other_email}`
              : res.value.data.client_email
          );
        } else if (res.value && res.value.status == false) {
          toast.error(res.value.message);
        } else {
          //console.log('FOURTH')
        }
      }
    });
  }, [dispatch]);

  function exportJob(jobId) {
    dispatch(JobActions.exportJob(jobId)).then((res) => {
      if (res.action.type == "EXPORT_JOB_FULFILLED") {
        if (res.value && res.value.status == true) {
          toast.success(res.value.message);
          window.open(res.value.data.file_path);
        } else if (res.value && res.value.status == false) {
          toast.error(res.value.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  }

  const handleJobExport = (jobId) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to export this job data?",
      buttons: [
        {
          label: "Yes",
          onClick: () => exportJob(jobId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleClientProcess = (formObj) => {};

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header />
          <div>
            <SectionDivider
              title="Job Report"
              selectedJob={selectedJob !== undefined && selectedJob}
              displayJobId={true}
            />
          </div>
          <div
            className={`grids-outer-common-css-full`}
            style={{ padding: " 8px 0" }}
          >
            <Form onSubmit={(formObj) => handleClientProcess(formObj)}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="client-form">
                  <Grid container spacing={4}>
                    {renderTF(
                      "company_name",
                      "Booking Taken By (GTDC)",
                      "Booking Taken By (GTDC)",
                      false,
                      jobReport && jobReport.gtdc
                    )}

                    {renderTF(
                      "date",
                      "Date Booking Made",
                      "Date Booking Made",
                      false,
                      jobReport && moment(jobReport.date).format("DD-MM-YYYY")
                    )}

                    {renderTF(
                      "client_name",
                      "Client",
                      "Client",
                      false,
                      jobReport && jobReport.client_company_name
                    )}

                    {renderTF(
                      "booking_name",
                      "Booking Contact Name",
                      "Booking Contact Name",
                      false,
                      jobReport && jobReport.booking_name
                    )}

                    {renderTF(
                      "contact_number",
                      "Contact Number",
                      "Contact Number",
                      false,
                      jobReport && jobReport.contact_number
                    )}

                    {renderTF(
                      "address",
                      "Job Address",
                      "Job Address",
                      false,
                      jobReport && jobReport.address
                    )}

                    {renderTF(
                      "time_req_site",
                      "Time Req Onsite",
                      "Time Req Onsite",
                      false,
                      jobReport && jobReport.time_req_site
                    )}

                    {renderTF(
                      "date_completed",
                      "Date Completed",
                      "Date Completed",
                      false,
                      jobReport &&
                        moment(jobReport.updated_at).format("DD-MM-YYYY")
                    )}

                    {renderTF(
                      "time_completed",
                      "Time Completed",
                      "Time Completed",
                      false,
                      jobReport &&
                        moment(jobReport.updated_at).format("hh:mm A")
                    )}

                    {renderTF(
                      "number_utes",
                      "Number of UTES",
                      "Number of UTES",
                      false,
                      jobReport && jobReport.number_utes
                    )}

                    {renderTF(
                      "number_trafic",
                      "Number of Traffic Controllers",
                      "Number of Traffic Controllers",
                      false,
                      jobReport && jobReport.number_trafic
                    )}

                    {renderTF(
                      "client_email",
                      "Client Email (s)",
                      "Client Email (s)",
                      false,
                      clientEmail
                    )}

                    {renderTA(
                      "setup_required",
                      " Setup Required",
                      "",
                      false,
                      jobReport && jobReport.setup_required
                    )}

                    {renderTA(
                      "notes",
                      "Notes",
                      "",
                      false,
                      jobReport && jobReport.notes
                    )}

                    {jobReport !== undefined &&
                      (jobReport.tgs.length > 0 ||
                        jobReport.permits.length > 0) && (
                        <Grid item lg={12} md={12} xs={12} className="padd-b0">
                          <SectionDivider title="Job Permits" />
                        </Grid>
                      )}
                    {jobReport !== undefined &&
                      (jobReport.tgs.length > 0 ||
                        jobReport.permits.length > 0) && (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                          className="job-permit-tgs-col"
                        >
                          {jobReport !== undefined && jobReport.tgs.length > 0 && (
                            <Grid
                              item
                              lg={5}
                              md={5}
                              xs={12}
                              className="job-permit-tgs-inner"
                            >
                              {jobReport !== undefined &&
                                jobReport.tgs.length > 0 && (
                                  <Grid item lg={12} md={12} xs={12}></Grid>
                                )}
                              <div className="add-new-job-field-title">
                                <p> TGS</p>
                              </div>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  display:
                                    jobReport !== undefined &&
                                    jobReport.tgs.length > 0
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <div className="job-permit-col">
                                  {jobReport !== undefined &&
                                    jobReport.tgs &&
                                    jobReport.tgs.map((tgsData, index) => {
                                      var tgsFileName =
                                        tgsData.path.split("/tgs/");
                                      return (
                                        <div className="permit-slide-block">
                                          <p>
                                            <a
                                              target="_blank"
                                              style={{
                                                color: "#535353",
                                                textDecoration: "none",
                                              }}
                                              href={tgsData.full_path}
                                            >
                                              {tgsData.original_file_name !=
                                              null ? (
                                                <span>
                                                  {tgsData.original_file_name}
                                                </span>
                                              ) : (
                                                <span>
                                                  {tgsData.path.slice(20)}
                                                </span>
                                              )}
                                            </a>
                                          </p>
                                          <span>TGS</span>
                                        </div>
                                      );
                                    })}
                                </div>
                              </Grid>
                            </Grid>
                          )}
                          {jobReport !== undefined &&
                            jobReport.permits.length > 0 && (
                              <Grid
                                item
                                lg={5}
                                md={5}
                                xs={12}
                                className="job-permit-tgs-inner"
                              >
                                <div className="add-new-job-field-title">
                                  <p> PERMITS</p>
                                </div>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  xs={12}
                                  style={{
                                    display:
                                      jobReport !== undefined &&
                                      jobReport.permits.length > 0
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <div className="job-permit-col">
                                    {jobReport !== undefined &&
                                      jobReport.permits &&
                                      jobReport.permits.map((permit, index) => {
                                        let permitFileName =
                                          permit.path.split("/permits/");
                                        var permitFileName1 = permitFileName;
                                        return (
                                          <div className="permit-slide-block">
                                            <p>
                                              <a
                                                target="_blank"
                                                style={{
                                                  color: "#535353",
                                                  textDecoration: "none",
                                                }}
                                                href={permit.full_path}
                                              >
                                                {permit.original_file_name !=
                                                null ? (
                                                  <span>
                                                    {permit.original_file_name}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    {permit.path.slice(20)}
                                                  </span>
                                                )}
                                              </a>
                                            </p>
                                            <span>PERMIT</span>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                        </Grid>
                      )}
                    {jobReport !== undefined && jobReport.staffs.length > 0 && (
                      <Grid item lg={12} md={12} xs={12}>
                        <SectionDivider title="Assigned Staff" />
                      </Grid>
                    )}

                    {jobReport !== undefined &&
                      jobReport.staffs &&
                      jobReport.staffs.map((staff, index) => {
                        return (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            className="dash-box-outer list-view"
                          >
                            <div
                              className={`${
                                classes.container
                              } ${"list-view-outer-block"}`}
                            >
                              <div style={{ display: "flex" }}>
                                <div
                                  className="list-title-area"
                                  style={{ display: "flex" }}
                                >
                                  <div className={`job-details-id bg-gray`}>
                                    <p>{`STAFF ID GTDTC${staff.id}`}</p>
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "left",
                                      padding: "10px 8px 8px",
                                      fontSize: 16,
                                      lineHeight: "26px",
                                      color: "#535353",
                                    }}
                                  >
                                    <p> {staff.name}</p>
                                  </div>
                                </div>
                                <div
                                  className="list-table-area"
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    padding: "6px 8px",
                                    flex: 1,
                                  }}
                                >
                                  <div className="job-details-info-list-wrapper">
                                    <div className="job-details-info-list-title">
                                      <p>TC Status</p>
                                    </div>
                                    <div className="job-details-info-list-data">
                                      <p>
                                        {staff.pivot.type === "D"
                                          ? "Driver"
                                          : "Straight to Site"}
                                        {staff.pivot.is_team_lead ? "/TL" : ""}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="job-details-info-list-divider" />
                                  <div className="job-details-info-list-wrapper">
                                    <div className="job-details-info-list-title">
                                      <p>Vehicle Checklist</p>
                                    </div>
                                    <div className="job-details-info-list-data">
                                      <p>
                                        {staff.vehicle_checklist !==
                                          undefined &&
                                        staff.vehicle_checklist !== null &&
                                        staff.vehicle_checklist !== "" ? (
                                          <a
                                            target="_blank"
                                            href={staff.vehicle_checklist}
                                          >{`VPC_GC-${jobReport.id}`}</a>
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="job-details-info-list-divider" />
                                  <div className="job-details-info-list-wrapper">
                                    <div className="job-details-info-list-title">
                                      <p>Risk Assessment</p>
                                    </div>
                                    <div className="job-details-info-list-data">
                                      <p>
                                        {staff.risk_assessment !== undefined &&
                                        staff.risk_assessment !== null &&
                                        staff.risk_assessment !== "" ? (
                                          <a
                                            target="_blank"
                                            href={staff.risk_assessment}
                                          >{`RAF_VPC_GC-${jobReport.id}`}</a>
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="job-details-info-list-divider" />
                                  <div className="job-details-info-list-wrapper">
                                    <div className="job-details-info-list-title">
                                      <p>Site Record</p>
                                    </div>
                                    <div className="job-details-info-list-data">
                                      <p>
                                        {staff.site_record !== undefined &&
                                        staff.site_record !== null &&
                                        staff.site_record !== "" ? (
                                          <a
                                            target="_blank"
                                            href={staff.site_record}
                                          >{`SRF_VPC_GC-${jobReport.id}`}</a>
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="job-details-info-list-divider" />
                                  <div className="job-details-info-list-wrapper">
                                    <div className="job-details-info-list-title">
                                      <p>Signage</p>
                                    </div>
                                    <div className="job-details-info-list-data">
                                      <p>
                                        {(staff.signage !== undefined) &
                                          (staff.signage !== null) &&
                                        staff.signage !== "" ? (
                                          <a
                                            target="_blank"
                                            href={staff.signage}
                                          >{`SF_VPC_GC-${jobReport.id}`}</a>
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="job-details-info-list-wrapper">
                                    <p className="job-details-info-list-button">
                                      {/* {staff.timesheet !== undefined & staff.timesheet !== null && staff.timesheet !== '' && ( */}
                                      {(staff.timesheet !== undefined) &
                                        (staff.timesheet !== null) &&
                                        staff.timesheet !== "" && (
                                          <a
                                            target="_blank"
                                            href={staff.site_record}
                                          >
                                            <Button
                                              style={{
                                                color: "#4C4C4C",
                                                fontFamily: "futura-pt-demi",
                                                fontSize: 14,
                                                paddingLeft: 14,
                                                paddingRight: 14,
                                                backgroundColor: "#A3C960",
                                                borderRadius: 22,
                                              }}
                                            >
                                              {`TIMESHEET`}
                                            </Button>
                                          </a>
                                        )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {staff.stocks !== undefined &&
                                staff.stocks.length > 0 && (
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className={`job-report-stock-left-div`}
                                  >
                                    <SectionDivider title="STOCK LEFT ON SITE" />
                                  </Grid>
                                )}
                              {staff.stocks !== undefined &&
                                staff.stocks.length > 0 && (
                                  <Grid item lg={12} md={12} xs={12}>
                                    <div className="left-stock-list job-report-stock-left-div">
                                      {staff.stocks !== undefined &&
                                        staff.stocks &&
                                        staff.stocks.map((stock, index) => {
                                          return (
                                            <div className="left-stock-col">
                                              <span>
                                                {stock.remaining_quantity}
                                              </span>{" "}
                                              {stock.name}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </Grid>
                                )}
                            </div>
                          </Grid>
                        );
                      })}

                    {/* {jobReport !== undefined && jobReport.stocks.length > 0 && (
                      <Grid item lg={12} md={12} xs={12}>
                        <SectionDivider title="STOCK LEFT ON SITE" />
                      </Grid>
                    )}

                    {jobReport !== undefined && jobReport.stocks.length > 0 && (
                      <Grid item lg={12} md={12} xs={12}>
                        <div className="left-stock-list">
                          {jobReport !== undefined && jobReport.stocks &&
                            jobReport.stocks.map((stock, index) => {
                              return (
                                <div className="left-stock-col"><span>{stock.quantity}</span> {stock.name}</div>
                              );
                            })}
                        </div>
                      </Grid>
                    )} */}

                    {/* <Grid item lg={12} md={12} xs={12} className="job-report-btn-outer">
                      <div className="anj-create-job-wrapper  job-report-btn">
                        <div className="anj-create-job-inner-wrapper">
                          <Button style={{
                            color: "white",
                            fontFamily: "futura-pt-demi",
                            fontSize: 18,
                            backgroundColor: "#A3C960",
                            borderRadius: 22
                          }} onClick={() => handleJobExport(jobReport.id)}
                          >
                            {`Export Report`}
                          </Button>
                        </div>
                      </div>
                    </Grid> */}
                  </Grid>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default JobReport;
