import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import NotificationStyles from "./NotificationStyles";
import "./Notification.css";
import Header from "../../UI/Header/Header";
import Sidebar from "../../UI/SideBar/Sidebar";
import SearchBarNotification from "../../UI/SearchBar/SearchBarNotification";
import GridView from "./UI/GridView/GridView";
import ListView from "./UI/ListView/ListView";
import { useDispatch } from "react-redux";
import NotificationActions from "../../../Redux/Actions/dispatches/Notification";

import moment from "moment";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "react-js-pagination";
require("bootstrap");
require("bootstrap/less/bootstrap.less");

const Notifications = () => {
  const classes = NotificationStyles();
  const notifications = useSelector((state) => state.notification.notification);
  const dispatch = useDispatch();

  const today = moment().format("YYYY-MM-DD");
  const [dateSearch, setDateSearch] = useState(today);

  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState();
  const [itemsCountPerPage, setItemsCountPerPage] = useState();
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3);
  const [pageNo, setPageNo] = useState("1");

  const [searchNotification, setSearchNotification] = useState([]);

  // useEffect(() => {
  //   const formObj = {};
  //   formObj['page'] = pageNo;
  //   formObj["date"] = dateSearch;
  //   //dispatch(NotificationActions.fetchNotification(formObj));
  // }, [dispatch]);

  useEffect(() => {
    var formData = {};
    formData["page"] = pageNo;
    formData["date"] = dateSearch;
    dispatch(NotificationActions.fetchNotification(formData)).then((res) => {
      if (res.action.type == "FETCH_NOTIFICATION_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSearchNotification(res.value.data.data);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }, [dispatch]);

  // const handleDate = (e) => {
  //   const formObj = {};
  //   formObj['page'] = pageNo;
  //   formObj["date"] = moment(e).format("YYYY-MM-DD");
  //   dispatch(NotificationActions.fetchNotification(formObj));
  // }

  const handleDate = (e) => {
    setDateSearch(moment(e).format("YYYY-MM-DD"));
    const formObj = {};
    formObj["date"] = moment(e).format("YYYY-MM-DD");
    formObj["page"] = pageNo;
    dispatch(NotificationActions.fetchNotification(formObj)).then((res) => {
      if (res.action.type == "FETCH_NOTIFICATION_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSearchNotification(res.value.data.data);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  };

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    const formObj = {};
    formObj["page"] = pageNumber;
    formObj["date"] = dateSearch;
    dispatch(NotificationActions.fetchNotification(formObj)).then((res) => {
      if (res.action.type == "FETCH_NOTIFICATION_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSearchNotification(res.value.data.data);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header handleDate={handleDate} />
          <SearchBarNotification />
          {/* <div className="grids-outer">
            <ListView notifications={notifications} />
          </div> */}
          <div className="grid-colums-outer">
            {searchNotification != undefined && searchNotification != "" ? (
              <div>
                <ListView notifications={searchNotification} />
                <Pagination
                  prevPageText="Prev"
                  nextPageText="Next"
                  firstPageText="First"
                  lastPageText="Last"
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={pageRangeDisplayed}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            ) : (
              <Grid item xs={12} className={`${"no-record-found"}`}>
                <p>No record found</p>
              </Grid>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Notifications;
