import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const JobsIcon = ({ props, className }) => {
  return (
    <SvgIcon {...props} className={className}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="sign-out-alt"
        className="svg-inline--fa fa-sign-out-alt fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <path
          d="M10.57 11.9L6.72 1.54C6.72 1.47 6.58 1.4 6.44 1.4L5.46 1.4C5.32 1.4 5.18 1.47 5.18 1.54L1.33 11.9L0 11.41L3.85 1.05C4.06 0.42 4.76 0 5.46 0L6.44 0C7.14 0 7.84 0.42 8.05 1.05L11.9 11.41L10.57 11.9Z"
          transform="translate(1.049805 0)"
          id="Path"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 0L14 0L14 1.39999L0 1.39999L0 0Z"
          transform="translate(0 11.19995)"
          id="Rectangle"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 0L5.6 0L5.6 1.39999L0 1.39999L0 0Z"
          transform="translate(4.200195 4.199951)"
          id="Rectangle"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
        <path
          d="M0 0L8.4 0L8.4 1.39999L0 1.39999L0 0Z"
          transform="translate(2.799805 7.699951)"
          id="Rectangle"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
      </svg>
    </SvgIcon>
  );
};

export default JobsIcon;
