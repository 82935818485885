import { createMuiTheme } from "@material-ui/core/styles";

const LoginTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0
      }
    }
  }
});

export default LoginTheme;
