let DashboardFetches = {
  fetchClients(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/client/list`, {
      method: "GET",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        //  "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  }
};

export default DashboardFetches;
