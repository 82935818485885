import { makeStyles } from "@material-ui/core/styles";

const AppStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',
    },
}));


export default AppStyles;