let ClientFetches = {
  fetchClient(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/client/list`, {
      method: "GET",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  fetchSearchClient(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/client/listweb?page=${data['page']}`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  createClient(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/client/create`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //"X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  updateClient(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/client/edit`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //"X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  deleteClient(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/client/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  fetchHistory(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/job/history/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  }
};

export default ClientFetches;
