import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddNewStaffStyles from "../../AddNewStaff/AddNewStaffStyles";
import StaffMember from "../StaffMember/StaffMember";

const GridView = ({ staffs }) => {
  const classes = AddNewStaffStyles();
  return (
    <Grid container spacing={4} className="grid-colums-outer">
      {staffs &&
        staffs.map((staff, index) => {
          return (
            <Grid item lg={4} md={6} xs={12} key={index} className={`${'dash-box-outer day-view1'}`}>
              <StaffMember data={staff} />
            </Grid>
          );
        })}

      <Grid item xs={12} className="staff-member-btn">
        <Link to={`/Staff/new`} style={{ textDecoration: 'none' }}>
          <div className="anj-staff-new-staff-wrapper assign-staff-btn-outer ">
            <Button className={`assign-staff-btn ${classes.link}`}>
              <div className="anj-add-circle-wrapper">
                <AddCircleIcon />
              </div>
              {"Add a new staff"}
            </Button>
          </div>
        </Link>
      </Grid>

    </Grid>
  );
};

export default GridView;
