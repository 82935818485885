import {
  FETCH_EMAIL_TEMPLATE_LIST_FULFILLED,
  SELECT_EMAIL_TEMPLATE
} from "../Actions/Types/EmailTemplate/EmailTemplate";

const initialState = {
  emailtemplate: undefined,
  selectedEmailtemplate: undefined,
  emailtemplates: undefined
};

const emailtemplate = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_TEMPLATE_LIST_FULFILLED: {
      return {
        ...state,
        emailtemplate: action.payload && action.payload.data
      };
    }
    case SELECT_EMAIL_TEMPLATE: {
      return {
        ...state,
        selectedEmailtemplate: action.payload
      };
    }
    default:
      return state;
  }
};

export default emailtemplate;
