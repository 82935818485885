import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import "./JobClientEmailModal.css";
import JobClientEmailModalTheme from "./JobClientEmailModalTheme";
import { MuiThemeProvider } from "@material-ui/core";
import JobsAction from "../../../../Redux/Actions/dispatches/Jobs/Jobs";
import $ from "jquery";

const JobClientEmailModal = ({ opened, setOpened, clientOtherEmail, setClientOtherEmail, clientEmail }) => {

  const selectedJob = useSelector(state => state.jobs.detailedJob);
  const [clientOtherEmail1, setClientOtherEmail1] = React.useState(selectedJob != undefined ? selectedJob.client_other_email : clientOtherEmail);
  const dispatch = useDispatch();

  useEffect(() => {
  }, [dispatch, selectedJob]);

  const style = theme => ({
    subTitle: {
      top: theme.spacing(-5),
      alignContent: "space-between",
      color: theme.palette.background.paper
    },
    closeButton: {
      marginLeft: "auto",
      backgroundColor: "#A3C960",
      padding: "4px",
      color: theme.palette.background.paper
    }
  });

  const addJobClientEmail = () => {
    var jobClientOtherEmail = document.getElementById("job_client_other_email").value;

    //regular expression for email validation
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


    if (jobClientOtherEmail == "") {
      var formIsValid = false;
      document.getElementById('errorDiv-job_client_other_email').innerHTML = "Please enter client additional email";
    }

    else if (jobClientOtherEmail != "" && !pattern.test(jobClientOtherEmail)) {
      var formIsValid = false;
      document.getElementById('errorDiv-job_client_other_email').innerHTML = "Please enter valid client additional email";
    }
    else if (jobClientOtherEmail != "" && jobClientOtherEmail == clientEmail) {
      var formIsValid = false;
      document.getElementById('errorDiv-job_client_other_email').innerHTML = "Client additional email should be different from default email id";
    }
    else {
      document.getElementById('errorDiv-job_client_other_email').innerHTML = "";
      var formIsValid = true;
      setClientOtherEmail(jobClientOtherEmail);
    }

    if (formIsValid == true) {
      setOpened(false);
      setClientOtherEmail(jobClientOtherEmail);
    }
  };

  return (
    <Fragment>
      <MuiThemeProvider theme={JobClientEmailModalTheme}>

        <Dialog
          fullWidth open={opened} onClose={() => setOpened(false)}
          style={{ color: "#E8E8E8" }}
          disableBackdropClick
        >
          <div className="add-staff-modal-over pop-title-custom-over">
            <p>
              {clientOtherEmail !== undefined && clientOtherEmail !== null && clientOtherEmail !== '' ? 'Edit Additional Email' : 'Add Additional Email'} </p>
            <IconButton aria-label="close" style={style(JobClientEmailModalTheme).closeButton} onClick={() => setOpened(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="popup-content-rounded">
            <DialogContent className="padd0 popupoverflowAuto">
              <div className="alternate-email-popup">
                <div className="add-new-job-field-title">
                  <p>Additional Email</p>
                </div>
                <div className="alternate-input-outer">
                  <div className="alternate-input">
                    <input required="" placeholder="Enter Additional Email" type="email" name="job_client_other_email" className="add-new-job-input-tf false" id="job_client_other_email" onChange={(id) => {
                      setClientOtherEmail1(id.target.value);
                    }}
                      value={clientOtherEmail1}
                    />
                  </div>
                  <div className="alternate-input-btn">
                    <Button onClick={addJobClientEmail} style={{
                      background: "#A3C960",
                      borderRadius: 25,
                      color: "white",
                      padding: "6px 24px",
                      margin: "24px 0",
                      fontFamily: "futura-pt-demi"
                    }}>
                      {clientOtherEmail !== undefined && clientOtherEmail !== null && clientOtherEmail !== '' ? 'EDIT EMAIL' : 'ADD EMAIL'}
                    </Button>
                  </div>
                </div>
                <div className="error-message text-danger" id="errorDiv-job_client_other_email"></div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

      </MuiThemeProvider>
    </Fragment>
  );
};
export default JobClientEmailModal;
