import React from "react";
import { Grid } from "@material-ui/core";
import NewJob from "../../../../UI/Elements/Cards/NewJob/NewJob";
import JobDetails from "../../../../UI/Elements/Cards/JobDetails/JobDetails";
import StaffMember from "../../../../UI/Elements/Cards/StaffMember/StaffMember";

const GridView = ({ staff, jobs, completed, openStaffModal, setOpenStaffModal, setDeleteJob }) => {
  return (
    <Grid container spacing={4} >
      {!completed && (
        <Grid item lg={4} md={6} xs={12}>
          <NewJob />
        </Grid>
      )}
      {jobs &&
        jobs.map((job, index) => {
          return (
            <Grid item lg={4} md={6} xs={12} key={index} className={`${'dash-box-outer day-view1'}`}>
              {staff ? (
                <StaffMember data={job} />
              ) : (
                  <JobDetails data={job} completed={completed} openStaffModal={openStaffModal} setOpenStaffModal={setOpenStaffModal} setOpenStaffModal={setOpenStaffModal}
                    setDeleteJob={setDeleteJob} />
                )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default GridView;
