import { SESSION_REQUEST_FULLFILED, SESSION_REQUEST_PENDING, SESSION_REQUEST_REJECTED, SESSION_REQUEST, LOGIN_SUCCESS } from "../Actions/Types/Session/Session";

const initialState = {
  logged: false,
  session: undefined
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return{
        ...state,
        logged: true
      }
    }
    case SESSION_REQUEST: {
      return{
        ...state,
        hello: action.payload
      }
    }
    case SESSION_REQUEST_FULLFILED: {
      return{
        ...state,
        logged: true,
        session: action.payload
      }
    }
    case SESSION_REQUEST_PENDING: {
      return{
        ...state,
        logged: true,
        session: action.payload
      }
    }
    case SESSION_REQUEST_REJECTED: {
      return{
        ...state,
        invalidTry:true
      }
    }

    default: {
      return state;
    }
  }
};

export default login;
