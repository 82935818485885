import { FETCH_STAFF_LIST, FETCH_STAFF_SEARCH_LIST, ADD_STAFF, DELETE_STAFF } from "../Types/Staff/Staff";
import StaffFetches from "../../../Requests/Staff/Staff";

let StaffActions = {
  fetchStaff() {
    return {
      type: FETCH_STAFF_LIST,
      payload: StaffFetches.fetchStaff()
    };
  },
  fetchSearchStaff(data) {
    return {
      type: FETCH_STAFF_SEARCH_LIST,
      payload: StaffFetches.fetchSearchStaff(data)
    };
  },
  createStaff(data) {
    return {
      type: ADD_STAFF,
      payload: StaffFetches.createStaff(data)
    };
  },
  updateStaff(data) {
    return {
      type: ADD_STAFF,
      payload: StaffFetches.updateStaff(data)
    };
  },
  deleteStaff(data) {
    return {
      type: DELETE_STAFF,
      payload: StaffFetches.deleteStaff(data)
    };
  }
};

export default StaffActions;
