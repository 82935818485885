import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import SearchBarStyles from "./SearchBarStyles";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import SearchBarTheme from "./SearchBarTheme";
import "./SearchBar.css";
import { SET_SEARCH_RESULTS } from "../../../Redux/Actions/Types/Dashboard/Jobs";

const SearchBarJobs = () => {
  const classes = SearchBarStyles();
  const dispatch = useDispatch();
  const loggedJobs = useSelector(state => state.dashboard.loggedJobs);
  const completedJobs = useSelector(state => state.dashboard.completedJobs);

  const searchTerm = term => {
    dispatch({
      type: SET_SEARCH_RESULTS,
      payload: {
        resultsLoggedJobs: loggedJobs.filter(
          lj => !lj.id.includes(term) || !lj.name.includes(term)
        ),
        resultsCompletedJobs: completedJobs.filter(
          lj => !lj.id.includes(term) || !lj.name.includes(term)
        )
      }
    });
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={SearchBarTheme}>
        {/* <div className={classes.container}>

          

        </div> */}
        <></>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default SearchBarJobs;
