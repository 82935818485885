import {
  CREATE_NEW_JOB,
  EDIT_JOB,
  FETCH_JOBS_LIST,
  SET_ACTIVE_JOBS_LIST,
  SET_COMPLETED_JOBS_LIST,
  DELETE_JOB,
  FETCH_JOB_DETAIL,
  UPDATE_JOB_STAFF,
  CONFIRM_JOB,
  REPORT_JOB,
  ADD_JOB,
  EXPORT_JOB
} from "../../Types/Dashboard/Jobs";
import JobsRequests from "../../../../Requests/Jobs/Jobs";

let JobsActions = {
  fetchJobs(data) {
    return {
      type: FETCH_JOBS_LIST,
      payload: JobsRequests.fetchJobs(data)
    };
  },
  setActiveJobs(activeJobs) {
    return {
      type: SET_ACTIVE_JOBS_LIST,
      payload: activeJobs
    };
  },
  setCompletedJobs(completedJobs) {
    return {
      type: SET_COMPLETED_JOBS_LIST,
      payload: completedJobs
    };
  },
  createNewJob(data) {
    return {
      type: ADD_JOB,
      payload: JobsRequests.createNewJob(data)
    };
  },
  editJob(data) {
    return {
      type: ADD_JOB,
      payload: JobsRequests.editJob(data)
    };
  },
  deleteJob(data) {
    return {
      type: DELETE_JOB,
      payload: JobsRequests.deleteJob(data)
    };
  },
  confirmJob(data) {
    return {
      type: CONFIRM_JOB,
      payload: JobsRequests.confirmJob(data)
    };
  },
  jobDetail(id) {
    return {
      type: FETCH_JOB_DETAIL,
      payload: JobsRequests.jobDetail(id)
    };
  },
  jobReport(id) {
    return {
      type: REPORT_JOB,
      payload: JobsRequests.jobReport(id)
    };
  },
  updateJobStaff(data) {
    return {
      type: UPDATE_JOB_STAFF,
      payload: JobsRequests.updateJobStaff(data)
    };
  },
  updateJobStaff1(id, staff) {
    return {
      type: UPDATE_JOB_STAFF,
      payload: JobsRequests.updateJobStaff1(id, staff)
    };
  },
  exportJob(data) {
    return {
      type: EXPORT_JOB,
      payload: JobsRequests.exportJob(data)
    };
  },
};

export default JobsActions;
