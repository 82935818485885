import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddNewClientStyles from "./AddNewClientStyles";
import "./AddNewClient.css";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import Header from "../../../UI/Header/HeaderLink";
import { Form, Field } from "react-final-form";
import { Grid, Button } from "@material-ui/core";
import ClientActions from "../../../../Redux/Actions/dispatches/Client";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { stat } from "fs";

const renderTF = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFM = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={4} md={6} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
            minLength={`10`}
            maxLength={`16`}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const handleSubmitButton = () => {
  var company_name = document.forms["client-form"]["company_name"].value;
  var name = document.forms["client-form"]["name"].value;
  var mobile = document.forms["client-form"]["mobile"].value;
  var email = document.forms["client-form"]["email"].value;

  var status = false;
  var formIsValid = true;

  if (company_name == "") {
    document.getElementById("errorDiv-company_name").innerHTML =
      "Please enter client name/company";
  } else {
    document.getElementById("errorDiv-company_name").innerHTML = "";
    var status = true;
  }

  if (name == "") {
    document.getElementById("errorDiv-name").innerHTML =
      "Please enter contact name";
  } else {
    document.getElementById("errorDiv-name").innerHTML = "";
    var status = true;
  }
  if (mobile == "") {
    document.getElementById("errorDiv-mobile").innerHTML =
      "Please enter client phone number";
  } else if (
    mobile != "" &&
    !mobile.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
  ) {
    var formIsValid = false;
    document.getElementById("errorDiv-mobile").innerHTML =
      "Please enter valid client phone number";
  } else {
    document.getElementById("errorDiv-mobile").innerHTML = "";
    var status = true;
  }
  //regular expression for email validation
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (email == "") {
    document.getElementById("errorDiv-email").innerHTML =
      "Please enter client email";
  } else if (email != "" && !pattern.test(email)) {
    var formIsValid = false;
    document.getElementById("errorDiv-email").innerHTML =
      "Please enter valid client email";
  } else {
    document.getElementById("errorDiv-email").innerHTML = "";
    var status = true;
  }

  if (status == true && formIsValid == true) {
    document
      .getElementById("client-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  }
};

const AddNewClient = () => {
  const classes = AddNewClientStyles();
  const currentLocation = window.location.pathname;
  const today = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const selectedClient = useSelector((state) => state.client.selectedClient);

  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);

  if (selectedClient != undefined && currentLocation == "/Clients/new") {
    window.location.reload();
  }

  useEffect(() => {
    if (!selectedClient && currentLocation !== "/Clients/new") {
      window.location.href = "/Clients";
    }
  }, [dispatch]);
  const handleClientProcess = (formObj) => {
    setSubmitButtonDisable(true);
    if (!selectedClient) {
      dispatch(ClientActions.createClient(formObj)).then((res) => {
        if (res.action.type == "ADD_CLIENT_FULFILLED") {
          if (res.value && res.value.status == true) {
            toast.success(res.value.message);
            window.location = "/Clients";
          } else if (res.value && res.value.status == false) {
            setSubmitButtonDisable(false);
            toast.error(res.value.message);
          } else {
            setSubmitButtonDisable(false);
            // toast.error(res.value.message);
          }
        }
      });
    } else {
      formObj["id"] = selectedClient.id;

      dispatch(ClientActions.updateClient(formObj)).then((res) => {
        if (res.action.type == "ADD_CLIENT_FULFILLED") {
          if (res.value && res.value.status == true) {
            toast.success(res.value.message);
            window.sessionStorage.setItem("record-status", "saved");
            window.location = "/Clients";
          } else if (res.value && res.value.status == false) {
            setSubmitButtonDisable(false);
            toast.error(res.value.message);
          } else {
            setSubmitButtonDisable(false);
            // toast.error(res.value.message);
          }
        }
      });
    }
  };

  const [errorStatus, setErrorStatus] = React.useState(
    selectedClient != undefined ? true : false
  );

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header />
          <div className={`page-main-title`}>
            <SectionDivider
              title={`${selectedClient ? "Edit Client" : "Add New Client"}`}
            />
          </div>
          <div
            className={`grids-outer-common-css-full`}
            style={{ padding: " 8px 0" }}
          >
            <Form onSubmit={(formObj) => handleClientProcess(formObj)}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="client-form">
                  <Grid container spacing={4}>
                    {renderTF(
                      "company_name",
                      "Client Name/Company",
                      "Client Name/Company",
                      false,
                      selectedClient && selectedClient.company_name
                    )}

                    {renderTF(
                      "name",
                      "Contact Name",
                      "Contact Name",
                      false,
                      selectedClient && selectedClient.name
                    )}
                    <Grid container spacing={4} />
                    {renderTFM(
                      "mobile",
                      "Client Phone",
                      "Client Phone",
                      false,
                      selectedClient && selectedClient.mobile
                    )}

                    {renderTF(
                      "email",
                      "Client Email",
                      "Client Email",
                      false,
                      selectedClient && selectedClient.email,
                      "email"
                    )}
                    <Grid container spacing={4} />
                    <Grid item lg={4} md={6} xs={12}>
                      <div className="anj-create-job-wrapper  client-sub-btn">
                        <div className="anj-create-job-inner-wrapper">
                          <Button
                            style={{
                              color: "white",
                              fontFamily: "futura-pt-demi",
                              fontSize: 18,
                              backgroundColor: "#A3C960",
                              borderRadius: 22,
                            }}
                            disabled={submitButtonDisable}
                            onClick={() => handleSubmitButton()}
                          >
                            {`${selectedClient ? "Edit" : "Add"} Client`}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddNewClient;
