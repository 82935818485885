import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import JobDetailsStyles from "./JobDetailsListStyles";
import { MuiThemeProvider } from "@material-ui/core";
import JobDetailsTheme from "./JobDetailsListTheme";
import "./JobDetailsList.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SELECT_JOB } from "../../../../../Redux/Actions/Types/Dashboard/Jobs";
import JobsActions from "../../../../../Redux/Actions/dispatches/Jobs/Jobs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const JobDetailsList = ({
  data,
  openStaffModal,
  setOpenStaffModal,
  deleteJob,
  setDeleteJob,
}) => {
  const classes = JobDetailsStyles();
  const getClientName = (id) => {
    switch (id) {
      default:
        return "Get Directed";
    }
  };
  const dispatch = useDispatch();
  const completedJobs = useSelector((state) => state.dashboard.completedJobs);
  const loggedJobs = useSelector((state) => state.dashboard.loggedJobs);
  const today = moment().format("YYYY-MM-DD");

  const findJobDetails = (jobID, array) => {
    var job = array.filter((obj) => {
      return obj.id === jobID;
    });
    return job[0];
  };

  const handleOpenEdit = (jobID) => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs),
    });
  };

  const handleOpenReport = (jobID) => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, completedJobs),
    });
    return;
  };

  function deleteThisJob(jobID) {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs),
    });
    setDeleteJob(true);
  }

  const handleDeleteJob = (jobID, popupState) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to delete this Job?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteThisJob(jobID),
        },
        {
          label: "No",
        },
      ],
    });

    popupState.close();
  };

  const handleOpenAddStaff = (jobID, popupState) => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs),
    });
    setOpenStaffModal(true);
    popupState.close();
  };

  function confirmJob(jobId) {
    dispatch(JobsActions.confirmJob(jobId)).then((res) => {
      if (res.action.type == "CONFIRM_JOB_FULFILLED") {
        if (res.value && res.value.status == true) {
          toast.success(res.value.message);
          setInterval(function () {
            window.location = "/";
          }, 1000);
        } else if (res.value && res.value.status == false) {
          toast.error(res.value.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  }

  const handleConfirmJob = (jobID, popupState) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to confirm this Job?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmJob(jobID),
        },
        {
          label: "No",
        },
      ],
    });
    popupState.close();
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={JobDetailsTheme}>
        <div className={`${classes.container} ${"list-view-outer-block"}`}>
          <div style={{ display: "flex" }}>
            <div className="list-title-area">
              <div
                className={`job-details-id ${
                  0 == data.status && false == data.is_accepted
                    ? "incomplete"
                    : 0 == data.status && true === data.is_accepted
                    ? "in-progress"
                    : "completed"
                }
            `}
              >
                <p>{`job ID: GC-${data.id}`}</p>
              </div>

              <div
                style={{
                  textAlign: "left",
                  padding: "10px 8px 8px",
                  fontSize: 16,
                  lineHeight: "26px",
                  color: "#535353",
                }}
              >
                <p> {data.address}</p>
              </div>
            </div>

            <div
              className="list-table-area"
              style={{
                display: "flex",
                textAlign: "left",
                padding: "6px 8px",
                flex: 3,
              }}
            >
              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Client </p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.client_company_name}</p>
                </div>
              </div>

              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Contact</p>
                </div>
                <div className="job-details-info-list-data">
                  <a
                    title={data.contact_number}
                    href={`tel:${data.contact_number}`}
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <p>{data.contact_number}</p>
                  </a>
                </div>
              </div>

              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Name</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.booking_name}</p>
                </div>
              </div>

              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>{"N° UTES "}</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.number_utes}</p>
                </div>
              </div>
              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>{"N° TCS "}</p>
                </div>

                <div className="job-details-info-list-data">
                  <div
                    style={{
                      width: "min-content",
                      display: "flex",
                      margin: "auto",
                    }}
                  >
                    <p style={{ color: "#8FBE3E" }}>{data.accepted_count}</p>/
                    <p>{data.number_trafic}</p>
                  </div>
                </div>
              </div>
              <div className="job-details-info-list-divider" />
              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Team Leader</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.team_leader_name}</p>
                </div>
              </div>

              <div className="job-details-info-list-divider" />

              {data.is_accepted == true && (
                <div className="job-details-info-list-divider" />
              )}

              {data.status != 1 ? (
                <div className="job-details-info-list-wrapper">
                  <div className="job-details-info-list-title">
                    <p>start time</p>
                  </div>
                  <div className="job-details-info-list-data">
                    <p>{`${moment(today + " " + data.time_req_site).format(
                      "hh:mm A"
                    )}`}</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {data.status == 1 ? (
                <div className="job-details-info-list-wrapper">
                  <div className="job-details-info-list-title">
                    <p>complete time</p>
                  </div>
                  <div className="job-details-info-list-data">
                    <p>{`${moment(today + " " + data.time_req_site).format(
                      "hh:mm A"
                    )}`}</p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="list-action-area"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                {data.status === 0 ? (
                  <div className={`job-list-toggle`}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <React.Fragment>
                          <IconButton
                            aria-label="delete"
                            {...bindTrigger(popupState)}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu {...bindMenu(popupState)}>
                            {data.date >= today && (
                              <MenuItem
                                onClick={() => handleOpenEdit(data.id)}
                                className={classes.menuItem}
                              >
                                <Link
                                  to={"/Jobs/edit"}
                                  className={classes.link}
                                >
                                  <p>Edit Job</p>
                                </Link>
                              </MenuItem>
                            )}
                            {data.date >= today && (
                              <MenuItem
                                onClick={() =>
                                  handleOpenAddStaff(data.id, popupState)
                                }
                                className={classes.menuItem}
                              >
                                <p>Assign staff</p>
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() =>
                                handleDeleteJob(data.id, popupState)
                              }
                              className={classes.menuItem}
                            >
                              <p>Delete </p>
                            </MenuItem>
                            {data.date >= today &&
                              (data.tbc &&
                              data.tbc == 1 &&
                              data.is_accepted == false ? (
                                <MenuItem
                                  onClick={() =>
                                    handleConfirmJob(data.id, popupState)
                                  }
                                  className={classes.menuItem}
                                >
                                  <p>Confirm Job</p>
                                </MenuItem>
                              ) : (
                                " "
                              ))}
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </div>
                ) : (
                  <div className="view-job-report-list-view">
                    <div className="job-details-view-job-report-wrapper">
                      <Link
                        to={`/Jobs/report/${data.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <p
                          onClick={() => handleOpenReport(data.id)}
                          style={{ color: "#A3C960", cursor: "pointer" }}
                        >
                          {" "}
                          {`View Job Report >`}
                        </p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              {data.status === 0 && (
                <div className={`shift-status-list-view`}>
                  {data.shift_type && data.shift_type === 1 ? (
                    <img src="images/sun-img.svg" alt="" />
                  ) : (
                    <img src="images/moon-circle-light-gray.svg" alt="" />
                  )}
                </div>
              )}
              <div
                className="jobs-details-utcs-wrapper"
                style={{ display: "block" }}
              >
                {data.tbc === 1 &&
                data.status === 0 &&
                data.is_accepted == false ? (
                  <div className="jobs-details-list-tbc">
                    {data.tbc ? "TBC" : <div />}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
      <ToastContainer />
    </Fragment>
  );
};

export default JobDetailsList;
