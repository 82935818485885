export const FETCH_CLIENT_LIST = "FETCH_CLIENT_LIST";
export const FETCH_CLIENT_LIST_PENDING = "FETCH_CLIENT_LIST_PENDING";
export const FETCH_CLIENT_LIST_FULFILLED = "FETCH_CLIENT_LIST_FULFILLED";
export const FETCH_CLIENT_LIST_REJECTED = "FETCH_CLIENT_LIST_REJECTED";

export const FETCH_CLIENT_SEARCH_LIST = "FETCH_CLIENT_SEARCH_LIST";
export const FETCH_CLIENT_SEARCH_LIST_PENDING = "FETCH_CLIENT_SEARCH_LIST_PENDING";
export const FETCH_CLIENT_SEARCH_LIST_FULFILLED = "FETCH_CLIENT_SEARCH_LIST_FULFILLED";
export const FETCH_CLIENT_SEARCH_LIST_REJECTED = "FETCH_CLIENT_SEARCH_LIST_REJECTED";

export const FETCH_CLIENT_HISTORY_LIST = "FETCH_CLIENT_HISTORY_LIST";
export const FETCH_CLIENT_HISTORY_LIST_PENDING = "FETCH_CLIENT_HISTORY_LIST_PENDING";
export const FETCH_CLIENT_HISTORY_LIST_FULFILLED = "FETCH_CLIENT_HISTORY_LIST_FULFILLED";
export const FETCH_CLIENT_HISTORY_LIST_REJECTED = "FETCH_CLIENT_HISTORY_LIST_REJECTED";


export const ADD_CLIENT = "ADD_CLIENT";
export const ADD_CLIENT_PENDING = "ADD_CLIENT_PENDING";
export const ADD_CLIENT_FULFILLED = "ADD_CLIENT_FULFILLED";
export const ADD_CLIENT_REJECTED = "ADD_CLIENT_REJECTED";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_PENDING = "DELETE_CLIENT_PENDING";
export const DELETE_CLIENT_FULFILLED = "DELETE_CLIENT_FULFILLED";
export const DELETE_CLIENT_REJECTED = "DELETE_CLIENT_REJECTED";

export const SELECT_CLIENT = "SELECT_CLIENT";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
