import { FETCH_NOTIFICATION_LIST_FULFILLED } from "../Actions/Types/Notification/Notification";

const initialState = {
  notification: undefined,
  selectedNotification: undefined,
  notifications: undefined,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_LIST_FULFILLED: {
      return {
        ...state,
        notification: action.payload && action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default notification;
