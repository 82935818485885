import { createStore, applyMiddleware } from "redux";
import rootReducer from "./Reducers";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

export default createStore(
  rootReducer,
  {},
  //applyMiddleware(promise, createLogger(), thunk)
  applyMiddleware(promise, thunk)
);
