import { FETCH_FAQ_LIST, DELETE_FAQ } from "../Types/Faq/Faq";
import FaqFetches from "../../../Requests/Faq/Faq";

let FaqActions = {
  fetchFaq() {
    return {
      type: FETCH_FAQ_LIST,
      payload: FaqFetches.fetchFaq()
    };
  },
  createFaq(data) {
    return {
      type: FETCH_FAQ_LIST,
      payload: FaqFetches.createFaq(data)
    };
  },
  updateFaq(data) {
    return {
      type: FETCH_FAQ_LIST,
      payload: FaqFetches.updateFaq(data)
    };
  },
  deleteFaq(faqId) {
    return {
      type: DELETE_FAQ,
      payload: FaqFetches.deleteFaq(faqId)
    };
  },
  fetchDetail(id) {
    return {
      type: FETCH_FAQ_LIST,
      payload: FaqFetches.fetchDetail(id)
    };
  },
};

export default FaqActions;
