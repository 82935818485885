import React from "react";
import { useSelector } from "react-redux";
import "./App.css";
import "./font.css";
import Dashboard from "./Components/Layout/Jobs/Dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "./Components/Layout/Login/Login";
import ForgotPass from "./Components/Layout/ForgotPass/ForgotPass";
import Staff from "./Components/Layout/Staff/Staff";
import Clients from "./Components/Layout/Clients/Clients";
import Faqs from "./Components/Layout/Faqs/Faqs";
import History from "./Components/Layout/Clients/History";
import Location from "./Components/Layout/Location/Location";
import Notifications from "./Components/Layout/Notifications/Notifications";
import EmailTemplates from "./Components/Layout/EmailTemplates/EmailTemplates";
import AddNewJob from "./Components/Layout/Jobs/Add/AddNewJob";
import JobReport from "./Components/Layout/Jobs/JobReport/JobReport";
import AddNewStaff from "./Components/Layout/Staff/AddNewStaff/AddNewStaff";
import AddNewClient from "./Components/Layout/Clients/AddNewClient/AddNewClient";
import AddNewFaq from "./Components/Layout/Faqs/AddNewFaq/AddNewFaq";
import AddNewEmailTemplate from "./Components/Layout/EmailTemplates/AddNewEmailTemplate/AddNewEmailTemplate";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppStyles from "./AppStyles";

function PrivateRoute({ children, logged, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        logged ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
const App = () => {
  const logged =
    useSelector(state => state.session.logged) ||
    (localStorage.getItem("supersecretkey") ||
      sessionStorage.getItem("supersecretkey"));

  const classes = AppStyles();

  const loading = useSelector(state => state.global.loading);
  const isLoading = () => {
    return loading !== 0
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute logged={logged} exact path="/">
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute logged={logged} exact path="/jobs">
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute logged={logged} exact path="/jobs/edit">
            <AddNewJob />
          </PrivateRoute>

          <PrivateRoute logged={logged} exact path="/jobs/report/:id">
            <JobReport />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/jobs/new">
            <AddNewJob />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/staff">
            <Staff />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/staff/new">
            <AddNewStaff />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/staff/edit">
            <AddNewStaff />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/clients">
            <Clients />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/clients/new">
            <AddNewClient />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/clients/edit">
            <AddNewClient />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/clients/history/:id">
            <History />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/location">
            <Location />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/notifications">
            <Notifications />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/faqs">
            <Faqs />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/faqs/new">
            <AddNewFaq />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/faqs/edit">
            <AddNewFaq />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/emailtemplates">
            <EmailTemplates />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/emailtemplates/new">
            <AddNewEmailTemplate />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/emailtemplates/edit">
            <AddNewEmailTemplate />
          </PrivateRoute>
          <Route exact path="/forgot">
            <ForgotPass />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
        </Switch>
      </Router>
      <Backdrop className={classes.backdrop} open={isLoading()} >
        <CircularProgress style={{ color: "#8FBE3E" }} />
      </Backdrop>
    </div>
  );
};

export default App;
