
/* eslint-disable no-undef */
/* global google */
import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LocationActions from "../../../Redux/Actions/dispatches/Location";
import { Link } from "react-router-dom";

import LocationStyles from "./LocationStyles";
import "./Location.css";
import Header from "../../UI/Header/HeaderLocation";
import Sidebar from "../../UI/SideBar/Sidebar";
import SearchBarLocation from "../../UI/SearchBar/SearchBarLocation";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import "../../UI/Elements/Cards/JobDetails/JobDetails.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

const Location = ({ google, job }) => {
  const classes = LocationStyles();
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setaAtiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [jobData, setJobData] = useState({});
  const [staffWorkData, setStaffWorkData] = useState({});
  const [showingInfoWindow1, setShowingInfoWindow1] = useState(false);
  const [activeMarker1, setaAtiveMarker1] = useState({});
  const [staffData, setStaffData] = useState({});
  const [showingInfoWindow2, setShowingInfoWindow2] = useState(false);
  const [activeMarker2, setaAtiveMarker2] = useState({});
  const locations = useSelector(state => state.location.location);
  const dispatch = useDispatch();
  const [searchAddress, setSearchAddress] = useState('');
  const [searchType, setSearchType] = useState('');
  const today = moment().format("YYYY-MM-DD");


  const style = {
    width: '100%',
    height: '100%'
  }

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
  }

  function onMarkerClick(props, marker, e, job) {
    setSelectedPlace(props);
    setaAtiveMarker(marker);
    setShowingInfoWindow(true);
    setJobData(job);

    setShowingInfoWindow1(false);
    setaAtiveMarker1(null);
    setShowingInfoWindow2(false);
    setaAtiveMarker2(null);
    setStaffData({});
    setStaffWorkData({});
  }

  function onMarkerClickStaff(props, marker, e, staff) {
    setSelectedPlace(props);
    setaAtiveMarker1(marker);
    setShowingInfoWindow1(true);
    setStaffData(staff);

    setShowingInfoWindow(false);
    setaAtiveMarker(null);

    setShowingInfoWindow2(false);
    setaAtiveMarker2(null);
    setJobData({});

    setStaffWorkData({});
  }

  function onMarkerClickStaffWork(props, marker, e, staff) {
    setSelectedPlace(props);
    setaAtiveMarker2(marker);
    setShowingInfoWindow2(true);
    setStaffWorkData(staff);

    setShowingInfoWindow(false);
    setaAtiveMarker(null);
    setShowingInfoWindow1(false);
    setaAtiveMarker1(null);

    setJobData({});
    setStaffData({});

  }

  function onMapClicked(props) {
    setShowingInfoWindow(false);
    setaAtiveMarker(null);
    setShowingInfoWindow1(false);
    setaAtiveMarker1(null);
    setShowingInfoWindow2(false);
    setaAtiveMarker2(null);
    setJobData({});
    setStaffData({});
    setStaffWorkData({});
  }

  function handleType(e) {
    const formData = {};
    formData['address'] = searchAddress
    formData['type'] = e;
    dispatch(LocationActions.fetchLocation(formData));
    setSearchType(e);
  }

  function handleSearch(e) {
    const formData = {};
    formData['address'] = e
    formData['type'] = searchType;
    dispatch(LocationActions.fetchLocation(formData));
    setSearchType(e);
  }

  useEffect(() => {
    const formData = {};
    formData['address'] = searchAddress;
    formData['type'] = searchType;
    dispatch(LocationActions.fetchLocation(formData)).then((res) => {
      if (res.action.type === "FETCH_LOCATION_LIST_FULFILLED") {
        if (res.action.payload && res.action.payload.status == true) {
          // setSearchEmailTemplate(res.action.payload.data);
        } else if (res.action.payload && res.action.payload.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
         // console.log('FETCH_LOCATION_LIST_FULFILLED', 'none')
        }
      }
    })
  }, [dispatch]);


  return (
    <Fragment>
      <div className={`${classes.root} ${'main-container'}`}>
        <Sidebar />
        <div className={`${classes.content} ${'main-content-area'}`}>
          <SearchBarLocation />
          <Header handleType={handleType} searchType={searchType} handleSearch={handleSearch} />
          <div className="loc-map-outer">
            <Map
              google={google}
              onClick={(props) => {
                onMapClicked(props)
              }}
              initialCenter={{
                lat: -25.172203, lng: 133.755138
              }}
              zoom={5}

            >
              {locations && locations.jobs &&
                locations.jobs.map((job, index) => {
                  return (
                    <Marker
                      key={`jobs-${index}`}
                      title={job.address ? (`${job.address}`) : ('')}
                      id={1}
                      position={{ lat: job.latitude, lng: job.longitude }}
                      icon={{
                        url: "./images/location-active-jobs-active-icon.svg",
                        anchor: new google.maps.Point(16, 16),
                        scaledSize: new google.maps.Size(32, 32)
                      }}
                      onClick={(props, marker, e) => {
                        onMarkerClick(props, marker, e, job)
                      }}
                    />
                  );
                })}

              {jobData !== undefined &&
                <InfoWindow
                  marker={activeMarker}
                  visible={showingInfoWindow}>
                  <div>
                    <div className="dash-box-outer loc-grid-job job-grid-outer">
                      <div
                        className={`job-grid-id ${
                          0 == jobData.status && false == jobData.is_accepted
                            ? "incomplete"
                            : 0 == jobData.status && true === jobData.is_accepted
                              ? "in-progress"
                              : "completed"
                          }
                  `}
                      >{`Job ID: GC-${jobData.id}`}</div>
                      {jobData.status != 1 ? (
                        <div className="job-grid-time"><span>Start Time: </span> {`${moment(today + ' ' + jobData.time_req_site).format('hh:mm A')}`}</div>) : ('')}
                      {jobData.status == 1 ? (
                        <div className="job-grid-time"><span>COMPLETED AT: </span> {`${moment(jobData.updated_at).format("hh:mm A")}`}</div>) : ('')}


                      <div className="job-grid-content">
                        <div className="job-grid-title">{jobData.address ? (`${jobData.address}`) : ('')}</div>

                        <div className="job-grid-btm">
                          <ul className="grid-btm-first">
                            <li>{jobData.client_name} <span>Client</span></li>
                            <li>{jobData.booking_name} <span>Name</span></li>
                            <li>{jobData.number_utes ? (`${jobData.number_utes}`) : ""} <span>N° UTES</span></li>
                            <li><span className="text-green">{jobData.accepted_count}</span>/{jobData.number_trafic} <span>N° TCS </span></li>
                          </ul>
                          <ul className="grid-btm-second">
                            <li><img src="images/traffic-control-icon.svg" alt="" /> <span>Job Type: </span> {jobData.job_type_name}</li>
                            <li><img src="images/team-leader-icon.svg" alt="" /> <span>Team Leader: </span> {jobData.team_leader_name}</li>
                          </ul>
                          <div className="grid-btm-third">
                            {jobData.status === 0 ? (
                              <div className="grid-btm-contact-left">

                                <p><img src="images/call-circle-light-gray.svg" alt="" /> <a title={jobData.contact_number} href={`tel:${jobData.contact_number}`} style={{ textDecoration: "none", color: "black" }}>Call</a></p>
                                <p><img src="images/mail-circle-light-gray.svg" alt="" /> <a title={jobData.client_email} href={`mailto:${jobData.client_email}`} style={{ textDecoration: "none", color: "black" }}>Mail</a></p>
                              </div>) : ('')}

                            <div className="grid-btm-status-right">
                              {jobData.tbc === 1 && jobData.status === 0 && jobData.is_accepted == false && (
                                <span className="tbc-circle">TBC</span>)}
                              <span className="day-status">
                                {jobData.shift_type && jobData.shift_type === 1 ? (<img src="images/sun-img.svg" alt="" />) : (<img src="images/moon-circle-light-gray.svg" alt="" />)}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* <div className="job-grid-btm-area">
                          <div className="loc-job-details-info-client-area">
                            <div className="job-details-info-wrapper">
                              <div className="job-details-info-title"><p>Client</p></div>
                              <div className="job-details-info-data"> <p>{jobData.client_name}</p></div>
                            </div>
                            <div className="job-details-info-wrapper">
                              <div className="job-details-info-title"><p>Contact</p></div>
                              <div className="job-details-info-data"><a title={jobData.contact_number} href={`tel:${jobData.contact_number}`} style={{ textDecoration: "none", color: "grey" }}><p>{jobData.contact_number}</p></a></div>
                            </div>
                            <div className="job-details-info-wrapper">
                              <div className="job-details-info-title"><p>Name</p></div>
                              <div className="job-details-info-data"><p>{jobData.booking_name}</p></div>
                            </div>
                          </div>
                          <div className="job-details-info-divider"></div>
                          <div className="loc-jobs-details-utcs-outer">
                            <div className="jobs-details-utcs-wrapper">
                              <div className="jobs-details-utcs-title"><p>N° UTES</p></div>
                              <div className="jobs-details-utcs-data"><p>{jobData.number_utes ? (`${jobData.number_utes}`) : ""}</p></div>
                            </div>
                            <div className="jobs-details-utcs-wrapper">
                              <div className="jobs-details-utcs-title"><p>N° TCS</p></div>
                              <div className="jobs-details-utcs-data"><p style={{ color: "#8FBE3E" }}>
                                {jobData.accepted_count}
                              </p>/
                    <p>{jobData.number_trafic}</p></div>
                            </div>
                            {jobData.status != 1 ? (
                              <Fragment>
                                <div className="jobs-details-utcs-wrapper">
                                  <div className="jobs-details-utcs-title">
                                    <p>{"START TIME"}</p>
                                  </div>
                                  <div className="jobs-details-utcs-data">
                                    <p>{`${moment(today + ' ' + jobData.time_req_site).format('hh:mm A')}`}</p>
                                  </div>
                                </div>
                              </Fragment>
                            ) : (
                                ''
                              )}
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                </InfoWindow>
              }

              {locations && locations.working_staffs &&
                locations.working_staffs.map((staff, index) => {
                  return (
                    <Marker
                      key={`working-staffs-${index}`}
                      title={staff.address ? (`${staff.address}`) : ('')}
                      id={1}
                      position={{ lat: staff.latitude, lng: staff.longitude }}
                      icon={{
                        url: "./images/location-working-active-icon.svg",
                        anchor: new google.maps.Point(16, 16),
                        scaledSize: new google.maps.Size(32, 32)
                      }}
                      onClick={(props, marker, e) => {
                        onMarkerClickStaffWork(props, marker, e, staff)
                      }}
                    />
                  );
                })}
              {staffWorkData !== undefined &&
                <InfoWindow
                  marker={activeMarker2}
                  visible={showingInfoWindow2}>
                  <div>
                    <div className="dash-box-outer loc-grid-job">
                      <div className="job-grid-top-area">
                        <div className="job-details-id bg-green"><p>STAFF ID: GDTC{staffWorkData.id}</p></div>
                        <div className="loc-page-km-col"><p></p></div>
                        <a title={staffWorkData.mobile} href={`tel:${staffWorkData.mobile}`} className="loc-wtsap-col"><img src="/images/wtsapp-icon.svg" alt="" /></a>
                        <div className="grid-title-outer loc-grid-staff-title"><div className="grid-title">{staffWorkData.name}</div><div className="job-det-info-inline"><div className="job-details-info-wrapper"><div className="job-details-info-title"><p>CURRENT JOB <span className="value-green">{staffWorkData.current_job_id}</span></p></div></div></div></div>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              }

              {locations && locations.available_staffs &&
                locations.available_staffs.map((staffData, index) => {
                  return (
                    <Marker
                      key={`available-staffs-${index}`}
                      title={staffData.address ? (`${staffData.address}`) : ('')}
                      id={2}
                      position={{ lat: staffData.latitude, lng: staffData.longitude }}
                      icon={{
                        url: "./images/location-available-active-icon.svg",
                        anchor: new google.maps.Point(16, 16),
                        scaledSize: new google.maps.Size(32, 32)
                      }}
                      onClick={(props, marker, e) => {
                        onMarkerClickStaff(props, marker, e, staffData)
                      }}
                    />
                  );
                })}

              {staffData !== undefined &&
                <InfoWindow
                  marker={activeMarker1}
                  visible={showingInfoWindow1}>
                  <div>
                    <div className="dash-box-outer loc-grid-job">
                      <div className="job-grid-top-area">
                        <div className="job-details-id bg-green"><p>STAFF ID: GDTC{staffData.id}</p></div>
                        <div className="loc-page-km-col"><p></p></div>
                        <a title={staffData.mobile} href={`tel:${staffData.mobile}`} className="loc-wtsap-col"><img src="/images/wtsapp-icon.svg" alt="" /></a>
                        <div className="grid-title-outer loc-grid-staff-title"><div className="grid-title">{staffData.name}</div><div className="job-det-info-inline"><div className="job-details-info-wrapper"><div className="job-details-info-title"><p>CURRENT JOB <span className="value-green">{staffData.current_job_id}</span></p></div></div></div></div>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              }
            </Map>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

//export default Location;
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBcqGiqodTkx93-eC25urJj06VrL9YG9Hg',
  language: 'en'
})(Location)
