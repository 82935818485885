import {
  FETCH_CLIENT_LIST_FULFILLED,
  FETCH_CLIENT_SEARCH_LIST_FULFILLED,
  FETCH_CLIENT_HISTORY_LIST_FULFILLED,
  ADD_CLIENT_FULFILLED,
  SELECT_CLIENT,
  SET_SEARCH_RESULTS
} from "../Actions/Types/Client/Client";

const initialState = {
  client: undefined,
  selectedClient: undefined,
  clients: undefined,
  clientsearch: undefined,
  activeJobs: undefined,
  completedJobs: undefined
};

const client = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_LIST_FULFILLED: {
      return {
        ...state,
        client: action.payload && action.payload.data
      };
    }
    case FETCH_CLIENT_SEARCH_LIST_FULFILLED: {
      return {
        ...state,
        clientsearch: action.payload && action.payload.data
      };
    }
    case ADD_CLIENT_FULFILLED: {
      return {
        ...state,
        clientsearch: action.payload && action.payload.data
      };
    }
    case FETCH_CLIENT_HISTORY_LIST_FULFILLED: {
      return {
        ...state,
        activeJobs: action.payload && action.payload.data.active_jobs,
        completedJobs: action.payload && action.payload.data.complete_jobs
      };
    }
    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: action.payload
      };
    }
    case SELECT_CLIENT: {
      return {
        ...state,
        selectedClient: action.payload
      };
    }
    default:
      return state;
  }
};

export default client;
