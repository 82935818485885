import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddNewEmailTemplateStyles from "./AddNewEmailTemplateStyles";
import "./AddNewEmailTemplate.css";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import Header from "../../../UI/Header/HeaderLink";
import { Form, Field } from "react-final-form";
import { Grid, Button } from "@material-ui/core";
import EmailTemplateActions from "../../../../Redux/Actions/dispatches/EmailTemplate";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CKEditor from "react-ckeditor-component";

import { stat } from "fs";
import { MDBInput } from "mdbreact";

const renderTF = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={3} md={4} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFA = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={6} md={8} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <textarea
            rows="8"
            cols="50"
            required
            disabled={disabled}
            placeholder={placeholder}
            type="textarea"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          ></textarea>
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFACK = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={6} md={8} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <textarea
            rows="8"
            cols="50"
            required
            disabled={disabled}
            placeholder={placeholder}
            type="textarea"
            {...input}
            className={`add-new-job-input-tf ckeditor ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          ></textarea>
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const handleSubmitButton = () => {
  var name = document.forms["email-template-form"]["name"].value;
  var subject = document.forms["email-template-form"]["subject"].value;
  var description = document.forms["email-template-form"]["description"].value;
  var footerText = document.forms["email-template-form"]["footer_text"].value;
  var status = false;

  if (name == "") {
    document.getElementById("errorDiv-name").innerHTML = "Please enter name";
  } else {
    document.getElementById("errorDiv-name").innerHTML = "";
    var status = true;
  }

  if (subject == "") {
    document.getElementById("errorDiv-subject").innerHTML =
      "Please enter subject";
  } else {
    document.getElementById("errorDiv-subject").innerHTML = "";
    var status = true;
  }

  if (description == "") {
    document.getElementById("errorDiv-description").innerHTML =
      "Please enter description";
  } else {
    document.getElementById("errorDiv-description").innerHTML = "";
    var status = true;
  }

  if (footerText == "") {
    document.getElementById("errorDiv-footer_text").innerHTML =
      "Please enter footer text";
  } else {
    document.getElementById("errorDiv-footer_text").innerHTML = "";
    var status = true;
  }

  if (status == true) {
    document
      .getElementById("email-template-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  }
};

const AddNewEmailTemplate = () => {
  const classes = AddNewEmailTemplateStyles();
  const currentLocation = window.location.pathname;
  const today = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const selectedEmailTemplate = useSelector(
    (state) => state.emailtemplate.selectedEmailtemplate
  );

  if (
    selectedEmailTemplate != undefined &&
    currentLocation == "/EmailTemplates/new"
  ) {
    window.location.reload();
  }

  const [description, setDescription] = React.useState(
    selectedEmailTemplate != undefined ? selectedEmailTemplate.description : ""
  );

  const handleEmailTemplateProcess = (formObj) => {
    formObj["description"] = description;

    if (!selectedEmailTemplate) {
      dispatch(EmailTemplateActions.createEmailTemplate(formObj)).then(
        (res) => {
          if (res.action.type == "FETCH_EMAIL_TEMPLATE_LIST_FULFILLED") {
            if (res.value && res.value.status == true) {
              toast.success(res.value.message);
              window.location = "/EmailTemplates";
            } else if (res.value && res.value.status == false) {
              toast.error(res.value.message);
            } else {
              // toast.error(res.value.message);
            }
          }
        }
      );
    } else {
      formObj["id"] = selectedEmailTemplate.id;

      dispatch(EmailTemplateActions.updateEmailTemplate(formObj)).then(
        (res) => {
          if (res.action.type == "FETCH_EMAIL_TEMPLATE_LIST_FULFILLED") {
            if (res.value && res.value.status == true) {
              toast.success(res.value.message);
              window.location = "/EmailTemplates";
            } else if (res.value && res.value.status == false) {
              toast.error(res.value.message);
            } else {
              // toast.error(res.value.message);
            }
          }
        }
      );
    }
  };

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header />
          <div className={`page-main-title`}>
            <SectionDivider title="Add New Email Template" />
          </div>
          <div style={{ padding: " 8px 0" }}>
            <Form onSubmit={(formObj) => handleEmailTemplateProcess(formObj)}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="email-template-form">
                  <Grid container spacing={4}>
                    {renderTF(
                      "name",
                      "Name",
                      "Enter Name",
                      false,
                      selectedEmailTemplate && selectedEmailTemplate.name
                    )}

                    {renderTF(
                      "subject",
                      "Subject",
                      "Enter Subject",
                      false,
                      selectedEmailTemplate && selectedEmailTemplate.subject
                    )}

                    {renderTFA(
                      "footer_text",
                      "Footer Text",
                      "Enter Footer Text",
                      false,
                      selectedEmailTemplate && selectedEmailTemplate.footer_text
                    )}

                    <Grid item lg={6} md={8} xs={12}>
                      <div className="add-new-job-field-title">
                        <p>{`Description`}</p>
                      </div>
                      <textarea
                        defaultValue={description && description}
                        className="add-new-job-input-tf"
                        placeholder="Description"
                        rows="8"
                        name="description"
                        cols="50"
                        onChange={(id) => {
                          setDescription(id.target.value);
                        }}
                        value={description}
                      ></textarea>
                      {/* <CKEditor
                        activeClass="p10"
                        content={description}
                        events={{
                          "blur": onBlur(),
                          "afterPaste": afterPaste(),
                          "change": onChange()
                        }}
                      /> */}
                      <div
                        className="error-message text-danger"
                        id={`errorDiv-description`}
                      ></div>
                    </Grid>

                    <Grid item lg={9} md={12} xs={12}>
                      <div className="anj-create-job-wrapper">
                        <div className="anj-create-job-inner-wrapper">
                          <Button
                            style={{
                              color: "white",
                              fontFamily: "futura-pt-demi",
                              fontSize: 18,
                              backgroundColor: "#A3C960",
                              width: 305,
                              borderRadius: 22,
                            }}
                            onClick={() => handleSubmitButton()}
                          >
                            {`${
                              selectedEmailTemplate ? "Edit" : "Add"
                            } Email Template`}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddNewEmailTemplate;
