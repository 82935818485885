import { createMuiTheme } from "@material-ui/core/styles";

const ChipButtonTheme = createMuiTheme({
  overrides: {
    MuiChip: {
      deleteIcon: {
        color: "rgba(255,255,255,0.5)"
      }
    }
  }
});

export default ChipButtonTheme;
