import { FETCH_LOCATION_LIST } from "../Types/Location/Location";
import LocationFetches from "../../../Requests/Location/Location";

let LocationActions = {
  fetchLocation(data) {
    return {
      type: FETCH_LOCATION_LIST,
      payload: LocationFetches.fetchLocation(data)
    };
  }
};

export default LocationActions;
