import { FETCH_LOCATION_LIST_FULFILLED } from "../Actions/Types/Location/Location";

const initialState = {
  location: undefined,
  locations: undefined,
};

const location = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATION_LIST_FULFILLED: {
      return {
        ...state,
        location: action.payload && action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default location;
