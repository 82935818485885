import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import SearchBarStyles from "./SearchBarStyles";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import SearchBarTheme from "./SearchBarTheme";
import "./SearchBar.css";

const SearchBarClient = ({ handleSearch,serachKeyword }) => {
  const classes = SearchBarStyles();
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={SearchBarTheme}>
      <div className={`${classes.container} ${'search-job-input-outer top-search-padding'}`}>
          <p>Client List</p>
          <div className="divider" />
          <div className="search-job-input"><input type="text" className="client-search" value={serachKeyword}  placeholder="Search for clients" onChange={handleSearch} /></div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default SearchBarClient;
