let EmailTemplateFetches = {
  fetchEmailTemplate(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/emailtemplate/list`, {
      method: "GET",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  createEmailTemplate(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/emailtemplate/create`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //"X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  updateEmailTemplate(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/emailtemplate/edit`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //"X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  deleteEmailTemplate(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/emailtemplate/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  detailEmailTemplate(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/emailtemplate/show/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  }
};

export default EmailTemplateFetches;
