import { makeStyles } from "@material-ui/core/styles";
const LoginStyles = makeStyles(theme => ({
  container: {
    background: "url(/images/login-bg.png)  no-repeat",
    backgroundSize: "cover",
    boxShadow: "inset 0px 0px 400px 510px rgba(0, 0, 0, .8)",
    height: "100vh"
  },
  root: {
    "& h2": {
      color: "#606060",
      fontFamily: `'Lato', sans-seriff`,
      fontSize: 32,
      textAlign: "center"
    }
  },
  content: {
    color: "#606060",
    fontSize: 24,
    textAlign: "center"
  },
  textfield: {
    width: "100%"
  },
  input: {
    color: "#303030",
    fontFamily: "'Lato', sans-serif",
    fontSize: 32
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(8)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "#D2D2D2"
  },
  submit: {
    width: 228.8,
    backgroundColor: "#88BB41",
    borderRadius: 22,
    color: "white",
    fontSize: 18.2,
    fontFamily: "futura-pt-demi"
  },

}));

export default LoginStyles;
