import { FETCH_CLIENT_LIST, FETCH_CLIENT_SEARCH_LIST, FETCH_CLIENT_HISTORY_LIST, DELETE_CLIENT, ADD_CLIENT } from "../Types/Client/Client";
import ClientFetches from "../../../Requests/Client/Client";

let ClientActions = {
  fetchClient() {
    return {
      type: FETCH_CLIENT_LIST,
      payload: ClientFetches.fetchClient()
    };
  },
  fetchSearchClient(data) {
    return {
      type: FETCH_CLIENT_SEARCH_LIST,
      payload: ClientFetches.fetchSearchClient(data)
    };
  },
  createClient(data) {
    return {
      type: ADD_CLIENT,
      payload: ClientFetches.createClient(data)
    };
  },
  updateClient(data) {
    return {
      type: ADD_CLIENT,
      payload: ClientFetches.updateClient(data)
    };
  },
  deleteClient() {
    return {
      type: DELETE_CLIENT,
      payload: ClientFetches.deleteClient()
    };
  },
  fetchHistory(id) {
    return {
      type: FETCH_CLIENT_HISTORY_LIST,
      payload: ClientFetches.fetchHistory(id)
    };
  },
};

export default ClientActions;
