import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import NewClientStyles from "./NewClientStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import NewClientTheme from "./NewClientTheme";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import "./NewClient.css";

const NewClient = ({ list, staff }) => {
  const classes = NewClientStyles();

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={NewClientTheme}>
        <div className={`${classes.container} add-job-new-button`}>
          <Link to={`${"/Clients/new"}`} className={classes.link}>
            <Button className={classes.button}>
              <div className={`new-job-main-wrapper ${list && "new-job-main-wrapper-list"}`}>
                <div className={`new-job-inner-wrapper ${list && "new-job-inner-wrapper-list"}`}>
                  <AddCircleIcon className={classes.addCircle} />
                </div>
                <div className={`add-new-job ${list && "add-new-job-list"}`}>
                  <h4>{`Add a new Client`}</h4>
                </div>
              </div>
            </Button>
          </Link>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default NewClient;
