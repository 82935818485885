import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import HistoryStyles from "./HistoryStyles";
import "./History.css";
import Header from "../../UI/Header/HeaderLink";
import Sidebar from "../../UI/SideBar/Sidebar";
import SearchBarHistory from "../../UI/SearchBar/SearchBarHistory";
import GridView from "./UI/GridView/GridViewHistory";
import { useDispatch } from "react-redux";
import ClientActions from "../../../Redux/Actions/dispatches/Client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const History = ({ clientName }) => {
  const classes = HistoryStyles();
  const activeJobs = useSelector(state => state.client.activeJobs);
  const completedJobs = useSelector(state => state.client.completedJobs);
  const dispatch = useDispatch();
  const currentLocation = window.location.pathname;

  useEffect(() => {
    var clientData = currentLocation.split('/');
    var clientId = clientData[clientData.length - 1];
    dispatch(ClientActions.fetchHistory(clientId));
  }, [dispatch]);

  return (
    <Fragment>
      <div className={`${classes.root} ${'main-container'}`}>
        <Sidebar />
        <div className={`${classes.content} ${'main-content-area'}`}>
          <Header />
          <SearchBarHistory />
          <div className="grid-colums-outer">
            {activeJobs != undefined && activeJobs != '' ? (
              <GridView jobs={activeJobs} />) : (
                <Grid xs={12} className={`${'no-record-found'}`}>
                  <p>No record found</p>
                </Grid>)}
          </div>
          <div className="dashboard-ljc">
            <p>Completed Jobs</p>
            <div className="divider" />
          </div>
          <div className="grid-colums-outer">
            <div>
              {completedJobs != undefined && completedJobs != '' ? (
                <GridView jobs={completedJobs} />) : (
                  <Grid xs={12} className={`${'no-record-found'}`}>
                    <p>No record found</p>
                  </Grid>)}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default History;
