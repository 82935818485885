import React, { useRef, useState, useCallback, useEffect } from "react";
import "./DragAndDrop.css";
import { Button } from "@material-ui/core";
import DragAndDropStyles from "./DragAndDropStyles";
import { useDropzone } from "react-dropzone";

var _ = require('lodash');

const DragAndDropTfns = ({ setTfns, tfns, setTfnsViewUpdate, uploadedTfns }) => {
  const classes = DragAndDropStyles();
  var acc = useRef([]);
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {

    acceptedFiles.map(item => {
      files.push(item);
    });

    setTfns(files);
    setTfnsViewUpdate(true);

  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ onDrop });
  return (
    <div className="drag-drop-wrapper" style={{ marginTop: '15px' }}>
      <div {...getRootProps()} style={{ outline: "none" }} className="dragOuterPopup">
        <input {...getInputProps()} />
        {tfns.length == 0 && uploadedTfns.length == 0 && (
          <div className={"drag-n-drop-box"}>
            {tfns.length > 0 ? (
              <div className={"drag-n-drop-text-wrapper uploaded-file-list"}>
                <ol>
                  {tfns.map(af => {
                    return (
                      <li>
                        <p>{af.name}</p>
                      </li>
                    );
                  })}
                </ol>
              </div>
            ) : (
                <div className={"drag-n-drop-text-wrapper"}><p>DRAG AND DROP FILE</p></div>
              )}
          </div>)}

        <div>
          <div className="drag-n-drop-or-wrapper">
            {tfns.length == 0 && uploadedTfns.length == 0 && (
              <p>or</p>)}
            <div className="drag-n-drop-or-upload-button-wrapper">
              <Button className={classes.uploadButton}>{tfns.length == 0 && uploadedTfns.length == 0 ? 'upload' : 'add more'}</Button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};


export default DragAndDropTfns;
