import { CREATE_NEW_JOB_FULFILLED, EDIT_JOB_FULFILLED, FETCH_JOBS_LIST_FULFILLED, FETCH_JOB_DETAIL_FULFILLED, REPORT_JOB_FULFILLED, ADD_JOB_FULFILLED, EXPORT_JOB_FULFILLED } from "../Actions/Types/Dashboard/Jobs";

const initialState = {
  jobs: undefined,
  detailedJob: undefined,
  report: undefined
};

const jobs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_LIST_FULFILLED: {
      return {
        ...state,
        jobs: action.payload && action.payload.data
      }
    }
    case ADD_JOB_FULFILLED: {
      return {
        ...state,
        jobs: action.payload && action.payload.data
      }
    }
    case FETCH_JOB_DETAIL_FULFILLED: {
      return {
        ...state,
        // detailedJob: action.payload && action.payload.job
        detailedJob: action.payload && action.payload.data
      }
    }
    case REPORT_JOB_FULFILLED: {
      return {
        ...state,
        report: action.payload && action.payload.data
      }
    }
    case CREATE_NEW_JOB_FULFILLED: {
      return {};
    }
    case EDIT_JOB_FULFILLED: {
      return {};
    }
    case EXPORT_JOB_FULFILLED: {
      return {
        ...state,
        jobs: action.payload && action.payload.data
      }
    }
    default:
      return state;
  }
};

export default jobs;
