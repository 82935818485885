import React from "react";
import { Grid } from "@material-ui/core";
import FaqDetailsList from "../FaqDetailsList/FaqDetailsList";
import NewFaq from "../NewFaq/NewFaq";

const ListView = ({ faqs, completed }) => {
  return (

    <Grid container spacing={2} style={{ width: "calc(100% + 8px)" }}>
      {!completed && (
        <Grid item xs={12}>
          <NewFaq list />
        </Grid>
      )}
      {faqs &&
        faqs.map((faq, index) => {
          return (
            <Grid item xs={12} key={index} className={`${'dash-box-outer list-view day-view1'}`}>
              <FaqDetailsList data={faq} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ListView;
