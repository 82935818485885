import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from "react-final-form";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import ChipButton from "../../../UI/Elements/Buttons/ChipButton/ChipButton";
import "./TrafficManagementModel.css";
import TrafficManagementModelTheme from "./TrafficManagementModelTheme";
import { MuiThemeProvider } from "@material-ui/core";
import StaffActions from "../../../../Redux/Actions/dispatches/Staff";
import DragAndDrop from "../../../UI/Elements/DragAndDrop/DragAndDrop";
import moment from "moment";
import $ from "jquery";

const TrafficManagementModel = ({ opened, setOpened, trafficCardNo, setTrafficCardNo, trafficRtoNo, setTrafficRtoNo, trafficIssueDate, setTrafficIssueDate, trafficExpiryDate, setTrafficExpiryDate, trafficImage, setTrafficImage, selectedTraffic, setSelectedTraffic }) => {

  const selectedStaff = useSelector(state => state.staff.selectedStaff);
  const today = moment().format("YYYY-MM-DD");
  const [imagePreview, setImagePreview] = React.useState(trafficImage != undefined && trafficImage != null ? trafficImage : "/images/default-upload-popup.png");
  const dispatch = useDispatch();

  useEffect(() => {
  }, [dispatch, selectedStaff]);

  const style = theme => ({
    subTitle: {
      top: theme.spacing(-5),
      alignContent: "space-between",
      color: theme.palette.background.paper
    },
    closeButton: {
      marginLeft: "auto",
      backgroundColor: "#A3C960",
      padding: "4px",
      color: theme.palette.background.paper
    }
  });

  const addTraffic = () => {
    var cardNo = document.getElementById("blue_card_card_no").value;
    var rtoNo = document.getElementById("blue_card_rto_no").value;
    var issueDate = document.getElementById("blue_card_issue_date").value;
    var expiryDate = document.getElementById("blue_card_expiry_date").value;

    if (cardNo == "") {
      document.getElementById('errorDiv-blue_card_card_no').innerHTML = "Please enter card number";
    } else {
      document.getElementById('errorDiv-blue_card_card_no').innerHTML = "";
      setTrafficCardNo(cardNo);
    }

    if (rtoNo == "") {
      document.getElementById('errorDiv-blue_card_rto_no').innerHTML = "Please rto number";
    } else {
      document.getElementById('errorDiv-blue_card_rto_no').innerHTML = "";
      setTrafficRtoNo(rtoNo);
    }

    if (issueDate == "") {
      document.getElementById('errorDiv-blue_card_issue_date').innerHTML = "Please issue date";
    } else {
      document.getElementById('errorDiv-blue_card_issue_date').innerHTML = "";
      setTrafficIssueDate(issueDate);
    }

    if (expiryDate == "") {
      document.getElementById('errorDiv-blue_card_expiry_date').innerHTML = "Please expiry date";
    } else {
      document.getElementById('errorDiv-blue_card_expiry_date').innerHTML = "";
      setTrafficExpiryDate(expiryDate);
    }

    if (cardNo !== "" && rtoNo !== "" && issueDate !== "" && expiryDate !== "") {
      setOpened(false);
      setSelectedTraffic(true);
    }
  };


  function onTrafficImageChange(event) {
    setTrafficImage(event.target.files[0]);
    setImagePreview(URL.createObjectURL(event.target.files[0]));
  }

  function onTrafficImageSelect(e) {
    $("#traffic_managment_image").css('opacity', '0');
    e.preventDefault();
    $("#traffic_managment_image").trigger('click');
    $(".dragged-thumb").addClass('dragged-thumb-file-preview');
  }

  return (
    <Fragment>
      <MuiThemeProvider theme={TrafficManagementModelTheme}>
        <Dialog
          fullWidth
          open={opened}
          onClose={() => setOpened(false)}
          style={{ color: "#E8E8E8" }}
          disableBackdropClick
        >
          <div className="add-staff-modal-over pop-title-custom-over" >
            <p>Traffic Management</p>
            <IconButton aria-label="close" style={style(TrafficManagementModelTheme).closeButton} onClick={() => setOpened(false)}>
              <CloseIcon />
            </IconButton>
          </div>

          <DialogContent className="padd0 popupoverflowAuto">
            <div className="add-qualifcation-pop-outer">
              {/* <div className="add-qualifcation-pop-left">
                <DragAndDrop
                  // title="Upload TCA, Driver License, White/Blue Card and other qualificatons"
                  isNotDisplayUpload={true}
                />
                <div className="dragged-icon"><img src="/images/default-upload-popup.png" alt="" /></div>
              </div> */}
              <div className="add-qualifcation-pop-left">
                {selectedStaff != undefined && selectedStaff != '' ? (
                  <div className="dragged-thumb dragged-thumb-file-preview">
                    <img src={imagePreview} alt="" />
                    <a href="#" id="select_traffic_managment_image" onClick={onTrafficImageSelect}>Click to Upload New Image</a><input type="file" style={{ opacity: '0' }} id="traffic_managment_image" name="traffic_managment_image" onChange={onTrafficImageChange} accept="image/*" />
                  </div>
                ) : (
                    <div className="dragged-thumb dragged-thumb-file-preview">
                      <img src={imagePreview} alt="" />
                      <a href="#" id="select_traffic_managment_image" onClick={onTrafficImageSelect}>Click to Upload New Image</a><input type="file" style={{ opacity: '0' }} id="traffic_managment_image" name="traffic_managment_image" onChange={onTrafficImageChange} accept="image/*" />
                    </div>)}
              </div>
              <div className="add-qualifcation-pop-right">
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title"><p>Card Number</p></div>
                  <input
                    required=""
                    placeholder="Enter Card Number"
                    type="text"
                    name="blue_card_card_no"
                    maxLength={`20`}
                    className="add-new-job-input-tf false"
                    id="blue_card_card_no"
                    onChange={(id) => {
                      setTrafficCardNo(id.target.value);
                    }}
                    value={trafficCardNo}
                  />
                  <div className="error-message text-danger" id="errorDiv-blue_card_card_no"></div>
                </div>
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title"><p>RTO Number</p></div>
                  <input
                    required=""
                    placeholder="Enter RTO Number"
                    type="text"
                    name="blue_card_rto_no"
                    maxLength={`20`}
                    className="add-new-job-input-tf false"
                    id="blue_card_rto_no"
                    onChange={(id) => {
                      setTrafficRtoNo(id.target.value);
                    }}
                    value={trafficRtoNo}
                  />
                  <div className="error-message text-danger" id="errorDiv-blue_card_rto_no"></div>
                </div>
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title"><p>Issue Date</p></div>
                  <input
                    required=""
                    placeholder="Enter Issue Date"
                    type="date"
                    max={today}
                    name="blue_card_issue_date"
                    className="add-new-job-input-tf false"
                    id="blue_card_issue_date"
                    onChange={(id) => {
                      setTrafficIssueDate(id.target.value);
                    }}
                    value={trafficIssueDate}
                  />
                  <div className="error-message text-danger" id="errorDiv-blue_card_issue_date"></div>
                </div>
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title"><p>Expiry Date</p></div>
                  <input
                    required=""
                    placeholder="Enter Expiry Date"
                    type="date"
                    min={today}
                    name="blue_card_expiry_date"
                    className="add-new-job-input-tf false"
                    id="blue_card_expiry_date"
                    onChange={(id) => {
                      setTrafficExpiryDate(id.target.value);
                    }}
                    value={trafficExpiryDate}
                  />
                  <div className="error-message text-danger" id="errorDiv-blue_card_expiry_date"></div>
                </div>
                <div className="add-quali-btn">
                  <Button
                    onClick={addTraffic}
                    style={{
                      background: "#A3C960",
                      borderRadius: 25,
                      color: "white",
                      padding: "6px 24px",
                      margin: "24px 0"
                    }}
                  >
                    {selectedTraffic == true ? 'EDIT TRAFFIC MANAGEMENT' : 'ADD TRAFFIC MANAGEMENT'}
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>

        </Dialog>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default TrafficManagementModel;
