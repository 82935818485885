import React from "react";
import { Grid } from "@material-ui/core";
import NewJob from "../../../../UI/Elements/Cards/NewJob/NewJob";
import JobDetailsList from "../../../../UI/Elements/Cards/JobDetailsList/JobDetailsList";
import StaffMemberList from "../../../../UI/Elements/Cards/StaffMemberList/StaffMemberList";

const ListView = ({ staff, jobs, completed, openStaffModal, setOpenStaffModal, setDeleteJob }) => {
  return (
    <Grid container spacing={2} style={{ width: "calc(100% + 8px)" }}>
      {!completed && (
        <Grid item xs={12}>
          <NewJob list staff={staff} />
        </Grid>
      )}
      {jobs &&
        jobs.map((job, index) => {
          return (
            <Grid item xs={12} key={index} className={`${'dash-box-outer list-view day-view1'}`}>
              {staff ? (
                <StaffMemberList data={job} />
              ) : (
                <JobDetailsList data={job} completed={completed} openStaffModal={openStaffModal} setOpenStaffModal={setOpenStaffModal}  setOpenStaffModal={setOpenStaffModal}               
                setDeleteJob={setDeleteJob} />
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ListView;
