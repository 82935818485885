import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EmailTemplateStyles from "./EmailTemplateStyles";
import "./EmailTemplate.css";
import Header from "../../UI/Header/HeaderNotLink";
import Sidebar from "../../UI/SideBar/Sidebar";
import SearchBarEmailTemplate from "../../UI/SearchBar/SearchBarEmailTemplate";
import ListView from "./UI/ListView/ListView";
import { useDispatch } from "react-redux";
import EmailTemplateActions from "../../../Redux/Actions/dispatches/EmailTemplate";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmailTemplates = () => {
  const classes = EmailTemplateStyles();
  const emailTemplates = useSelector(state => state.emailtemplate.emailtemplate);
  const dispatch = useDispatch();
  const [searchEmailTemplate, setSearchEmailTemplate] = useState(emailTemplates);

  useEffect(() => {
    dispatch(EmailTemplateActions.fetchEmailTemplate()).then((res) => {
      if (res.action.type === "FETCH_EMAIL_TEMPLATE_LIST_FULFILLED") {
        if (res.action.payload && res.action.payload.status == true) {
          setSearchEmailTemplate(res.action.payload.data);
        } else if (res.action.payload && res.action.payload.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');  
        }
      }
    })
  }, [dispatch]);

  const handleSearch = (e) => {
    var searchEmailTemplateList = emailTemplates
      .filter(
        emailTemplate => emailTemplate.name.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase().trim())
          || `${emailTemplate.subject}`.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase().trim())
      );
    setSearchEmailTemplate(searchEmailTemplateList);
  }

  return (
    <Fragment>
      <div className={`${classes.root} ${'main-container'}`}>
        <Sidebar />
        <div className={`${classes.content} ${'main-content-area'}`}>
          <Header />
          <SearchBarEmailTemplate handleSearch={handleSearch} />
          <ListView emailTemplates={searchEmailTemplate} emailTemplate />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default EmailTemplates;
