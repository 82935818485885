import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddNewJobsStyles from "./AddNewJobStyles";
import "./AddNewJob.css";
import { Grid, Button } from "@material-ui/core";
import Sidebar from "../../../UI/SideBar/Sidebar";
import JobForm from "./JobForm";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChipButton from "../../../UI/Elements/Buttons/ChipButton/ChipButtonJob";
import DragAndDropPermit from "../../../UI/Elements/DragAndDrop/DragAndDropPermit";
import DragAndDropTgs from "../../../UI/Elements/DragAndDrop/DragAndDropTgs";
import Header from "../../../UI/Header/HeaderLink";
import DashboardActions from "../../../../Redux/Actions/dispatches/Dashboard/Dashboard";
import AddUpdateStaffModal from "../../Staff/AddStaffModal/AddUpdateStaffModal";

import JobsActions from "../../../../Redux/Actions/dispatches/Jobs/Jobs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var _ = require("lodash");

const jobAddressOptions = [
  { id: 0, name: "Rockell road" },
  { id: 1, name: "Frank Avenue" },
  { id: 2, name: "Boulevard of broken dreams" },
];

const jobTypeOptions = [
  { id: 1, label: "Traffic Control" },
  { id: 2, label: "Labour Hire" },
  { id: 3, label: "Vaccuum Excavation" },
];

const AddNewJob = () => {
  const classes = AddNewJobsStyles();
  const selectedJob = useSelector((state) => state.dashboard.selectedJob);
  const clients = useSelector((state) => state.dashboard.clients);
  const currentLocation = window.location.pathname;
  const [staffs, setStaffs] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState(
    selectedJob !== undefined ? selectedJob.staffs : []
  );

  const [permits, setPermits] = useState("");
  const [permitfiles, setPermitFiles] = useState([]);
  const [uploadedPermits, setUploadedPermits] = useState(
    selectedJob !== undefined && selectedJob.permits ? selectedJob.permits : []
  );
  const [removedPermits, setRemovedPermits] = useState([]);
  const [removedPermitIds, setRemovedPermitIds] = useState([]);
  const [viewUpdate, setViewUpdate] = useState(false);

  const [tgs, setTgs] = useState("");
  const [tgsfiles, setTgsFiles] = useState([]);
  const [uploadedTgs, setUploadedTgs] = useState(
    selectedJob !== undefined && selectedJob.tgs ? selectedJob.tgs : []
  );
  const [removedTgs, setRemovedTgs] = useState([]);
  const [removedTgsIds, setRemovedTgsIds] = useState([]);
  const [tgsViewUpdate, setTgsViewUpdate] = useState(false);

  const dispatch = useDispatch();
  const [openStaffModal, setOpenStaffModal] = useState(false);

  const [maxTrafficController, setMaxTrafficController] = useState("");
  const [assignStaffCheck, setAssignStaffCheck] = useState(true);

  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);

  if (selectedJob != undefined && currentLocation == "/Jobs/new") {
    window.location.reload();
  }

  useEffect(() => {
    if (!selectedJob && currentLocation !== "/Jobs/new") {
      window.location.href = "/";
    }

    if (clients.length === 0) {
      dispatch(DashboardActions.fetchClients());
    }

    if (viewUpdate == true) {
      setViewUpdate(false);
    }

    if (tgsViewUpdate == true) {
      setTgsViewUpdate(false);
    }

    if (selectedJob !== undefined && selectedJob.staffs) {
      var staffResponseArr = [];
      selectedJob.staffs.map((staff) => {
        staffResponseArr.push(staff.pivot);
      });
      setStaffs(staffResponseArr);
    }
  }, [
    selectedJob,
    currentLocation,
    clients,
    dispatch,
    viewUpdate,
    tgsViewUpdate,
  ]);

  const handleSubmitButton = () => {
    var client = document.forms["job-form"]["client"].value;
    var gtdc = document.forms["job-form"]["gtdc"].value.trim();
    var booking_name = document.forms["job-form"]["booking_name"].value.trim();
    var contact_number =
      document.forms["job-form"]["contact_number"].value.trim();
    var address = document.forms["job-form"]["address"].value.trim();
    // var time_req_site = document.forms["job-form"]["time_req_site"].value.trim();
    //var location = document.forms["job-form"]["location"].value.trim();
    var number_utes = document.forms["job-form"]["number_utes"].value.trim();
    var number_trafic =
      document.forms["job-form"]["number_trafic"].value.trim();

    var status = false;
    var formIsValid = true;
    if (client == "") {
      document.getElementById("errorDiv-client").innerHTML =
        "Please select client";
    } else {
      document.getElementById("errorDiv-client").innerHTML = "";
      var status = true;
    }
    if (gtdc == "") {
      var formIsValid = false;
      document.getElementById("errorDiv-gtdc").innerHTML = "Please enter GDTC";
    } else {
      document.getElementById("errorDiv-gtdc").innerHTML = "";
      var status = true;
    }
    if (booking_name == "") {
      var formIsValid = false;
      document.getElementById("errorDiv-booking_name").innerHTML =
        "Please enter booking contact name";
    } else {
      document.getElementById("errorDiv-booking_name").innerHTML = "";
      var status = true;
    }
    if (contact_number == "") {
      var formIsValid = false;
      document.getElementById("errorDiv-contact_number").innerHTML =
        "Please enter contact number";
    } else if (
      contact_number != "" &&
      !contact_number.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
    ) {
      var formIsValid = false;
      var validContactNumber = false;
      document.getElementById("errorDiv-contact_number").innerHTML =
        "Please enter valid contact number";
    } else {
      document.getElementById("errorDiv-contact_number").innerHTML = "";
      var status = true;
      var validContactNumber = true;
    }

    if (address == "") {
      document.getElementById("errorDiv-address").innerHTML =
        "Please enter job address";
      var formIsValid = false;
    } else {
      document.getElementById("errorDiv-address").innerHTML = "";
      var status = true;
      var formIsValid = true;
    }

    /* if (time_req_site == "") {
      document.getElementById('errorDiv-time_req_site').innerHTML = "Please enter time required on site";
      var formIsValid = false;
    } else {
      document.getElementById('errorDiv-time_req_site').innerHTML = "";
      var status = true;
      var formIsValid = true;
    }*/

    // if (location == "") {
    //   document.getElementById('errorDiv-location').innerHTML = "Please enter location";
    //   var formIsValid = false;
    // } else {
    //   document.getElementById('errorDiv-location').innerHTML = "";
    //   var status = true;
    //   var formIsValid = true;
    // }

    if (number_utes == "") {
      document.getElementById("errorDiv-number_utes").innerHTML =
        "Please enter UTES";
      var formIsValid = false;
    } else {
      document.getElementById("errorDiv-number_utes").innerHTML = "";
      var status = true;
      var formIsValid = true;
    }

    if (number_trafic == "") {
      document.getElementById("errorDiv-number_trafic").innerHTML =
        "Please enter number of traffic controllers";
      var formIsValid = false;
    } else {
      document.getElementById("errorDiv-number_trafic").innerHTML = "";
      var status = true;
      var formIsValid = true;
    }

    //if (status == true && formIsValid == true && client !== '' && gtdc !== '' && booking_name !== '' && contact_number !== '' && validContactNumber == true && address !== '' && time_req_site !== '' && number_utes !== '' && number_trafic !== '') {
    if (
      status == true &&
      formIsValid == true &&
      client !== "" &&
      gtdc !== "" &&
      booking_name !== "" &&
      contact_number !== "" &&
      validContactNumber == true &&
      address !== "" &&
      number_utes !== "" &&
      number_trafic !== ""
    ) {
      setSubmitButtonDisable(true);
      document
        .getElementById("job-form")
        .dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };

  function deleteCurrentJob(jobId) {
    dispatch(JobsActions.deleteJob(jobId)).then((res) => {
      if (res.action.type == "DELETE_JOB_FULFILLED") {
        if (res.value && res.value.status == true) {
          toast.success(res.value.message);
          window.location = "/";
        } else if (res.value && res.value.status == false) {
          toast.error(res.value.message);
        } else {
          toast.error("Something went wrong");
        }
      }
    });
  }

  const handleDeleteCurrentJob = (jobId) => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to delete this Job?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCurrentJob(jobId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteMember = (id) => {
    // alert(id);
    // var newStaffs = selectedStaffs.filter(e => e.id !== id);
    // setSelectedStaffs(newStaffs);
    // setStaffs(newStaffs);
  };

  const removePermit = (id) => {
    if (id !== -1) {
      permitfiles.splice(id, 1);
      setViewUpdate(true);
    }
  };

  const removePermits = (index, permitId) => {
    if (index !== -1) {
      uploadedPermits.splice(index, 1);
      setUploadedPermits(uploadedPermits);
      setViewUpdate(true);
      removedPermitIds.push(permitId);
      setRemovedPermits(removedPermitIds);
    }
  };

  const removeTgs = (id) => {
    if (id !== -1) {
      tgsfiles.splice(id, 1);
      setTgsViewUpdate(true);
    }
  };

  const removeTgss = (index, tgsId) => {
    if (index !== -1) {
      uploadedTgs.splice(index, 1);
      setUploadedTgs(uploadedTgs);
      setTgsViewUpdate(true);
      removedTgsIds.push(tgsId);
      setRemovedTgs(removedTgsIds);
    }
  };

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header />
          <div className={`page-main-title`}>
            <SectionDivider
              title={`${selectedJob ? "Edit Job" : "Add New Job"}`}
              selectedJob={selectedJob}
              displayJobId={`${selectedJob != undefined ? true : false}`}
            />
          </div>

          <AddUpdateStaffModal
            opened={openStaffModal}
            setOpened={setOpenStaffModal}
            staffs={staffs}
            setStaffs={setStaffs}
            selectedJob1={selectedJob}
            selectedStaffs={selectedStaffs}
            setSelectedStaffs={setSelectedStaffs}
            maxTrafficController={maxTrafficController}
          />

          <JobForm
            jobAddressOptions={jobAddressOptions}
            jobTypeOptions={jobTypeOptions}
            selectedJob={selectedJob}
            clients={clients}
            staffs={staffs}
            permits={permits}
            setPermits={setPermits}
            tgs={tgs}
            setTgs={setTgs}
            permitfiles={permitfiles}
            tgsfiles={tgsfiles}
            removedPermits={removedPermits}
            removedTgs={removedTgs}
            setSubmitButtonDisable={setSubmitButtonDisable}
          />

          <section className={`grids-outer-common-css-full`}>
            <Grid container spacing={4} className="assign-new-staff-block">
              <Grid item lg={12} md={12} xs={12}>
                <SectionDivider title="Permits and TGS" />
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                className={"drag-drop-outer drag-drop-wrapper"}
              >
                <div className="drag-drop-title-wrapper add-new-job-field-title">
                  <p>Permits</p>
                </div>

                {uploadedPermits.map((permit, index) => {
                  var permitFileName = permit.path.split("/permits/");

                  return (
                    <div
                      className="uploaded-block"
                      style={{ marginTop: "10px" }}
                    >
                      <a
                        href={permit.full_path}
                        target="_blank"
                        style={{ color: "#535353" }}
                      >
                        {permit.original_file_name != null ? (
                          <p>{permit.original_file_name}</p>
                        ) : (
                          <p>{permitFileName}</p>
                        )}
                      </a>
                      <a href="javascript:void(0)">
                        <img
                          src="/images/gray-close-icon.png"
                          alt=""
                          onClick={() => removePermits(index, permit.id)}
                        />
                      </a>
                    </div>
                  );
                })}

                {permitfiles.map((permit, index) => {
                  return (
                    <div
                      className="uploaded-block"
                      style={{ marginTop: "10px" }}
                    >
                      <a href="#" target="_blank" style={{ color: "#535353" }}>
                        <p>
                          {/* {permit.name.slice(
                            permit.name.length > 25
                              ? permit.name.length - 15
                              : permit.name.length - permit.name.length
                          )} */}
                          {permit.name}
                        </p>
                      </a>
                      <a href="javascript:void(0)">
                        <img
                          src="/images/gray-close-icon.png"
                          alt=""
                          onClick={() => removePermit(index)}
                        />
                      </a>
                    </div>
                  );
                })}

                <DragAndDropPermit
                  setPermitFiles={setPermitFiles}
                  permitfiles={permitfiles}
                  setViewUpdate={setViewUpdate}
                  uploadedPermits={uploadedPermits}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                className={"drag-drop-outer drag-drop-wrapper"}
              >
                <div className="drag-drop-title-wrapper  add-new-job-field-title">
                  <p>TGS</p>
                </div>

                {uploadedTgs.map((tgsData, index) => {
                  var tgsFileName = tgsData.path.split("/tgs/");
                  return (
                    <div
                      className="uploaded-block"
                      style={{ marginTop: "10px" }}
                    >
                      <a
                        href={tgsData.full_path}
                        target="_blank"
                        style={{ color: "#535353" }}
                      >
                        {tgsData.original_file_name != null ? (
                          <p>{tgsData.original_file_name}</p>
                        ) : (
                          <p>{tgsFileName}</p>
                        )}
                      </a>
                      <a href="javascript:void(0)">
                        <img
                          src="/images/gray-close-icon.png"
                          alt=""
                          onClick={() => removeTgss(index, tgsData.id)}
                        />
                      </a>
                    </div>
                  );
                })}

                {tgsfiles.map((tgsData, index) => {
                  return (
                    <div
                      className="uploaded-block"
                      style={{ marginTop: "10px" }}
                    >
                      <a href="#" target="_blank" style={{ color: "#535353" }}>
                        <p>
                          {/* {tgsData.name.slice(
                            tgsData.name.length > 25
                              ? tgsData.name.length - 15
                              : tgsData.name.length - tgsData.name.length
                          )} */}{tgsData.name}
                        </p>
                      </a>
                      <a href="javascript:void(0)">
                        <img
                          src="/images/gray-close-icon.png"
                          alt=""
                          onClick={() => removeTgs(index)}
                        />
                      </a>
                    </div>
                  );
                })}

                <DragAndDropTgs
                  setTgsFiles={setTgsFiles}
                  tgsfiles={tgsfiles}
                  setTgsViewUpdate={setTgsViewUpdate}
                  uploadedTgs={uploadedTgs}
                />
              </Grid>

              <Grid item lg={12} md={12} xs={12}>
                <SectionDivider title="Assign Staff" />
              </Grid>
              <Grid item xs={12}>
                <div className="anj-staff-new-staff-wrapper">
                  <Button
                    className={classes.createJob}
                    style={{ width: 220 }}
                    onClick={() => {
                      if (
                        document.forms["job-form"][
                          "number_trafic"
                        ].value.trim() > 0
                      ) {
                        setOpenStaffModal(true);
                        setMaxTrafficController(
                          document.forms["job-form"][
                            "number_trafic"
                          ].value.trim()
                        );
                        setAssignStaffCheck(true);
                        document.getElementById(
                          "errorDiv-number_trafic"
                        ).innerHTML = "";
                      } else {
                        setAssignStaffCheck(false);
                        document.getElementById(
                          "errorDiv-number_trafic"
                        ).innerHTML =
                          "Please enter number of traffic controllers";
                      }
                    }}
                  >
                    <div className="anj-add-circle-wrapper">
                      <AddCircleIcon />
                    </div>
                    {"Assign new staff"}
                  </Button>
                </div>

                {assignStaffCheck == false ? (
                  <div
                  className="error-message text-danger"
                    id="errorDiv-number_trafic1"
                  >
                    Please define number of traffic controller
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              {selectedStaffs !== undefined && selectedStaffs.length > 0 && (
                <Grid item xs={12}>
                  <div className="anj-staff-member-wrapper">
                    {/* {selectedJob !== undefined && selectedJob.staffs.map((data, index) => { */}
                    {selectedStaffs !== undefined &&
                      selectedStaffs.map((data, index) => {
                        return (
                          //data.pivot &&
                          <ChipButton
                            title={data.name}
                            driver={
                              data.pivot != undefined
                                ? data.pivot.type === "D"
                                : data.type === "D"
                            }
                            active
                            id={data.id}
                            onDelete={deleteMember}
                            key={data.id}
                          />
                        );
                      })}
                  </div>
                </Grid>
              )}

              <Grid item lg={12} md={12} xs={12} className="job-sub-btn">
                <div className="anj-create-job-wrapper">
                  <div className="anj-create-job-inner-wrapper">
                    {selectedJob && selectedJob.status !== 2 && (
                      <div style={{ padding: "12px 0" }}>
                        <Button
                          className={`light-gray-btn ${classes.deleteJob}`}
                          onClick={() => handleDeleteCurrentJob(selectedJob.id)}
                        >
                          Delete Job
                        </Button>
                      </div>
                    )}
                    <Button
                      className={classes.createJob}
                      disabled={submitButtonDisable}
                      onClick={() => handleSubmitButton()}
                    >{`${
                      selectedJob
                        ? selectedJob.status === 2
                          ? "Export"
                          : "Save"
                        : "Create"
                    } Job`}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddNewJob;
