import React, { Fragment } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./DaysPicker.css";
import { IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TBCDatesRail from "../TBCDatesRail/TBCDatesRail";
import moment from "moment";

function Weekday({ weekday, className, localeUtils, locale }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={className} title={weekdayName}>
      <p>{weekdayName.slice(0, 1)}</p>
    </div>
  );
}

function Navbar({ onPreviousClick, onNextClick, className }) {
  return (
    <div className={className}>
      <div className="day-picker-navButton-wrapper">
        <IconButton onClick={() => onPreviousClick()}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={() => onNextClick()}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
}
const today = new Date();

export default class DaysPicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: []
    };
  }

  handleDayClick(day, { selected }) {
    var g1 = new Date(moment().format("YYYY-MM-DD"));
    var g2 = new Date(moment(day).format("YYYY-MM-DD"));
    if (g1.getTime() <= g2.getTime()) {
      const { selectedDays } = this.state;
      const selectedDaysCopy = Array.from(this.props.selectedDates);

      if (selected) {
        const selectedIndex = selectedDays.findIndex(selectedDay =>
          DateUtils.isSameDay(selectedDay, day)
        );
        const indexOfPickedDay = selectedDaysCopy.findIndex(pday => {
          return pday.date.getTime() === day.getTime();
        });
        selectedDays.splice(selectedIndex, 1);
        selectedDaysCopy.splice(indexOfPickedDay, 1);
        this.props.setSelectedDates(selectedDaysCopy);
      } else {
        selectedDays.push(day);
        selectedDaysCopy.push({ date: day, tbc: false });
        this.props.setSelectedDates(selectedDaysCopy);
      }
      this.setState({ selectedDays });
    }
  }

  render() {
    return (
      <div>
        <DayPicker
          selectedDays={this.state.selectedDays}
          onDayClick={this.handleDayClick}
          weekdayElement={<Weekday />}
          navbarElement={<Navbar />}
          disabledDays={{ before: today }}
        />
        {this.props.showTBCRail && (
          <Fragment>
            <div className="divider" />
            <TBCDatesRail
              dates={this.props.selectedDates}
              setSelectedDates={this.props.setSelectedDates}
            />
          </Fragment>
        )}
      </div>
    );
  }
}
