export const FETCH_FAQ_LIST = "FETCH_FAQ_LIST";
export const FETCH_FAQ_LIST_PENDING = "FETCH_FAQ_LIST_PENDING";
export const FETCH_FAQ_LIST_FULFILLED = "FETCH_FAQ_LIST_FULFILLED";
export const FETCH_FAQ_LIST_REJECTED = "FETCH_FAQ_LIST_REJECTED";

export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_FAQ_PENDING = "DELETE_FAQ_PENDING";
export const DELETE_FAQ_FULFILLED = "DELETE_FAQ_FULFILLED";
export const DELETE_FAQ_REJECTED = "DELETE_FAQ_REJECTED";

export const SELECT_FAQ = "SELECT_FAQ";

