import { FETCH_JOBS_LIST_FULFILLED, FETCH_JOBS_LIST_PENDING, FETCH_JOBS_LIST_REJECTED, FETCH_JOB_DETAIL_FULFILLED, FETCH_JOB_DETAIL_PENDING, FETCH_JOB_DETAIL_REJECTED, UPDATE_JOB_STAFF_FULFILLED, UPDATE_JOB_STAFF_REJECTED, UPDATE_JOB_STAFF_PENDING, REPORT_JOB_FULFILLED, REPORT_JOB_REJECTED, REPORT_JOB_PENDING, ADD_JOB_PENDING, ADD_JOB_REJECTED, ADD_JOB_FULFILLED, EXPORT_JOB_PENDING, EXPORT_JOB_REJECTED, EXPORT_JOB_FULFILLED } from "../Actions/Types/Dashboard/Jobs";
import { FETCH_CLIENTS_PENDING, FETCH_CLIENTS_REJECTED, FETCH_CLIENTS_FULFILLED } from "../Actions/Types/Dashboard/Clients";

import { FETCH_CLIENT_LIST_PENDING, FETCH_CLIENT_LIST_REJECTED, FETCH_CLIENT_LIST_FULFILLED, FETCH_CLIENT_SEARCH_LIST_PENDING, FETCH_CLIENT_SEARCH_LIST_REJECTED, FETCH_CLIENT_SEARCH_LIST_FULFILLED, FETCH_CLIENT_HISTORY_LIST_PENDING, FETCH_CLIENT_HISTORY_LIST_REJECTED, FETCH_CLIENT_HISTORY_LIST_FULFILLED, FETCH_CLIENT_HISTORY_LIST, ADD_CLIENT_PENDING, ADD_CLIENT_REJECTED, ADD_CLIENT_FULFILLED } from "../Actions/Types/Client/Client";

import { FETCH_NOTIFICATION_LIST_PENDING, FETCH_NOTIFICATION_LIST_REJECTED, FETCH_NOTIFICATION_LIST_FULFILLED } from "../Actions/Types/Notification/Notification";

import { FETCH_FAQ_LIST_PENDING, FETCH_FAQ_LIST_REJECTED, FETCH_FAQ_LIST_FULFILLED } from "../Actions/Types/Faq/Faq";

import { FETCH_EMAIL_TEMPLATE_LIST_PENDING, FETCH_EMAIL_TEMPLATE_LIST_REJECTED, FETCH_EMAIL_TEMPLATE_LIST_FULFILLED } from "../Actions/Types/EmailTemplate/EmailTemplate";

import { FETCH_LOCATION_LIST_PENDING, FETCH_LOCATION_LIST_REJECTED, FETCH_LOCATION_LIST_FULFILLED } from "../Actions/Types/Location/Location";

import { FETCH_STAFF_SEARCH_LIST_PENDING, FETCH_STAFF_SEARCH_LIST_REJECTED, FETCH_STAFF_SEARCH_LIST_FULFILLED, ADD_STAFF_PENDING, ADD_STAFF_REJECTED, ADD_STAFF_FULFILLED } from "../Actions/Types/Staff/Staff";

const initialState = {
  screen: undefined,
  loading: 0
};

const global = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_JOB_STAFF_PENDING:
    case FETCH_CLIENT_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_CLIENTS_PENDING:
    case FETCH_NOTIFICATION_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_CLIENT_HISTORY_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_FAQ_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case REPORT_JOB_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_JOB_DETAIL_PENDING:
    case FETCH_JOBS_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }

    case UPDATE_JOB_STAFF_FULFILLED:
    case UPDATE_JOB_STAFF_REJECTED:
    case FETCH_CLIENTS_REJECTED:
    case FETCH_CLIENTS_FULFILLED:
    case FETCH_CLIENT_LIST_REJECTED:
    case FETCH_CLIENT_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_NOTIFICATION_LIST_REJECTED:
    case FETCH_CLIENT_HISTORY_LIST_REJECTED:
    case FETCH_FAQ_LIST_REJECTED:
    case REPORT_JOB_REJECTED:
    case FETCH_NOTIFICATION_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_CLIENT_HISTORY_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_FAQ_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case REPORT_JOB_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_JOB_DETAIL_REJECTED:
    case FETCH_JOB_DETAIL_FULFILLED:
    case FETCH_JOBS_LIST_REJECTED:
    case FETCH_JOBS_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_EMAIL_TEMPLATE_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_EMAIL_TEMPLATE_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_EMAIL_TEMPLATE_LIST_REJECTED:
    case FETCH_LOCATION_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_LOCATION_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_LOCATION_LIST_REJECTED:
    case FETCH_STAFF_SEARCH_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_STAFF_SEARCH_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_STAFF_SEARCH_LIST_REJECTED:
    case ADD_STAFF_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case ADD_STAFF_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case ADD_STAFF_REJECTED:
    case ADD_CLIENT_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case ADD_CLIENT_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case ADD_CLIENT_REJECTED:
    case FETCH_CLIENT_SEARCH_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case ADD_JOB_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case ADD_JOB_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case ADD_JOB_REJECTED:
    case FETCH_CLIENT_SEARCH_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_CLIENT_SEARCH_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case FETCH_CLIENT_SEARCH_LIST_REJECTED:
    case EXPORT_JOB_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case EXPORT_JOB_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    case EXPORT_JOB_PENDING:
    default:
      return state;
  }
};

export default global;
