import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Form, Field } from "react-final-form";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import ChipButton from "../../../UI/Elements/Buttons/ChipButton/ChipButton";
import "./ConstructionInductionModel.css";
import ConstructionInductionModelTheme from "./ConstructionInductionModelTheme";
import { MuiThemeProvider } from "@material-ui/core";
import StaffActions from "../../../../Redux/Actions/dispatches/Staff";
import DragAndDrop from "../../../UI/Elements/DragAndDrop/DragAndDrop";
import moment from "moment";
import $ from "jquery";

const ConstructionInductionModel = ({ opened, setOpened, constructionCardNo, setConstructionCardNo, constructionRtoNo, setConstructionRtoNo, constructionIssueDate, setConstructionIssueDate, constructionImage, setConstructionImage, selectedConstruction, setSelectedConstruction }) => {

  const selectedStaff = useSelector(state => state.staff.selectedStaff);
  const today = moment().format("YYYY-MM-DD");
  const [imagePreview, setImagePreview] = React.useState(constructionImage != undefined && constructionImage != null ? constructionImage : "/images/default-upload-popup.png");
  const dispatch = useDispatch();

  useEffect(() => {
  }, [dispatch, selectedStaff]);

  const style = theme => ({
    subTitle: {
      top: theme.spacing(-5),
      alignContent: "space-between",
      color: theme.palette.background.paper
    },
    closeButton: {
      marginLeft: "auto",
      backgroundColor: "#A3C960",
      padding: "4px",
      color: theme.palette.background.paper
    }
  });

  const addConstruction = () => {
    var cardNo = document.getElementById("red_card_card_no").value;
    var rtoNo = document.getElementById("red_card_rto_no").value;
    var issueDate = document.getElementById("red_card_issue_date").value;
    if (cardNo == "") {
      document.getElementById('errorDiv-red_card_card_no').innerHTML = "Please enter card number";
    } else {
      document.getElementById('errorDiv-red_card_card_no').innerHTML = "";
      setConstructionCardNo(cardNo);
    }

    if (rtoNo == "") {
      document.getElementById('errorDiv-red_card_rto_no').innerHTML = "Please rto number";
    } else {
      document.getElementById('errorDiv-red_card_rto_no').innerHTML = "";
      setConstructionRtoNo(rtoNo);
    }

    if (issueDate == "") {
      document.getElementById('errorDiv-red_card_issue_date').innerHTML = "Please issue date";
    } else {
      document.getElementById('errorDiv-red_card_issue_date').innerHTML = "";
      setConstructionIssueDate(issueDate);
    }

    if (cardNo !== "" && rtoNo !== "" && issueDate !== "") {
      setOpened(false);
      setSelectedConstruction(true);
    }
  };

  function onConstructionImageChange(event) {
    setConstructionImage(event.target.files[0]);
    setImagePreview(URL.createObjectURL(event.target.files[0]));
  }

  function onConstructionImageSelect(e) {
    $("#construction_induction_image").css('opacity', '0');
    e.preventDefault();
    $("#construction_induction_image").trigger('click');
    $(".dragged-thumb").addClass('dragged-thumb-file-preview');
  }

  return (
    <Fragment>
      <MuiThemeProvider theme={ConstructionInductionModelTheme}>
        <Dialog
          fullWidth
          open={opened}
          onClose={() => setOpened(false)}
          style={{ color: "#E8E8E8" }}
          disableBackdropClick
        >
          <div className="add-staff-modal-over pop-title-custom-over" >
            <p>Construction Induction</p>
            <IconButton aria-label="close" style={style(ConstructionInductionModelTheme).closeButton} onClick={() => setOpened(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent className="padd0 popupoverflowAuto">
            <div className="add-qualifcation-pop-outer">
              {/* <div className="add-qualifcation-pop-left">
                <DragAndDrop
                  // title="Upload TCA, Driver License, White/Blue Card and other qualificatons"
                  isNotDisplayUpload={true}
                />
                <div className="dragged-icon"><img src="/images/default-upload-popup.png" alt="" /></div>
              </div> */}
              <div className="add-qualifcation-pop-left">
                {selectedStaff != undefined && selectedStaff != '' ? (
                  <div className="dragged-thumb dragged-thumb-file-preview">
                    <img src={imagePreview} alt="" />
                    <a href="#" id="select_construction_induction_image" onClick={onConstructionImageSelect}>Click to Upload New Image</a><input type="file" style={{ opacity: '0' }} id="construction_induction_image" name="construction_induction_image" onChange={onConstructionImageChange} accept="image/*" />
                  </div>
                ) : (
                    <div className="dragged-thumb dragged-thumb-file-preview">
                      <img src={imagePreview} alt="" />
                      <a href="#" id="select_construction_induction_image" onClick={onConstructionImageSelect}>Click to Upload New Image</a><input type="file" style={{ opacity: '0' }} id="construction_induction_image" name="construction_induction_image" onChange={onConstructionImageChange} accept="image/*" />
                    </div>)}
              </div>
              <div className="add-qualifcation-pop-right">
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title"><p>Card Number</p></div>
                  <input
                    required=""
                    placeholder="Enter Card Number"
                    type="text"
                    name="red_card_card_no"
                    maxLength={`20`}
                    className="add-new-job-input-tf false"
                    id="red_card_card_no"
                    onChange={(id) => {
                      setConstructionCardNo(id.target.value);
                    }}
                    value={constructionCardNo}
                  />
                  <div className="error-message text-danger" id="errorDiv-red_card_card_no"></div>
                </div>
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title"><p>RTO Number</p></div>
                  <input
                    required=""
                    placeholder="Enter RTO Number"
                    type="text"
                    name="red_card_rto_no"
                    maxLength={`20`}
                    className="add-new-job-input-tf false"
                    id="red_card_rto_no"
                    onChange={(id) => {
                      setConstructionRtoNo(id.target.value);
                    }}
                    value={constructionRtoNo}
                  />
                  <div className="error-message text-danger" id="errorDiv-red_card_rto_no"></div>
                </div>
                <div className="add-quali-inp-block">
                  <div className="add-new-job-field-title"><p>Issue Date</p></div>
                  <input
                    required=""
                    placeholder="Enter Issue Date"
                    type="date"
                    max={today}
                    name="red_card_issue_date"
                    className="add-new-job-input-tf false"
                    id="red_card_issue_date"
                    onChange={(id) => {
                      setConstructionIssueDate(id.target.value);
                    }}
                    value={constructionIssueDate}
                  />
                  <div className="error-message text-danger" id="errorDiv-red_card_issue_date"></div>
                </div>
                <div className="add-quali-btn">
                  <Button
                    onClick={addConstruction}
                    style={{
                      background: "#A3C960",
                      borderRadius: 25,
                      color: "white",
                      padding: "6px 24px",
                      margin: "24px 0"
                    }}
                  >
                    {selectedConstruction == true ? 'EDIT CONSTRUCTION INDUCTION' : 'ADD CONSTRUCTION INDUCTION'}
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>

        </Dialog>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default ConstructionInductionModel;
