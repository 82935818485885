import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { Helmet } from "react-helmet";
import DaysPicker from "../../../UI/Elements/DaysPicker/DaysPicker";
import JobsRequests from "../../../../Requests/Jobs/Jobs";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAdress,
  getLatLng,
} from "react-places-autocomplete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rc-time-picker/assets/index.css";
import JobClientEmailModel from "../../Staff/JobClientEmailModal/JobClientEmailModal";
import TimePicker from "rc-time-picker";
const now = moment().hour(0).minute(0);
const modifiers = {
  selected: {},
};
const format = "h:mm A";
const renderTF = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  fieldType
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type={fieldType}
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFNR = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  fieldType
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            // required
            disabled={disabled}
            placeholder={placeholder}
            type={fieldType}
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFAE = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  fieldType,
  handleAdditionalEmail
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <div className="add-new-job-additional-email">
        <Field name={fieldName} defaultValue={value && value}>
          {({ input }) => (
            <input
              required
              disabled={disabled}
              placeholder={placeholder}
              type={fieldType}
              {...input}
              className={`add-new-job-input-tf ${
                disabled && "job-form-disabled"
              }`}
              id={fieldName}
              // style="padding: 4px 40px 4px 20px;"
            />
          )}
        </Field>
        <a href="javascript:void(0)">
          <img
            src="/images/white-close-icon.png"
            alt=""
            onClick={handleAdditionalEmail}
          />
        </a>
      </div>

      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFCE = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  fieldType,
  setJobClientEmailModal,
  clientOtherEmail
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group relative">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type={fieldType}
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      {/* <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div> */}
      <div className="add-new-job-field-title add-alternate-email">
        <p
          onClick={() => setJobClientEmailModal(true)}
          style={{ cursor: "pointer", color: "#8FBE3E" }}
        >
          {clientOtherEmail !== undefined &&
          clientOtherEmail !== null &&
          clientOtherEmail !== ""
            ? "Edit Additional Email"
            : "Add Additional Email"}
        </p>
      </div>
    </Grid>
  );
};

const renderTFM = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  fieldType
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type={fieldType}
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
            minLength={`10`}
            maxLength={`16`}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFN = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  fieldType
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type={fieldType}
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
            min={`1`}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderSelect = (
  fieldName,
  title,
  placeholder,
  options,
  disabled,
  value,
  setJobType
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {(props) => (
          <select
            {...props.input}
            className="add-new-job-input-select"
            disabled={disabled}
            value={value}
            onChange={(id) => {
              setJobType(id.target.value);
            }}
          >
            {options.map((option, index) => {
              return <option value={`${option.id}`} key={`job-type-${index}`}>{option.label}</option>;
            })}
          </select>
        )}
      </Field>
    </Grid>
  );
};

const renderTA = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {(props) => (
          <textarea
            {...props.input}
            className="add-new-job-input-textarea"
            disabled={disabled}
          ></textarea>
        )}
      </Field>
    </Grid>
  );
};

const renderCHArray = (
  fieldName,
  title,
  placeholder,
  disabled,
  value,
  setShiftType
) => {
  return (
    <Grid item lg={4} md={6} xs={12} className="grid-form-group">
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <div className="add-new-job-field-cb-wrapper add-job-radio-btn">
        <div className="radio-custom">
          <Field name={fieldName} component="input" type="radio" value={"1"}>
            {(props) => (
              <input
                {...props.input}
                disabled={disabled}
                checked={value == "1" ? true : false}
                onChange={(e) => setShiftType(1)}
                className="add-new-job-field-cb"
                id="day"
              ></input>
            )}
          </Field>
          <label htmlFor="day" className="add-new-job-field-cb-label">
            Day
          </label>
        </div>

        <div className="radio-custom">
          <Field name={fieldName} component="input" type="radio" value={"0"}>
            {(props) => (
              <input
                {...props.input}
                disabled={disabled}
                checked={value == "0" ? true : false}
                onChange={(e) => setShiftType(0)}
                className="add-new-job-field-cb"
                id="night"
              ></input>
            )}
          </Field>
          <label htmlFor="night" className="add-new-job-field-cb-label">
            Night
          </label>
        </div>
      </div>
    </Grid>
  );
};

const JobForm = ({
  jobAddressOptions,
  jobTypeOptions,
  selectedJob,
  clients,
  staffs,
  permits,
  tgs,
  permitfiles,
  tgsfiles,
  removedPermits,
  removedTgs,
  setSubmitButtonDisable,
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [openJobClientEmailModal, setJobClientEmailModal] = useState(false);

  const isFormDisabled = () => {
    return selectedJob && selectedJob.status === 2;
  };

  const today = moment().format("YYYY-MM-DD");
  const todayDateTime = moment().format("DD-MM-YYYY hh:mm A");

  const sendJobsToServer = (index, dates, formObj, callback) => {
    if (index >= dates.length) {
      callback();
      return;
    }
    const body = new FormData();
    body.append("start_date", moment(dates[index].date).format("YYYY-MM-DD"));
    body.append("date", moment(dates[index].date).format("YYYY-MM-DD"));
    body.append("tbc", dates[index].tbc ? 1 : 0);
    body.append("status", 0);
    body.append("time_start", today);
    body.append("address", address);
    body.append("booking_name", formObj.booking_name);
    body.append("booking_made_date", formObj.booking_made_date);
    body.append("client", formObj.client);
    //body.append('client_other_email', formObj.client_other_email !== undefined && formObj.client_other_email !== '' && formObj.client_other_email !== null ? formObj.client_other_email : '');
    body.append("client_other_email", clientOtherEmail);
    body.append("contact_number", formObj.contact_number);
    body.append("gtdc", formObj.gtdc);
    body.append("job_type", formObj.job_type);
    // body.append('location', formObj.location);
    body.append(
      "location",
      formObj.location !== undefined &&
        formObj.location !== null &&
        formObj.location !== ""
        ? formObj.location
        : ""
    );
    body.append(
      "notes",
      formObj.notes !== undefined &&
        formObj.notes !== null &&
        formObj.notes !== ""
        ? formObj.notes
        : ""
    );
    body.append("number_trafic", formObj.number_trafic);
    body.append("number_utes", formObj.number_utes);
    body.append(
      "setup_required",
      formObj.setup_required !== undefined &&
        formObj.setup_required !== null &&
        formObj.setup_required !== ""
        ? formObj.setup_required
        : ""
    );
    body.append("shift_type", shiftType);
    // body.append('time_req_site', formObj.time_req_site);
    body.append("time_req_site", moment(newTime, "hh:mm A").format("HH:mm"));
    body.append("staffs", JSON.stringify(staffs));

    if (
      permitfiles !== undefined &&
      permitfiles !== null &&
      permitfiles.length > 0
    ) {
      var i;
      for (i = 0; i < permitfiles.length; i++) {
        body.append(`permits[${i}]`, permitfiles[i], permitfiles[i].name);
      }
    }

    if (tgsfiles !== undefined && tgsfiles !== null && tgsfiles.length > 0) {
      var i;
      for (i = 0; i < tgsfiles.length; i++) {
        body.append(`tgs[${i}]`, tgsfiles[i], tgsfiles[i].name);
      }
    }

    //return false;
    const requestForgot = JobsRequests.createNewJob(body);
    requestForgot.then((response) => {
      if (response) {
        if (response.status) {
          //toast.success(response.message);
        } else {
          setSubmitButtonDisable(false);
          toast.error(response.message);
        }
      } else {
        setSubmitButtonDisable(false);
        toast.error("Something went wrong!!");
      }
      sendJobsToServer(++index, dates, formObj, callback);
    });
  };
  const handleJobCreationProcess = (formObj) => {
    if (!selectedJob) {
      if (selectedDates.length === 0) {
        setSubmitButtonDisable(false);
        toast.error("Please select at least one date to create job");
      } else {
        sendJobsToServer(0, selectedDates, formObj, () => {
          toast.success("Job submmited successfully.");
          window.location = "/Jobs";
        });
      }
    } else if (selectedJob.status === 1) {
      JobsRequests.editJob(selectedJob.id, formObj);
    } else {
      const body = new FormData();
      body.append("id", selectedJob.id);
      body.append("time_start", today);
      body.append("address", address);
      body.append("shift_type", shiftType);

      body.append("booking_name", formObj.booking_name);
      body.append("booking_made_date", formObj.booking_made_date);
      body.append("client", formObj.client);
      // body.append('client_other_email', formObj.client_other_email !== undefined && formObj.client_other_email !== '' && formObj.client_other_email !== null ? formObj.client_other_email : '');
      body.append("client_other_email", clientOtherEmail);
      body.append("contact_number", formObj.contact_number);
      body.append("gtdc", formObj.gtdc);
      body.append("job_type", formObj.job_type);
      // body.append('location', formObj.location);
      body.append(
        "location",
        formObj.location !== undefined &&
          formObj.location !== null &&
          formObj.location !== ""
          ? formObj.location
          : ""
      );
      body.append(
        "notes",
        formObj.notes !== undefined &&
          formObj.notes !== null &&
          formObj.notes !== ""
          ? formObj.notes
          : ""
      );
      body.append("number_trafic", formObj.number_trafic);
      body.append("number_utes", formObj.number_utes);
      body.append(
        "setup_required",
        formObj.setup_required !== undefined &&
          formObj.setup_required !== null &&
          formObj.setup_required !== ""
          ? formObj.setup_required
          : ""
      );
      // body.append('time_req_site', formObj.time_req_site);
      body.append("time_req_site", moment(newTime, "hh:mm A").format("HH:mm"));

      body.append("staffs", JSON.stringify(staffs));
      if (
        permitfiles !== undefined &&
        permitfiles !== null &&
        permitfiles.length > 0
      ) {
        var i;
        for (i = 0; i < permitfiles.length; i++) {
          body.append(`permits[${i}]`, permitfiles[i], permitfiles[i].name);
        }
      }

      if (tgsfiles !== undefined && tgsfiles !== null && tgsfiles.length > 0) {
        var i;
        for (i = 0; i < tgsfiles.length; i++) {
          body.append(`tgs[${i}]`, tgsfiles[i], tgsfiles[i].name);
        }
      }

      body.append(
        "permit_ids",
        removedPermits != undefined && removedPermits !== null
          ? removedPermits
          : []
      );
      body.append(
        "tgs_ids",
        removedTgs != undefined && removedTgs !== null ? removedTgs : []
      );

      const updateJob = JobsRequests.editJob(body);
      updateJob.then((response) => {
        if (response) {
          if (response.status) {
            toast.success(response.message);
            //window.sessionStorage.setItem("record-status",'saved');
            window.location = "/Jobs";
          } else {
            setSubmitButtonDisable(false);
            toast.error(response.message);
          }
        } else {
          setSubmitButtonDisable(false);
          toast.error("Something went wrong!!");
        }
      });
    }
  };

  const handleJobCreationProcess1 = (formObj) => {
    const body = new FormData();
    if (!selectedJob) {
      if (selectedDates.length == 0) {
        toast.error("Please select at least one date to create job");
      }
      selectedDates.map((date) => {
        formObj["start_date"] = moment(date.date).format("YYYY-MM-DD");
        formObj["date"] = moment(date.date).format("YYYY-MM-DD");
        formObj["tbc"] = date.tbc;
        formObj["staffs"] = staffs;
        formObj["permits"] = permits;
        formObj["tgs"] = tgs;
        formObj["status"] = 0;
        formObj["time_start"] = today;
        formObj["address"] = address;
        formObj["shift_type"] = shiftType;

        const requestForgot = JobsRequests.createNewJob(formObj);
        requestForgot.then((response) => {
          if (response) {
            if (response.status) {
              toast.success(response.message);
              window.location = "/Jobs";
            } else {
              toast.error(response.message);
            }
          } else {
            toast.error("Something went wrong!!");
          }
        });
      });
    } else if (selectedJob.status === 1) {
      JobsRequests.editJob(selectedJob.id, formObj);
    } else {
      formObj["id"] = selectedJob.id;
      formObj["time_start"] = today;
      formObj["address"] = address;
      formObj["shift_type"] = shiftType;
      const updateJob = JobsRequests.editJob(formObj);
      updateJob.then((response) => {
        if (response) {
          if (response.status) {
            toast.success(response.message);
            window.location = "/Jobs";
          } else {
            toast.error(response.message);
          }
        } else {
          toast.error("Something went wrong!!");
        }
      });
    }
  };

  const [address, setAddress] = React.useState(
    selectedJob != undefined ? selectedJob.address : ""
  );
  const [jobType, setJobType] = React.useState(
    selectedJob != undefined ? selectedJob.job_type : "1"
  );
  const [newTime, setNewTime] = React.useState(
    selectedJob != undefined
      ? moment(selectedJob.time_req_site, "HH:mm")
      : moment().hour(0).minute(0)
  );
  const [shiftType, setShiftType] = React.useState(
    selectedJob != undefined ? selectedJob.shift_type : "1"
  );
  const [client, setClient] = React.useState(
    selectedJob != undefined ? selectedJob.id_client : ""
  );
  const [clientName, setClientName] = React.useState(
    selectedJob != undefined ? selectedJob.client_name : ""
  );
  const [clientEmail, setClientEmail] = React.useState(
    selectedJob != undefined ? selectedJob.client_email : ""
  );
  const [clientOtherEmail, setClientOtherEmail] = React.useState(
    selectedJob != undefined &&
      selectedJob.client_other_email != undefined &&
      selectedJob.client_other_email != null &&
      selectedJob.client_other_email != ""
      ? selectedJob.client_other_email
      : ""
  );
  if (selectedJob != undefined) {
    //console.log(moment(selectedJob.time_req_site, "HH:mm"));
    //console.log(moment().hour(0).minute(0), "-----");
  }
  const handleSelect = async (value) => {
    setAddress(value);
  };

  const searchOptions = {
    componentRestrictions: { country: "au" },
  };

  function onChangetime(value) {
    setNewTime(value.format(format));
  }

  const handleAdditionalEmail = () => {
    confirmAlert({
      title: "Get Directed",
      message: "Are you sure you want to remove additional email?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setClientOtherEmail("");
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div
      className={`grids-outer-common-css-full`}
      style={{ padding: " 8px 0" }}
    >
      <JobClientEmailModel
        opened={openJobClientEmailModal}
        setOpened={setJobClientEmailModal}
        clientOtherEmail={clientOtherEmail}
        setClientOtherEmail={setClientOtherEmail}
        clientEmail={clientEmail}
      />
      <Form
        onSubmit={(formObj) => {
          handleJobCreationProcess(formObj);
        }}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            id="job-form"
            encType="multipart/form-data"
          >
            <Grid container spacing={4}>
              <div
                className={`grid-outer-add ${
                  selectedJob != undefined && "job-page-edit"
                }`}
              >
                <Grid item lg={8} md={8} xs={12} className="grid-form-group">
                  <div className="add-new-job-field-title">
                    <p>{"Select a client"}</p>
                  </div>
                  {/* <Field name={"client"}> */}
                  <Field name={"client"} defaultValue={client && client}>
                    {(props) => (
                      <select
                        {...props.input}
                        className="add-new-job-input-select"
                        //disabled={disabled}
                        value={client}
                        onChange={(id) => {
                          var index = id.target.selectedIndex;
                          var optionElement = id.target.childNodes[index];
                          var clientName =
                            optionElement.getAttribute("client_name");
                          var clientEmail =
                            optionElement.getAttribute("client_email");
                          setClient(id.target.value);
                          setClientName(clientName);
                          setClientEmail(clientEmail);
                        }}
                        id="client"
                      >
                        <option value="">Search for a client</option>
                        {clients &&
                          clients.map((option, index) => {
                            return (
                              <option
                                key={`${option}-${index}`}
                                value={option.id}
                                client_name={option.name}
                                client_email={option.email}
                              >
                                {option.company_name}
                              </option>
                            );
                          })}
                      </select>
                    )}
                  </Field>
                  <div
                    className="error-message text-danger"
                    id={`errorDiv-client`}
                  ></div>
                </Grid>
                <Grid item lg={4} md={4} xs={12} className="form-blank-div" />

                {renderTF(
                  "gtdc",
                  "Booking Taken By (GDTC)",
                  "Enter GDTC Staff Name",
                  isFormDisabled(),
                  selectedJob && selectedJob.gtdc,
                  "required"
                )}

                <Grid item lg={4} md={6} xs={12} className="grid-form-group">
                  <div className="add-new-job-field-title">
                    <p>{"Date Booking Made"}</p>
                  </div>
                  <Field
                    name={"booking_made_date"}
                    defaultValue={todayDateTime}
                  >
                    {({ input }) => (
                      <input
                        disabled={true}
                        placeholder={"placeholder"}
                        type="text"
                        {...input}
                        className={`add-new-job-input-tf ${"job-form-disabled"}`}
                      />
                    )}
                  </Field>
                </Grid>
                {renderTF(
                  "client_name",
                  "Client",
                  "Enter Client Name",
                  true,
                  clientName
                )}
                {renderTF(
                  "booking_name",
                  "Booking Contact Name",
                  "Enter Booking Contact Name",
                  isFormDisabled(),
                  selectedJob && selectedJob.booking_name
                )}
                {renderTFM(
                  "contact_number",
                  "Contact Number",
                  "Enter Contact Number",
                  isFormDisabled(),
                  selectedJob && selectedJob.contact_number,
                  "tel"
                )}
                {renderTFCE(
                  "client_email",
                  "Client Email",
                  "Enter Client Email",
                  true,
                  clientEmail,
                  "text",
                  setJobClientEmailModal,
                  clientOtherEmail
                )}
                {clientOtherEmail !== undefined &&
                  clientOtherEmail !== null &&
                  clientOtherEmail !== "" &&
                  renderTFAE(
                    "client_other_email",
                    "Additional Email",
                    "Enter Additional Email",
                    true,
                    clientOtherEmail,
                    "text",
                    handleAdditionalEmail
                  )}
                <Grid item lg={4} md={6} xs={12} className="grid-form-group">
                  <div className="add-new-job-field-title">
                    <p>Job Address</p>
                  </div>
                  <PlacesAutocomplete
                    // value={selectedJob != undefined ? selectedJob.address : address}
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className={"custom-address-dropdown"}>
                        <input
                          id="address"
                          className="add-new-job-input-tf address"
                          {...getInputProps({
                            placeholder: "Enter job address",
                          })}
                        />
                        <div className="address-section">
                          {loading ? (
                            <div className="address-suggestion">...loading</div>
                          ) : (
                            ""
                          )}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#84d1f1"
                                : "#fff",
                            };
                            return (
                              <div
                                className="address-suggestion"
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className="error-message text-danger"
                          id="errorDiv-address"
                        ></div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Grid>
                {renderSelect(
                  "job_type",
                  "Type Of Job",
                  "",
                  jobTypeOptions,
                  isFormDisabled(),
                  jobType,
                  setJobType
                )}

                <div className="addPageLeft">
                  {<Grid className="form-blank-div" item />}
                  {
                    //   renderTF(
                    //   "date",
                    //   "Date Booking Made",
                    //   "",
                    //   true,
                    //   new Date().toLocaleDateString()
                    // )
                  }
                  {/* {renderTF("client", "Client", "Get Directed", isFormDisabled())} */}

                  {/* {renderTF(
                    "time_req_site",
                    "Time Req On Site",
                    "Enter Time Req On Site",
                    isFormDisabled(),
                    selectedJob && selectedJob.time_req_site,
                    'time'
					  )}*/}

                  <div className="MuiGrid-root grid-form-group MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6 MuiGrid-grid-lg-4">
                    <div className="add-new-job-field-title">
                      <p>Time Req On Site</p>
                    </div>

                    <TimePicker
                      showSecond={false}
                      className="xxx"
                      defaultValue={newTime}
                      onChange={onChangetime}
                      format={format}
                      minuteStep={15}
                      use12Hours
                      inputReadOnly
                    />
                  </div>

                  {renderCHArray(
                    "shift_type",
                    "Shift",
                    "",
                    false,
                    shiftType,
                    setShiftType
                  )}

                  {renderTFN(
                    "number_utes",
                    "Number of UTES",
                    "",
                    isFormDisabled(),
                    selectedJob && selectedJob.number_utes,
                    "number"
                  )}
                  {renderTFN(
                    "number_trafic",
                    "Number of Traffic Controllers",
                    "",
                    isFormDisabled(),
                    selectedJob && selectedJob.number_trafic,
                    "number"
                  )}
                  {renderTA(
                    "setup_required",
                    " Setup Required",
                    "",
                    isFormDisabled(),
                    selectedJob && selectedJob.setup_required
                  )}
                  {renderTA(
                    "notes",
                    "Notes",
                    "",
                    isFormDisabled(),
                    selectedJob && selectedJob.notes
                  )}
                  {renderTFNR(
                    "location",
                    "Location of the UTE",
                    "",
                    isFormDisabled(),
                    selectedJob && selectedJob.location
                  )}
                </div>
                <div className="addPageRight">
                  {selectedJob ? (
                    <Grid item lg={4} md={6} xs={12} />
                  ) : (
                    <Grid item xs={12} className="grid-calender">
                      <div className="add-new-job-field-title">
                        <p>{"Select date(s)"}</p>
                      </div>
                      <div className="add-new-job-dates-picker-wrapper">
                        <div className="picker">
                          <Helmet>
                            <style>{`
.DayPicker-Day .DayPicker-Day--selected {
background-color: #a3c960;
borderRadius:10
}

`}</style>
                          </Helmet>
                          <DaysPicker
                            showTBCRail
                            selectedDates={selectedDates}
                            setSelectedDates={setSelectedDates}
                            modifiers={modifiers}
                          />
                        </div>
                      </div>
                    </Grid>
                  )}
                </div>
              </div>
            </Grid>
          </form>
        )}
      </Form>
      <ToastContainer />
    </div>
  );
};

export default JobForm;
