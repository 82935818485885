import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClientStyles from "./ClientStyles";
import "./Client.css";
import Header from "../../UI/Header/HeaderNotLink";
import Sidebar from "../../UI/SideBar/Sidebar";
import SearchBarClient from "../../UI/SearchBar/SearchBarClient";
import GridView from "./UI/GridView/GridView";
import { useDispatch } from "react-redux";
import ClientActions from "../../../Redux/Actions/dispatches/Client";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "react-js-pagination";
require("bootstrap");
require("bootstrap/less/bootstrap.less");

const Clients = () => {
  const classes = ClientStyles();
  const clients = useSelector((state) => state.client.clientsearch);
  const dispatch = useDispatch();
  //const [searchClient, setSearchClient] = useState(clients);
  const [searchClient, setSearchClient] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [filterLocation, setFilterLocation] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [itemsCountPerPage, setItemsCountPerPage] = useState();
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(3);
  const [pageNo, setPageNo] = useState("1");
  const [serachKeyword, setSerachKeyword] = useState("");

  useEffect(() => {
    var formData = {};
    formData["page"] = pageNo;
    formData["keyword"] = serachKeyword;

    /*if(window.sessionStorage.getItem("click-event") == 'edit' && window.sessionStorage.getItem("type") == 'client' && window.sessionStorage.getItem("record-status") == 'saved'){
		if(window.sessionStorage.getItem("page")){
			formData['page'] = window.sessionStorage.getItem("page");
			var pageFilter = parseInt(formData['page']);
			 setActivePage(pageFilter);
		}
		
		if(window.sessionStorage.getItem("keyword")){
			formData['keyword'] = window.sessionStorage.getItem("keyword");
			 setSerachKeyword(window.sessionStorage.getItem("keyword"));			 
		}
		
		if(window.sessionStorage.getItem('locationOrder')){
			 formData['locationOrder'] = window.sessionStorage.getItem('locationOrder');
			  setFilterLocation(window.sessionStorage.getItem('locationOrder'));	
		}
		window.sessionStorage.setItem("click-event",'');
	    window.sessionStorage.setItem("type",'');
	    window.sessionStorage.setItem("record-status",'');	
	}else{
		window.sessionStorage.setItem("page",pageNo);
	    window.sessionStorage.setItem("keyword",serachKeyword);
	}*/

    dispatch(ClientActions.fetchSearchClient(formData)).then((res) => {
      if (res.action.type == "FETCH_CLIENT_SEARCH_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          //setSearchClient(res.value.data);
          setSearchClient(res.value.data.data);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }, [dispatch]);

  const handleSearch = (e) => {
    setSerachKeyword(e.target.value);
    const formData = {};
    formData["page"] = pageNo;
    formData["locationOrder"] = filterLocation;
    formData["keyword"] = e.target.value.toLocaleLowerCase().trim();
    window.sessionStorage.setItem("page", pageNo);
    window.sessionStorage.setItem(
      "keyword",
      e.target.value.toLocaleLowerCase().trim()
    );
    window.sessionStorage.setItem("locationOrder", filterLocation);
    dispatch(ClientActions.fetchSearchClient(formData)).then((res) => {
      if (res.action.type == "FETCH_CLIENT_SEARCH_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSearchClient(res.value.data.data);
          setTotalItemsCount(res.value.data.total);
          setItemsCountPerPage(res.value.data.per_page);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  };

  function handleFilterChange(value) {
    setFilterLocation(value.target.value);
    const formData = {};
    formData["page"] = pageNo;
    formData["keyword"] = serachKeyword;
    formData["locationOrder"] = value.target.value;

    window.sessionStorage.setItem("page", pageNo);
    window.sessionStorage.setItem("keyword", serachKeyword);
    window.sessionStorage.setItem("locationOrder", value.target.value);
    dispatch(ClientActions.fetchSearchClient(formData)).then((res) => {
      if (res.action.type == "FETCH_CLIENT_SEARCH_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSearchClient(res.value.data.data);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    const formData = {};
    formData["page"] = pageNumber;
    formData["keyword"] = serachKeyword;
    formData["locationOrder"] = filterLocation;

    window.sessionStorage.setItem("page", pageNumber);
    window.sessionStorage.setItem("keyword", serachKeyword);
    window.sessionStorage.setItem("locationOrder", filterLocation);
    dispatch(ClientActions.fetchSearchClient(formData)).then((res) => {
      if (res.action.type == "FETCH_CLIENT_SEARCH_LIST_FULFILLED") {
        if (res.value && res.value.status == true) {
          setSearchClient(res.value.data.data);
        } else if (res.value && res.value.status == false) {
          //  toast.error(res.value.message);
        } else {
          // toast.error('Something went wrong');
        }
      }
    });
  }

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header />
          <SearchBarClient
            handleSearch={handleSearch}
            serachKeyword={serachKeyword}
          />
          <div className="filter-type">
            <span>Filter By </span>
            <select
              className="add-new-job-input-select"
              value={`${filterLocation}`}
              onChange={(e) => handleFilterChange(e)}
              id="client"
            >
              <option value="">Select Filter Type</option>
              <option value="ASC">Alphabetical Ascending</option>
              <option value="DESC">Alphabetical Descending</option>
              <option value="DateAsc">Date Ascending</option>
              <option value="DateDesc">Date Descending</option>
            </select>
          </div>
          <div className="grids-outer">
            <GridView clients={searchClient} />
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText="First"
              lastPageText="Last"
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              pageRangeDisplayed={pageRangeDisplayed}
              onChange={(e) => handlePageChange(e)}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Clients;
