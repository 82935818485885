let ForgotFetches = {
  requestForgot(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/forgot`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .catch(error => {
        alert(error);
        console.log(error);
      });
  }
};

export default ForgotFetches;
