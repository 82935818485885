import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import NotificationDetailsListStyles from "./NotificationDetailsListStyles";
import { MuiThemeProvider } from "@material-ui/core";
import NotificationDetailsListTheme from "./NotificationDetailsListTheme";
import "./NotificationDetailsList.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const NotificationDetailsList = ({ data }) => {
  const classes = NotificationDetailsListStyles();
  const dispatch = useDispatch();

  if (data.type == 'started') {
    var msg = data.message;
  }
  else if (data.type == 'completed') {
    var msg = data.message;
  }
  else if (data.type == 'rejected') {
    var msg = 'has been rejected the job.';
  }
  else if (data.type == 'risk_assessment') {
    var msg = data.message;
  }
  else if (data.type == 'vehicle_pre_check') {
    var msg = data.message;
  }
  else if (data.type == 'deleted') {
    var msg = 'Job has been deleted by admin.';
  }
  else {
    var msg = 'has been booked in for a job.';
  }

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={NotificationDetailsListTheme}>
        <div className={`${classes.container} ${'list-view-outer-block'}`}>
          <div style={{ display: "flex" }}>
            <div className="list-title-area" style={{ display: "flex", flex: 2, maxWidth: 500 }}>
              <div
                className={`job-details-id ${
                  0 == data.status
                    ? "incomplete"
                    : 1 == data.status
                      ? "in-progress"
                      : "completed"
                  }
              `}
              >
                <p>{`job ID: GC-${data.job_id}`}</p>
              </div>
              <div
                style={{
                  textAlign: "left",
                  padding: "10px 8px 8px",
                  fontSize: 16,
                  lineHeight: "26px",
                  color: "#535353"
                }}
              >
                {data.type === null || data.type === '' || data.type === 'removed' || data.type === 'rejected' ? (
                  <p><span style={{ textTransform: 'capitalize' }} className={`${
                    0 == data.status
                      ? "text-red"
                      : 1 == data.status
                        ? "text-orange"
                        : "text-darkgreen"
                    }
                  `}>{data.staff_name}</span> {msg}</p>) : (
                    <p>{msg}</p>)}
              </div>
            </div>
            <div
              className="list-table-area"
              style={{
                display: "flex",
                textAlign: "left",
                padding: "6px 8px",
                flex: 3
              }}
            >
              <div className="job-details-info-list-divider" />
              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Staff ID</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>GDTC-{data.staff_id}</p>
                </div>
              </div>
              <div className="job-details-info-list-divider" />
              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Team Leader</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.team_leader_name}</p>
                </div>
              </div>
              <div className="job-details-info-list-divider" />
              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Date</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{moment(data.created_at).format("DD-MM-YYYY hh:mm A")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default NotificationDetailsList;
