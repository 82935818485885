import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import SearchBarStyles from "./SearchBarStyles";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import SearchBarTheme from "./SearchBarTheme";
import "./SearchBar.css";

const SearchBarJobs = ({ handleSearch }) => {
  const classes = SearchBarStyles();

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={SearchBarTheme}>
        <div className={`${classes.container} ${'search-job-input-outer'}`}>
          <p>Jobs logged</p>
          <div className="divider" />
          <div className="search-job-input">
            <input type="text" placeholder="Search for a job" onChange={handleSearch} />
          </div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default SearchBarJobs;
