import React, { Fragment } from "react";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SidebarStyles from "./SidebarStyles";
import { MuiThemeProvider } from "@material-ui/core";
import "./Sidebar.css";
import SidebarTheme from "./SidebarTheme";
import ClientsIcon from "../Icons/ClientsIcon/ClientsIcon";
import JobsIcon from "../Icons/JobsIcon/JobsIcon";
import LocationIcon from "../Icons/LocationIcon/LocationIcon";
import NotificationsIcon from "../Icons/NotificationsIcon/NotificationsIcon";
import FaqsIcon from "../Icons/FaqsIcon/FaqsIcon";
import EmailTemplatesIcon from "../Icons/EmailTemplatesIcon/EmailTemplatesIcon";
import { Link } from "react-router-dom";
import StaffIcon from "../Icons/StaffIcon/StaffIcon";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

const options = ["Jobs", "Staff", "Clients", "Location", "Notifications"];

const Sidebar = () => {
  const classes = SidebarStyles();
  const sideBarClass = 'sideBarClass';
  const currentLocation = window.location.pathname;
  var clientData = currentLocation.split('/');
  var clientId = clientData[clientData.length - 1];

  const getOptionsIcon = option => {
    switch (option) {
      case "Jobs":
        return <JobsIcon />;
      case "Staff":
        return <StaffIcon />;
      case "Clients":
        return <ClientsIcon />;
      case "Location":
        return <LocationIcon />;
      case "Notifications":
        return <NotificationsNoneIcon fontSize={'small'} />;
      // case "Email Templates":
      //   return <EmailTemplatesIcon />;

      default:
        break;
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={SidebarTheme}>
        <Drawer
          className={`${classes.drawer} ${'sideBarClass'}`}//{classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          <a href="#" className="sidebar-close-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="6.292" height="11.536" viewBox="0 0 6.292 11.536"><g transform="translate(6.816 -0.524) rotate(180)"><path d="M-10.936,0l5.168,5.1L-.6,0,0,.6l-5.768,5.7L-11.536.6Z" transform="translate(0.524 -12.06) rotate(-90)" fill="#7f7f7f" /></g></svg>
          </a>
          <div className={classes.toolbar}>
            <Link to="/">
              <img className="sidebar-logo" src="/images/get-directed-logo.png" alt="" />
            </Link>
          </div>
          <ul className="dash-sidebar">
            {options.map((text, index) => (
              <li key={`sidebar-li-${index}`} className={window.location.pathname === `/${text.replace(' ', '')}` || window.location.pathname === `/${text.replace(' ', '')}/new` || window.location.pathname === `/${text.replace(' ', '')}/edit` || window.location.pathname === `/${text.replace(' ', '')}/report/${clientId}` || window.location.pathname === `/${text.replace(' ', '')}/history/${clientId}` ? 'active' : ''} >
                <Link to={`/${text.replace(' ', '')}`} style={{ textDecoration: "none", color: "black" }}>{getOptionsIcon(text)}<span>{text}</span></Link>
              </li>
            ))}
          </ul>
        </Drawer>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default Sidebar;
