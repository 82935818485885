import {
  FETCH_STAFF_LIST_FULFILLED,
  FETCH_STAFF_SEARCH_LIST_FULFILLED,
  ADD_STAFF_FULFILLED,
  SELECT_STAFF,
} from "../Actions/Types/Staff/Staff";

const initialState = {
  staff: undefined,
  staffsearch: undefined,
  selectedStaff: undefined,
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAFF_LIST_FULFILLED: {
      return {
        ...state,
        staff: action.payload && action.payload.data,
      };
    }
    case FETCH_STAFF_SEARCH_LIST_FULFILLED: {
      return {
        ...state,
        staffsearch: action.payload && action.payload.data,
      };
    }
    case ADD_STAFF_FULFILLED: {
      return {
        ...state,
        staff: action.payload && action.payload.data,
      };
    }
    case SELECT_STAFF: {
      return {
        ...state,
        selectedStaff: action.payload,
      };
    }
    default:
      return state;
  }
};

export default staff;
