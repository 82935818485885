import React from "react";
import { Grid } from "@material-ui/core";
import EmailTemplateDetailsList from "../EmailTemplateDetailsList/EmailTemplateDetailsList";
import NewEmailTemplate from "../NewEmailTemplate/NewEmailTemplate";

const ListView = ({ emailTemplates, completed }) => {
  return (

    <Grid container spacing={2} style={{ width: "calc(100% + 8px)" }}>
      {!completed && (
        <Grid item xs={12}>
          <NewEmailTemplate list />
        </Grid>
      )}
      {emailTemplates &&
        emailTemplates.map((emailTemplate, index) => {
          return (
            <Grid item xs={12} key={index} className={`${'dash-box-outer list-view day-view1'}`}>
              <EmailTemplateDetailsList data={emailTemplate} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ListView;
