import {
  FETCH_FAQ_LIST_FULFILLED,
  SELECT_FAQ
} from "../Actions/Types/Faq/Faq";

const initialState = {
  faq: undefined,
  selectedFaq: undefined,
  faqs: undefined
};

const faq = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAQ_LIST_FULFILLED: {
      return {
        ...state,
        faq: action.payload && action.payload.data
      };
    }
    case SELECT_FAQ: {
      return {
        ...state,
        selectedFaq: action.payload
      };
    }
    default:
      return state;
  }
};

export default faq;
