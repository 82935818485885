import { makeStyles } from "@material-ui/core/styles";

const HistoryStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#E8E8E8",
    paddingTop: 0,
    padding: theme.spacing(3),
    maxWidth: 1500,
    margin: "auto",
    minHeight: 80
  },
  jobsLoggedContainer: {
    width: "100%",
    display: "flex",
    padding: "18px 0",
    fontSize: 22
  },
  circleNumber: {
    borderRadius: 50,
    margin: "0 8px",
    backgroundColor: "#8FBE3E",
    color: "white",
    padding: "4px 6px"
  },
  jobCountInfoContainer: {
    float: "left",
    display: "flex",
    color: "#535353",
    fontSize: 12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


export default HistoryStyles;
