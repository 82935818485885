import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import NewEmailTemplateStyles from "./NewEmailTemplateStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import NewEmailTemplateTheme from "./NewEmailTemplateTheme";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import "./NewEmailTemplate.css";

const NewEmailTemplate = ({ list }) => {
  const classes = NewEmailTemplateStyles();
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={NewEmailTemplateTheme}>
        <div className={classes.container}>
          <Link to={`/EmailTemplates/new`} className={classes.link}>
            <Button className={classes.button}>
              <div className={`new-emailtemplate-main-wrapper ${list && "new-emailtemplate-main-wrapper-list"}`}>
                <div className={`new-emailtemplate-inner-wrapper ${list && "new-emailtemplate-inner-wrapper-list"}`}>
                  <AddCircleIcon className={classes.addCircle} />
                </div>
                <div className={`add-new-emailtemplate ${list && "add-new-emailtemplate-list"}`}>
                  <h4>{`Add a new Email Template`}</h4>
                </div>
              </div>
            </Button>
          </Link>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default NewEmailTemplate;
