
import DashboardFetches from "../../../../Requests/Dashboard/Dashboard";
import { FETCH_CLIENTS } from "../../Types/Dashboard/Clients";

let DashboardActions = {
  // populateLoggedJobs() {
  //   return {
  //     type: FETCH_LOGGED_JOBS,
  //     payload: DashboardFetches.fetchLoggedJobs()
  //   };
  // },
  // populateCompletedJobs(completedJobs) {
  //   return {
  //     type: FETCH_COMPLETED_JOBS,
  //     payload: completedJobs
  //   };
  // },
  fetchClients() {
    return {
      type: FETCH_CLIENTS,
      payload: DashboardFetches.fetchClients()
    };
  },
};

export default DashboardActions;
