import { createMuiTheme } from "@material-ui/core/styles";

const SidebarTheme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "unset"
      }
    }
  }
});

export default SidebarTheme;
