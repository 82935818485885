import React from "react";
import { Chip } from "@material-ui/core";
import ChipButtonStyles from "./ChipButtonStyles";
import { MuiThemeProvider } from "@material-ui/core";
import ChipButtonTheme from "./ChipButtonTheme";
import classnames from "classnames";

const ChipButtonDashboard = ({ title, action, icon, driver, active, onDelete, id }) => {
  const classes = ChipButtonStyles();
  return (
    <MuiThemeProvider theme={ChipButtonTheme}>
      <Chip
        icon={icon}
        label={
          <div style={{ display: "flex" }}>
            <div
              className={`bg-blue ${classnames(
                classes.staffTypeLetter,
                active && classes.active
              )}`}
            >
              {<p>{`${driver ? "D" : "S"}`}</p>}
            </div>
            <p style={{ paddingTop: 4 }}>{title}</p>
          </div>
        }
        onDelete={() => onDelete(id)}
        className={`${classnames(classes.chip)} ${'mem-selected'}`}
        style={{ float: `${driver ? "left" : "right"}` }}
      />
    </MuiThemeProvider>
  );
};

export default ChipButtonDashboard;
