import { combineReducers } from "redux";
import session from "./session";
import dashboard from "./dashboard";
import global from "./global";
import jobs from "./jobs";
import staff from "./staff";
import client from "./client";
import notification from "./notification";
import faq from "./faq";
import emailtemplate from "./emailtemplate";
import location from "./location";

export default combineReducers({ global, dashboard, session, jobs, staff, client, notification, faq, emailtemplate, location });