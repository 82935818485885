import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddNewFaqStyles from "./AddNewFaqStyles";
import "./AddNewFaq.css";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import Header from "../../../UI/Header/Header";
import { Form, Field } from "react-final-form";
import { Grid, Button } from "@material-ui/core";
import FaqActions from "../../../../Redux/Actions/dispatches/Faq";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { stat } from "fs";
import { MDBInput } from "mdbreact";

const renderTF = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={3} md={4} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            required
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          />
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const renderTFA = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item lg={6} md={8} xs={12}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          // <input
          //   required
          //   disabled={disabled}
          //   placeholder={placeholder}
          //   type="text"
          //   {...input}
          //   className={`add-new-job-input-tf ${disabled &&
          //     "job-form-disabled"}`}
          //   id={fieldName}
          // />
          <textarea
            required
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${
              disabled && "job-form-disabled"
            }`}
            id={fieldName}
          ></textarea>
        )}
      </Field>
      <div className="error-message text-danger" id={`errorDiv-${fieldName}`}></div>
    </Grid>
  );
};

const handleSubmitButton = () => {
  var question = document.forms["faq-form"]["question"].value;
  var answer = document.forms["faq-form"]["answer"].value;

  var status = false;

  if (question == "") {
    document.getElementById("errorDiv-question").innerHTML =
      "Please enter question";
  } else {
    document.getElementById("errorDiv-question").innerHTML = "";
    var status = true;
  }

  if (answer == "") {
    document.getElementById("errorDiv-answer").innerHTML =
      "Please enter answer";
  } else {
    document.getElementById("errorDiv-answer").innerHTML = "";
    var status = true;
  }

  if (status == true) {
    document
      .getElementById("faq-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  }
};

const AddNewFaq = () => {
  const classes = AddNewFaqStyles();
  const currentLocation = window.location.pathname;
  const today = moment().format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const selectedFaq = useSelector((state) => state.faq.selectedFaq);

  if (selectedFaq != undefined && currentLocation == "/Faqs/new") {
    window.location.reload();
  }

  const handleFaqProcess = (formObj) => {
    if (!selectedFaq) {
      dispatch(FaqActions.createFaq(formObj)).then((res) => {
        if (res.action.type == "FETCH_FAQ_LIST_FULFILLED") {
          if (res.value && res.value.status == true) {
            toast.success(res.value.message);
            window.location = "/Faqs";
          } else if (res.value && res.value.status == false) {
            toast.error(res.value.message);
          } else {
            // toast.error(res.value.message);
          }
        }
      });
    } else {
      formObj["id"] = selectedFaq.id;

      dispatch(FaqActions.updateFaq(formObj)).then((res) => {
        if (res.action.type == "FETCH_FAQ_LIST_FULFILLED") {
          if (res.value && res.value.status == true) {
            toast.success(res.value.message);
            window.location = "/Faqs";
          } else if (res.value && res.value.status == false) {
            toast.error(res.value.message);
          } else {
            // toast.error(res.value.message);
          }
        }
      });
    }
  };

  const [errorStatus, setErrorStatus] = React.useState(
    selectedFaq != undefined ? true : false
  );

  return (
    <Fragment>
      <div className={`${classes.root} ${"main-container"}`}>
        <Sidebar />
        <div className={`${classes.content} ${"main-content-area"}`}>
          <Header />
          <div style={{ paddingTop: 55 }}>
            <SectionDivider title="Add New Faq" />
          </div>
          <div style={{ padding: " 8px 0" }}>
            <Form onSubmit={(formObj) => handleFaqProcess(formObj)}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="faq-form">
                  <Grid container spacing={4}>
                    {renderTF(
                      "question",
                      "Question",
                      "Enter Question",
                      false,
                      selectedFaq && selectedFaq.question
                    )}

                    {renderTFA(
                      "answer",
                      "Answer",
                      "Enter Answer",
                      false,
                      selectedFaq && selectedFaq.answer
                    )}
                    <Grid item lg={9} md={12} xs={12}>
                      <div className="anj-create-job-wrapper">
                        <div className="anj-create-job-inner-wrapper">
                          <Button
                            style={{
                              color: "white",
                              fontFamily: "futura-pt-demi",
                              fontSize: 18,
                              backgroundColor: "#A3C960",
                              width: 305,
                              borderRadius: 22,
                            }}
                            onClick={() => handleSubmitButton()}
                          >
                            {`${selectedFaq ? "Edit" : "Add"} Faq`}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddNewFaq;
