import React from "react";
import "./TBCDatesRail.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconButton, Checkbox, FormControlLabel } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import moment from "moment";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "unset !important"
        }
      }
    },
    MuiCheckbox: {
      root: {
        padding: 4
      }
    }
  }
});
const PrevArrow = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="dates-rails-prev-button">
        <IconButton
          style={{ cursor: "none", padding: 0, color: "#E4002B" }}
          onClick={props.onClick}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </div>
    </MuiThemeProvider>
  );
};

const NextArrow = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="dates-rails-next-button">
        <IconButton
          style={{ cursor: "none", padding: 0, color: "#E4002B" }}
          onClick={props.onClick}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </MuiThemeProvider>
  );
};

const TBCDatesRail = ({ dates, setSelectedDates }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    touchMove: false,
    lazyLoad: "ondemand",
    PrevArrow: <PrevArrow />,
    NextArrow: <NextArrow />
  };

  const handleTBCAction = date => {
    var datesCopy = Array.from(dates);
    var dateToChangeIndex = datesCopy.indexOf(date);
    datesCopy[dateToChangeIndex] = { date: date.date, tbc: !date.tbc };
    setSelectedDates(datesCopy);
  };
  return (
 <div className="date-inCOntainer">
    <div className="tbc-dates-rail-wrapper">
      <p style={{ fontSize: 16, padding: "6px 0px", color: "#535353" }}>
        Selected dates
      </p>
      <Slider {...settings}>
        {dates &&
          dates.map(date => {
            return (
              <div key={date.date.toLocaleDateString()} className="select-tbc-col-outer">
                <div className="tbc-dates-date-wrapper tbc-date-green-box">
                  {/* <p>{date.date.toLocaleDateString()}</p> */}
                  <p>{moment(date.date).format('DD-MM-YYYY')}</p>
                </div>
                <div className="tbc-date-chk-box">
                  <MuiThemeProvider theme={theme}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="checkedA"
                          onChange={() => handleTBCAction(date)}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontFamily: "futura-pt-bold",
                            fontSize: 16,
                            color: "#7F7F7F"
                          }}
                        >
                          TBC
                        </p>
                      }
                    />
                  </MuiThemeProvider>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
</div>
  );
};

export default TBCDatesRail;
