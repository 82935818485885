import { createMuiTheme } from "@material-ui/core/styles";

const JobClientEmailModalTheme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        background: "#E8E8E8",
        overflowY: "initial"
      },
      paperWidthSm: {
        maxWidth: 850
      }
    }
  }
});

export default JobClientEmailModalTheme;
