import React, { Fragment } from "react";
import { isNull } from "util";

const SectionDividerAssignStaff = ({ title, selectedJob, displayJobId }) => {
  return (
    <Fragment>

      <div className="dashboard-ljc">
        <p>{title}</p>
        <div className="divider" />
        {selectedJob != undefined ? (
          <div className="job-id-right large-dark-block large-light-block"><span className={`id-text-light`}>JOB ID:</span> GC-{selectedJob != undefined && selectedJob.id}</div>
        ) : (
            ""
          )}
      </div>

    </Fragment>
  );
};

export default SectionDividerAssignStaff;
