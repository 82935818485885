let NotificationFetches = {
  fetchNotification(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/notification/list?page=${data['page']}`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "http://getdirected.projectstatus.in",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  createNotification(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/notification/create`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //"X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  updateNotification(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/notification/edit`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        //"X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  },
  deleteNotification(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v2/notification/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //  "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        // "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => res.json())
      .catch(error => { });
  }
};

export default NotificationFetches;
