import {
  SET_SEARCH_RESULTS,
  SELECT_JOB,
  SET_ACTIVE_JOBS_LIST,
  SET_COMPLETED_JOBS_LIST,
  FETCH_JOBS_LIST_PENDING,
  FETCH_JOBS_LIST_FULFILLED
} from "../Actions/Types/Dashboard/Jobs";
import { FETCH_CLIENTS_FULFILLED } from "../Actions/Types/Dashboard/Clients";

const initialState = {
  utes: 0,
  tcs: 0,
  loggedJobs: undefined,
  completedJobs: undefined,
  searchResults: {
    resultsLoggedJobs: undefined,
    resultsCompletedJobs: undefined
  },
  selectedJob: undefined,
  clients: []
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_JOBS_LIST: {
      return {
        ...state,
        loggedJobs: action.payload
      }
    }
    case SET_COMPLETED_JOBS_LIST: {
      return {
        ...state,
        completedJobs: action.payload
      }
    }
    case FETCH_CLIENTS_FULFILLED: {
      return {
        ...state,
        clients: action.payload.data
      };
    }
    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: action.payload
      };
    }
    case SELECT_JOB: {
      return {
        ...state,
        selectedJob: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default dashboard;
