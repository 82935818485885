import { createMuiTheme } from "@material-ui/core/styles";

const HeaderTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: "8px 14px"
      }
    }
  }
});

export default HeaderTheme;
