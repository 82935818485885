import React from "react";
import { Grid } from "@material-ui/core";

import ClientHistoryDetails from "../ClientDetails/ClientHistoryDetails";

const GridViewHistory = ({ jobs }) => {
  
  return (
    <Grid container spacing={2} style={{ width: "calc(100% + 8px)" }}>
      {jobs &&
        jobs.map((job, index) => {
          return (
            <Grid item lg={4} md={6} xs={12} key={index} className={`${'dash-box-outer day-view1'}`}>
              <ClientHistoryDetails data={job} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default GridViewHistory;
